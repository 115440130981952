import { ArcElement, Chart as ChartJS } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement);

const Chart1 = ({ jobData, highjob }) => {
  ////console'jobData.closeJobsCount', jobData.openJobsCount)
  const data = {
    datasets: [
      {
        data: [
          jobData.closeJobsCount,
          jobData.openJobsCount,
          jobData.urgentJobsCount,
          // jobData.highPriorities,

          //   jobData.openJobsCount,
          //   jobData.urgentJobsCount,
        ],
        backgroundColor: ["#EFF4FA", "#0C579B", "#FF0000"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div
      className="chart-1"
      style={{ width: "100%", minHeight: "250px", maxHeight: "50px" }}
    >
      <div className="pie-chart">
        <Doughnut data={data} />
        <div className="center-label">
          <div className="!font-[500] !capitalize">Open</div>
          <div className="count">{jobData.openJobsCount}</div>
        </div>
      </div>
      <ul className="chart-label">
        <li>
          <span className="icon" style={{ background: "#EFF4FA" }}></span>{" "}
          <span className="info !font-[500] !leading-5 !text-base !capitalize">
            {jobData.closeJobsCount} Closed Jobs
          </span>
        </li>
        <li>
          <span className="icon" style={{ background: "#0C579B" }}></span>{" "}
          <span className="info !font-[500] !leading-5 !text-base !capitalize">
            <span style={{ color: "#0C579B" }}>{jobData.openJobsCount}</span>{" "}
            Open Jobs
          </span>
        </li>
        <li>
          <span className="icon" style={{ background: "#FF0000" }}></span>{" "}
          <span className="info !font-[500] !leading-5 !text-base !capitalize">
            <span style={{ color: "#FF0000" }}>{jobData.urgentJobsCount}</span>{" "}
            Urgent Jobs
          </span>
        </li>

        {/* <li>
          <span className="icon" style={{ background: "#FFA412" }}></span>{" "}
          <span className="info">
            <span style={{ color: "#FFA412" }}>{jobData.highPriorities}</span>{" "}
            High Jobs
          </span>
        </li> */}
      </ul>
    </div>
  );
};

export default Chart1;
