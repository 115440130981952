import { useEffect } from "react";

export const useCtrlASelect = (ref) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      // For macOS, consider checking for e.metaKey as well
      if ((e.ctrlKey || e.metaKey) && e.key === "a") {
        e.preventDefault();
        if (document.activeElement === ref.current) {
          const selection = window.getSelection();
          const range = document.createRange();
          range.selectNodeContents(ref.current);
          selection.removeAllRanges(); // Clear any existing selections
          selection.addRange(range); // Select the content of the ref element
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [ref]); // Depend on the ref to re-attach the event listener if the ref changes
};
