import React, { useEffect, useRef, useState } from "react";
import TextEditor from "../../../../components/TextEditor";
import Input from "../../../auth/components/Input";
import axios from "axios";
import { useUserContext } from "../../../../Context/UserContext";
import OpenAI from "openai";
import { data } from "autoprefixer";
import { ToastContainer } from "react-bootstrap";
import { cssTransition, toast } from "react-toastify";
import { BarLoader } from "react-spinners";
import { RiseLoader } from "react-spinners";
import { RingLoader } from "react-spinners";
import "./styles.css"; // Replace 'styles.css' with the actual path to your CSS file
import OutOfCreditPopup from "../../components/OutOfCreditPopup";
import { JobDescriptionIcon } from "../../../../components/SidebarIcons";
import JobDescriptionPopup from "../../components/JobDescriptionPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useCtrlASelect } from "../../../../hooks/useCtrlSelect";

const JdRewrite = () => {
  const {
    FullJobData,

    projectId,

    selectStyle,
    setselectStyle,
    userInfo,
    jobDescLoading,
    botResponse,
    setBotResponse,
    errors,
    handleJobDescClick,
    selectpersona,
    setselectpersona,

    isJobDescSaved,
    OpenPopup,
    setOpenPopup,
  } = useUserContext();

  const textInputRef = useRef(null);

  useCtrlASelect(textInputRef);

  const [value, setValue] = useState("");
  const [open, setOpen] = useState(true);
  const [decodedValue, setDecodedValue] = useState("");
  const [firstEffectFinished, setFirstEffectFinished] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    setDecodedValue(decodeURIComponent(value));
  }, [value]);

  // get job title from localstorage
  let savedJobTitle = localStorage.getItem("jobTitle");
  const checkForChanges = () => {
    const newValue = localStorage.getItem("jobTitle");
    if (newValue !== savedJobTitle) {
      // Value has changed, update savedJobTitle
      savedJobTitle = newValue;
      // Do something with the updated value
      //(savedJobTitle);
    }
  };
  const pollingInterval = 500; // 500 milliseconds
  setInterval(checkForChanges, pollingInterval);
  checkForChanges();

  // get job title from localstorage

  //   get job details from localstorage
  let savedJobDetails = localStorage.getItem("jd");
  const checkForChangesDetails = () => {
    const newValue = localStorage.getItem("jd");
    if (newValue !== savedJobDetails) {
      // Value has changed, update savedJobTitle
      savedJobDetails = newValue;
      // Do something with the updated value
      //('savedJobDetails', savedJobDetails);
    }
  };
  const pollingIntervalDetails = 500; // 500 milliseconds
  setInterval(checkForChangesDetails, pollingIntervalDetails);
  checkForChangesDetails();

  const get = "JdRewrite";
  const post = "JdRewrite";
  const deleteEditor = "JdRewriteCollection";

  // const heading = `JDR: ${JobName ? JobName : ""}  `;
  const heading = `JobPost: ${""}  `;

  // Add the formatted date and time to the heading
  const headingWithDateTime = `${heading} - `;

  //------------------------------------------------------------------------------------------------------

  const singleObject = FullJobData[0];

  const [jdEmptyPopup, setjdEmptyPopup] = useState(false);

  const [counter, setCounter] = useState(0);

  //------------------------------------------------------------------------------------------------------
  const copyContentToEditor = () => {
    // Select the div containing the content you want to copy
    const contentDiv = document.querySelector(".__output-text");
    if (contentDiv) {
      // Get the HTML content of the div
      const htmlContent = contentDiv.innerHTML;

      setValue(htmlContent); // Where `setValue` is the function to update the state
    }
  };

  useEffect(() => {
    // Your API endpoint
    if (firstEffectFinished) {
      // Your API endpoint
      const apiUrl = `${process.env.REACT_APP_SERVER_END_POINT}/update/jd/inputBox/${projectId}`;

      const boldText = botResponse.replace(
        /\*\*(.*?)\*\*/g,
        (match, p1) => `<b>${p1}</b>`
      );

      // Your job data, including botResponses
      const jobData = {
        // response: boldText,
        selectedPersona: selectpersona,
        selectedStyle: selectStyle,
      };

      // Fetch options
      const requestOptions = {
        method: "PUT", // Change this to PUT
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jobData),
      };

      // Make the PUT request using fetch
      fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          //////('Data from server::', data);
          // Handle the response data as needed
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
        });
    }
  }, [botResponse, selectpersona, selectStyle, firstEffectFinished]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/get/jd/inputBox/${projectId}`
        );
        const result = await response.json();

        setBotResponse(result[0]?.response || "");
        setselectStyle(
          result[0]?.selectedStyle ? result[0].selectedStyle : "-"
        );
        setselectpersona(
          result[0]?.selectedPersona ? result[0]?.selectedPersona : "-"
        );
        setFirstEffectFinished(true);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (projectId) {
      fetchData();
    }
  }, [projectId, isJobDescSaved]);

  const handleOpenJd = () => {
    if (singleObject == undefined) {
      alert("Please select job");
      return;
    }

    if (singleObject?.jd == undefined) {
      setjdEmptyPopup(true);
    } else {
      handleJobDescClick(projectId);
    }
  };

  // Reset botResponses when projectId changes
  // useEffect(() => {
  //   setBotResponse("");
  // }, [projectId]);

  return (
    <>
      <section className="jobs-management bg-body-2">
        <form onSubmit={handleSubmit} className="dashboard-form-2">
          <div className="row g-4 flex-lg-nowrap">
            <div className="col-lg-4">
              <div className="mb-4">
                <Input
                  label="Style"
                  options={style}
                  onChange={(e) => setselectStyle(e.target.value)}
                  value={selectStyle}
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Brand / Voice"
                  options={persona}
                  value={selectpersona}
                  onChange={(e) => setselectpersona(e.target.value)}
                />
              </div>
              {/* <div className="mb-32">
								<Input label="Format" options={format} />
							</div> */}
              {/* <button
                onClick={handleOpenJd}
                type="button"
                className="__btn w-100 mb-32 h-50px"
              >
                Rewrite Job Description
              </button> */}

              <div className="d-flex  flex-col items-center   gap-20px save-info">
                {errors.JobDescErrors && (
                  <p className="text-red-500 text-sm  font-semibold">
                    {" "}
                    {errors.JobDescErrors}
                  </p>
                )}
                <button
                  onClick={handleOpenJd}
                  className="__btn w-100 mb-32  mt-4 !font-[500] !leading-5 !text-base !capitalize"
                  disabled={jobDescLoading}
                >
                  {jobDescLoading ? (
                    <div className="!flex justify-around gap-2 items-center  w-full">
                      <p className="mb-0"> Loading…</p>
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    </div>
                  ) : (
                    "Rewrite Job Description"
                  )}
                </button>
              </div>
            </div>
            <div className={`${open ? "col-lg-4" : "col-lg-8"}`}>
              <div>
                <label className="form--label mb-4 !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Suggested Job Posting
                </label>
                <div
                  className="form-control form--control __output-text"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                  readOnly={true}
                  ref={textInputRef}
                  contentEditable
                  spellCheck="false"
                  dangerouslySetInnerHTML={{
                    __html: loading
                      ? ""
                      : botResponse
                          ?.replace(
                            /\*\*(.*?)\*\*/g,
                            (match, p1) => `<b>${p1}</b>`
                          )
                          .replace(/\n/g, "<br />"),
                  }}
                />
              </div>
            </div>

            <div className={`${open ? "col-lg-4" : "col-lg-1"}`}>
              {/* <button onClick={copyContentToEditor}>Copy Content</button> */}
              <div>
                <TextEditor
                  key={counter}
                  get={get}
                  post={post}
                  deleteEditor={deleteEditor}
                  title={headingWithDateTime}
                  value={value}
                  setValue={setValue}
                  open={open}
                  setOpen={setOpen}
                />
              </div>
            </div>
          </div>
        </form>
        <OutOfCreditPopup
          open={OpenPopup}
          setOpen={setOpenPopup}
          heading={`You've Hit the Limit!`}
          text={
            userInfo.productName !== "Gold"
              ? "You've reached your maximum for this feature, but your potential is unlimited. Upgrade now to continue without interruption."
              : "You've used all your word credits for this month. Stay tuned, your credits will refresh soon."
          }
          userInfo={userInfo}
        />
        <JobDescriptionPopup
          open={jdEmptyPopup}
          setOpen={setjdEmptyPopup}
          handleUserInput={(projectId) => handleJobDescClick(projectId)}
        ></JobDescriptionPopup>
        <ToastContainer />
      </section>
    </>
  );
};
const style = [
  {
    value: "-",
    name: "-",
  },
  {
    name: "Professional ",
    value: "Professional and polite",
  },
  {
    name: "Energetic",
    value: " Enthusiastic and Energetic",
  },
  {
    name: "Compelling",
    value: "Enticing and Compelling",
  },
  {
    name: "Witty",
    value: "Witty and Smart",
  },
  {
    name: "Friendly",
    value: "Warm and Friendly",
  },
  {
    name: "Optimistic",
    value: "Positive and Optimistic",
  },
  {
    name: "Concise",
    value: "Short and Concise",
  },
];
const persona = [
  {
    value: "-",
    name: "-",
  },
  {
    name: "Lou Adler",
    value: "Lou Adler",
  },
  {
    name: "Stacy Zapar",
    value: "Stacy Zapar",
  },
  {
    name: "Johnny Campbell",
    value: "Johnny Campbell",
  },
  {
    name: "Greg Savage",
    value: "Greg Savage",
  },
  {
    name: "Maisha Cannon",
    value: "Maisha Cannon",
  },
  {
    name: "Glen Cathey",
    value: "Glen Cathey",
  },
  {
    name: "Jim Stroud",
    value: "Jim Stroud",
  },
  {
    name: "Amy Miller",
    value: "Amy Miller",
  },
  {
    name: "John Sullivan",
    value: "John Sullivan",
  },
  {
    name: "Steve Jobs",
    value: "Steve Jobs",
  },
  {
    name: "Sheryl Sandberg",
    value: "Sheryl Sandberg",
  },
  {
    name: "Jeff Bezos",
    value: "Jeff Bezos",
  },
  {
    name: "Elon Musk",
    value: "Elon Musk",
  },
  {
    name: "Satya Nadella",
    value: "Satya Nadella",
  },
  {
    name: "Tim Cook",
    value: "Tim Cook",
  },
];
const format = [
  {
    name: "Lou Adler",
    value: "Lou Adler",
  },
  {
    name: "Stacy Zapar",
    value: "Stacy Zapar",
  },
  {
    name: "Johnny Campbell",
    value: "Johnny Campbell",
  },
  {
    name: "Greg Savage",
    value: "Greg Savage",
  },
  {
    name: "Maisha Cannon",
    value: "Maisha Cannon",
  },
  {
    name: "Glen Cathey",
    value: "Glen Cathey",
  },
  {
    name: "Jim Stroud",
    value: "Jim Stroud",
  },
  {
    name: "Amy Miller",
    value: "Amy Miller",
  },
  {
    name: "John Sullivan",
    value: "John Sullivan",
  },
  {
    name: "Steve Jobs",
    value: "Steve Jobs",
  },
  {
    name: "Sheryl Sandberg",
    value: "Sheryl Sandberg",
  },
  {
    name: "Jeff Bezos",
    value: "Jeff Bezos",
  },
  {
    name: "Elon Musk",
    value: "Elon Musk",
  },
  {
    name: "Satya Nadella",
    value: "Satya Nadella",
  },
  {
    name: "Tim Cook",
    value: "Tim Cook",
  },
];

export default JdRewrite;
