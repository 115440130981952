import React from "react";
const JobStageChart = ({ data }) => {
  return (
    <>
      <div className="chart-wrapper">
        <CustomBarChart data={data} />
      </div>
    </>
  );
};

export const CustomBarChart = ({ data }) => {
  const { dataSet, label } = data;
  const maxValue = Math.max(...dataSet);
  const total = Math.ceil(dataSet.reduce((a, b) => a + b, 0));
  //(maxValue);
  const colors = ["#1AA9D0", "#2488D5", "#3669D5", "#2140A3", "#161F6F"]; // Add more color codes as needed

  return (
    <>
      <div className="chart" style={{ width: "100%" }}>
        <div className="yaxis">
          <div>
            <span>{maxValue}</span>
          </div>
          <div>
            <span>{(maxValue * 3) / 4}</span>
          </div>
          <div>
            <span>{(maxValue * 2) / 4}</span>
          </div>
          <div>
            <span>{(maxValue * 1) / 4}</span>
          </div>
          <div>
            <span>0</span>
            <div className="border">
              <div className="xaxis">
                <div className="xaxis-data-bars">
                  {label &&
                    label.map((item, i) => (
                      <span
                        className="item"
                        key={i}
                        style={{
                          height: (dataSet[i] / maxValue) * 100 + "%",
                          // width: "600px",
                          backgroundColor: colors[i % colors.length],
                        }}
                      ></span>
                    ))}
                </div>

                <div className="xaxis-data">
                  {label &&
                    label.map((item, i) => (
                      <span
                        className="item !font-[500]  !text-base !capitalize "
                        key={i}
                      >
                        {item}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default JobStageChart;
