import React from "react";
import DashboardLayout from "../../../components/DashboardLayout";
import Intake from "./components/Intake";
import JobSingleLayout from "./components/JobSingleLayout";
import { useUserContext } from "../../../Context/UserContext";

const IntakePage = () => {
  const { FullJobData, handleJobData } = useUserContext();

  // Define breadcrumbs here, after FullJobData is available
  const breadcrumbs = [
    {
      name: "My Jobs",
    },
    {
      name: FullJobData[0]?.name,
    },
    {
      name: "Intake",
    },
  ];

  return (
    <>
      <DashboardLayout breadcrumbs={breadcrumbs}>
        <JobSingleLayout>
          <Intake />
        </JobSingleLayout>
      </DashboardLayout>
    </>
  );
};

export default IntakePage;
