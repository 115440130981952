import React from "react";

const SectionHeader = ({ title, colorTitle, subtitle, attrTitle }) => {
	return (
		<div className="section-header" data-title={attrTitle}>
			{title && (
				<h2 className="title">
					{title} {colorTitle && <span>{colorTitle}</span>}{" "}
				</h2>
			)}
			{subtitle && <p className="subtitle">{subtitle}</p>}
		</div>
	);
};

export default SectionHeader;
