import React, { useContext, useRef, useState } from "react";
import {
  Link,
  NavLink,
  Navigate,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { MyContext } from "../App";
import userImg from "../assets/img/user-img.png";
import Input from "../pages/auth/components/Input";
import OutOfCreditPopup from "../pages/dashboard/components/OutOfCreditPopup";
import CreateNewJobPopup from "./CreateNewJobPopup";
import { AngleIcon, PointerIcon } from "./Icon";
import Logo from "./Logo";
import emailjs from "emailjs-com";
import {
  AccountSettingIcon,
  BarIcon,
  ClearIcon2,
  DashboardIcon,
  FavouriteIcon,
  IntakeIcon,
  InterviewIcon,
  JobDescriptionIcon,
  JobManagementIcon,
  JobsIcon,
  LogOutIcon,
  PlusIcon,
  ReachoutIcon,
  SearchAssistantIcon,
} from "./SidebarIcons";
import { XlsxUpload } from "./XlsxUpload";
import { AuthContext, useUserContext } from "../Context/UserContext";
import { useEffect } from "react";
import axios from "axios";
import CallUserAccount from "./CallUserAccount";
import UpgradeBtn from "../pages/landing/components/UpgradeBtn";

import Joyride from "react-joyride";

const DashboardLayout = ({ title, breadcrumbs, children }) => {
  const { totalWords, action } = useUserContext();

  const { collapesd, setCollapsed } = useContext(MyContext);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const {
    logout,
    setSetProjectIdloaded,
    user,
    userInfo,
    handleDaypass,
    projectUpdateId,
    handleRemoveUserInfo,
  } = useContext(AuthContext);

  const [steps, setSteps] = useState();
  const [run, setRun] = useState(false);

  useEffect(() => {
    const hasSeenTour = localStorage.getItem("hasSeenTour");

    if (!hasSeenTour) {
      const initialSteps = [
        {
          target: ".my-first-step",
          content: "Create a Job from here!",
          disableBeacon: true,
        },
        // ...sidebarMenu.map((item, index) => ({
        //   target: `.sidebar-step-${index + 1}`,
        //   content: `This is step ${index + 2}`,
        //   disableBeacon: true,
        // })),
      ];
      setSteps(initialSteps);
      setRun(true);
    }
  }, []); // Empty dependency array means this runs once on mount

  const handleLogout = (auth) => {
    var answer = window.confirm("Log out?");
    if (answer) {
      // Make sure that the logout function is working as expected
      logout(auth);
      projectUpdateId();
      handleRemoveUserInfo();
      setSetProjectIdloaded(true);

      // Make sure that the Navigate function is working as expected
      window.location.href = "/";
    } else {
      // Show an alert when "No" is pressed
    }
  };

  //   redirecting to the customer portal

  const timestamp = 1847180000;
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

  // Retrieve userInfo from local storage

  const [total, setTotal] = useState(); // Initialize the state variable
  useEffect(() => {
    // Assuming userInfo is a prop or state variable
    const words = userInfo?.limitWords;

    // Check if words is defined before updating the state
    if (words !== undefined) {
      setTotal(words);
    }
  }, [userInfo]); // Add userInfo as a dependency to the useEffect

  // console.log("wordsss", )

  const width = Math.min(100, Math.max(0, (totalWords / total) * 100));
  //send email

  ////('file',file)
  const [message, setMessage] = useState("");

  const [name, setName] = useState("");
  const [lname, setLName] = useState("");

  useEffect(() => {
    // Check if the user object exists and has a displayName
    if (user && user.displayName) {
      // Split the displayName into first name and last name assuming it is space-separated
      const displayNameParts = user.displayName.split(" ");

      // Assuming the first part is the first name and the second part is the last name
      if (displayNameParts.length >= 1) {
        setName(displayNameParts[0]);
      }

      if (displayNameParts.length >= 2) {
        setLName(displayNameParts[1]);
      }
    }
  }, [user]);

  const [countdown, setCountdown] = useState(
    calculateCountdown(userInfo?.timeDay)
  );

  //('countdown', countdown);

  function calculateCountdown(targetTime) {
    const currentTime = new Date().getTime();
    const timeRemaining = targetTime - currentTime;
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
    const minutes = Math.floor(
      (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
    );
    const hours = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  }

  const [OpenPopup, setOpenPopup] = useState(false);

  const productName = "Free";
  const limitWords = 1000;

  useEffect(() => {
    const targetTime = new Date(userInfo.timeDay).getTime();
    setCountdown(calculateCountdown(targetTime));

    const interval = setInterval(async () => {
      const updatedCountdown = calculateCountdown(targetTime);
      setCountdown(updatedCountdown);

      // Check if the countdown has reached zero
      if (
        userInfo.productName === "bronze" &&
        updatedCountdown.hours < 0 &&
        updatedCountdown.minutes < 0 &&
        updatedCountdown.seconds < 1
      ) {
        //reset account to Free user after 24 hour countDown
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_END_POINT}/update/account/subscription/${user?.email}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ productName, limitWords }), // Updated to use userInfo.productName
            }
          );

          if (response.ok) {
            handleDaypass(24);
            setOpenPopup(true);

            //('User updated successfully.');
          } else {
            // Failed to update user.
            console.error("Failed to update user.");
          }
        } catch (error) {
          console.error("An error occurred while updating user:", error);
        }

        clearInterval(interval); // Stop the interval once the action is triggered
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [userInfo?.timeDay]);

  //timer

  const handleManageSubscription = async () => {
    try {
      // Assuming you have a customerId stored in Firestore or similar
      ////({ customerId })

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_END_POINT}/create-portal`,

        { customerId: userInfo?.customer_id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.url) {
        window.location.href = response.data.url; // Redirect to the customer portal
      }
    } catch (error) {
      console.error("Error accessing customer portal:", error);
    }
  };

  const handleClearFeedback = () => {
    setMessage("");
  };

  //__________________ Nodaimailer code start _________________//

  const sendEmail = async (e) => {
    e.preventDefault();

    const userData = {
      name: name,
      email: user?.email,
      message: userInfo.productName + " " + message,
    };

    console.log({ message });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_END_POINT}/api/sendEmail`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (response.ok) {
        // Handle success, maybe show a success message or perform additional actions
        setMessage(""); // Set the message state to an empty string to clear the text
        alert("Feedback sent");
      } else {
        // Handle error, maybe show an error message or perform error-related actions
        console.error("Failed to send email");
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error:", error);
    }
  };

  //__________________ Nodaimailer code End _________________//
  const inputRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      // Clicked outside the input, clear the input
      setMessage("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleJoyrideCallback = (data) => {
    const { type, status } = data;

    if (type === "tour:end" || status === "finished") {
      localStorage.setItem("hasSeenTour", "true");
      setRun(false);
    }
  };

  return (
    <>
      {/* <Joyride
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        run={run}
        // stepIndex={0}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      /> */}
      <main className="main-section">
        <aside className={collapesd ? "active" : ""}>
          <div className="inner-div">
            <div className="logo-area">
              <div className="d-none d-lg-block">
                <Logo url="/home" favlogo={collapesd} />
              </div>
              <div className="d-lg-none">
                <Logo url="/home" />
              </div>
              <div
                className="angle-icon d-none d-lg-block"
                onClick={() => setCollapsed(!collapesd)}
              >
                <AngleIcon />
              </div>
            </div>
            <div className="sidebar-menu-area ">
              <Link
                to="#create-new-job-popup "
                className="__btn create-btn mb-32  my-first-step"
                style={{ paddingBlock: "12px" }}
                // // popup create job
                // data-bs-toggle="modal"
                // // popup out of usage
                // onClick={() => setOpen(!open)}
                {...((userInfo.productName === "Free" &&
                  userInfo.totaljob < 3) ||
                (userInfo.productName === "bronze" && userInfo.totaljob < 10) ||
                (userInfo.productName === "Silver" && userInfo.totaljob < 25) ||
                (userInfo.productName === "Gold" && userInfo.totaljob < 75)
                  ? { "data-bs-toggle": "modal" }
                  : { onClick: () => setOpen(!open) })}
              >
                <span className="txt mr-3">Create New Job </span> <PlusIcon />
              </Link>
              <ul className="sidebar-menu">
                {sidebarMenu?.map(({ url, icon, name }, i) => (
                  <li key={i} className={`sidebar-step-${i + 1}`}>
                    <NavLink to={url}>
                      {icon} <span className="txt">{name}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
              {userInfo?.productName === "Gold" ? (
                <></>
              ) : (
                <>
                  {" "}
                  {collapesd ? (
                    <UpgradeBtn classNames="__btn ms-2 w-[80px]" />
                  ) : (
                    <div className="account-update-info !flex !flex-col !gap-3 ">
                      <h6 className="title">Upgrade your account</h6>
                      <p>
                        Increase your usage and get early access to new features
                      </p>

                      <UpgradeBtn classNames="__btn ms-2" />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className="d-lg-none close-sidebar"
            onClick={() => setCollapsed(!collapesd)}
          >
            <ClearIcon2 />
          </div>
        </aside>
        <article>
          <section className="article-header">
            <div
              style={{ width: "32px", marginRight: "8px" }}
              className="d-lg-none"
            >
              <Logo favlogo />
            </div>
            {title && <h6 className="name">{title}</h6>}
            {breadcrumbs?.length > 0 && (
              <ul className="__breadcrumb !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D] ">
                {breadcrumbs?.map(({ name }, i) => (
                  <li
                    key={i}
                    className="!font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]"
                  >
                    {name}
                  </li>
                ))}
              </ul>
            )}
            <div className="right-icons ms-auto">
              <div className="dropdown">
                <button
                  className="icon no-gutter"
                  type="button"
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                >
                  <FavouriteIcon />
                </button>
                <div
                  className={`dropdown-menu __dropdown-menu-2 -mt-3 ${
                    show ? "show" : ""
                  }`}
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                >
                  <h4 className="title mb-1">Feedback</h4>
                  <p className="mb-20">
                    Please share your experience or thought with us
                  </p>
                  <div className="mb-20">
                    <Input
                      value={message}
                      onChange={(e) => setMessage(`${e.target.value}`)}
                      textarea
                      placeholder="Type here.."
                    />
                  </div>
                  <div className="mb-4">
                    {/* <XlsxUpload type="file" onChange={handleFileChange} files={files} setFiles={setFiles} />
                     */}
                    {/* <input ref={fileInputRef} type="file" onChange={handleFileChange} /> */}
                  </div>
                  <button
                    onClick={sendEmail}
                    type="submit"
                    className="__btn w-100"
                  >
                    Submit
                  </button>
                  <div
                    className="my-32 mx-auto"
                    style={{
                      maxWidth: "156px",
                      height: "1px",
                      background: "#B3BBC1",
                    }}
                  ></div>
                  <div className="d-flex align-items-center gap-3 ff-txt">
                    <PointerIcon />
                    <div
                      className="w-0 flex-grow-1"
                      style={{ maxWidth: "283px" }}
                    >
                      To solve the recent{" "}
                      <span className="text--danger">Payment Error</span> ,
                      start by removing the payment method and then attempt to
                      add it again.
                    </div>
                  </div>
                </div>
              </div>
              {/* <Link to="#" className="icon">
                <BellIcon />
              </Link> */}
              <div
                className="d-none d-lg-block"
                style={{
                  height: "25px",
                  width: "1px",
                  background: "var(--border)",
                }}
              ></div>

              <div className="dropdown">
                <button
                  className="btn dropdown-btn p-0 border-0"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  {/* <img
                    src={user?.photoURL}
                    alt=""
                    style={{
                      width: "50px", // Set the width to your desired size
                      height: "50px", // Set the height to your desired size
                    }}
                  /> */}
                  <img
                    src={
                      userInfo?.imgupload
                        ? userInfo.imgupload
                        : user
                        ? user.photoURL
                        : null
                    }
                    alt=""
                    style={{
                      width: "50px", // Set the width to your desired size
                      height: "50px", // Set the height to your desired size
                    }}
                  />

                  <div className="info">
                    <h6 className="name">
                      {userInfo?.firstName} {userInfo?.lastName}{" "}
                    </h6>
                    <span> {userInfo?.title} </span>
                  </div>
                </button>
                <div
                  className="dropdown-menu __dropdown-menu"
                  style={{ width: "288px", right: "0" }}
                >
                  <div className="txt">
                    {userInfo.productName === "bronze" ? (
                      <>
                        {" "}
                        You are using the{" "}
                        <span style={{ color: "blue" }}>Day-Pass</span> Plan
                      </>
                    ) : (
                      <>
                        {!userInfo.email ? (
                          <>
                            <p>Loading..</p>
                          </>
                        ) : (
                          <>
                            You are using the{" "}
                            <strong style={{ color: "blue" }}>
                              {userInfo.productName
                                ? userInfo.productName === "Silver"
                                  ? `${userInfo.productName}`
                                  : userInfo.productName === "Gold"
                                  ? `${userInfo.productName}`
                                  : `${userInfo.productName}`
                                : `${userInfo.productName}`}
                            </strong>{" "}
                            Plan
                          </>
                        )}
                      </>
                    )}

                    {userInfo.productName === "bronze" &&
                    countdown.days === 0 &&
                    countdown.hours < 0 &&
                    countdown.minutes < 0 &&
                    countdown.seconds < 1 ? (
                      // Don't show countdown for Day Pass when it's less than 1 second
                      <></>
                    ) : userInfo.timeDay === 0 ? (
                      <></> // Render nothing if userInfo.time is 0
                    ) : userInfo.productName === "bronze" ? (
                      <>
                        <p>
                          {countdown.hours} hours, {countdown.minutes} minutes,{" "}
                          {countdown.seconds} seconds
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {userInfo.productName === "bronze" ||
                  userInfo.productName === "Silver" ||
                  userInfo.productName === "Gold" ? null : ( // Show Upgrade button only if userInfo.amount_paid has a value
                    <UpgradeBtn classNames="__btn ms-2 w-11/12" />
                  )}

                  <div className="txt mb-2 mt-4">
                    <strong>Words Generated</strong>
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      className="progress flex-grow-1 me-2"
                      style={{ height: "10px" }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${width}%`,
                          borderRadius: "20px",
                          background: "var(--base-2)",
                        }}
                        aria-valuenow="40"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div className="d-flex align-items-center">
                      <strong className="text-base-2">
                        {Number(totalWords) > Number(userInfo?.limitWords) ? (
                          <>{userInfo?.limitWords} </>
                        ) : (
                          <> {totalWords}</>
                        )}
                        {/* {totalWords} */}
                      </strong>
                      /{total}
                      {userInfo.words == "unlimited" ? (
                        <> {userInfo.words}</>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <ul className="account-menu">
                    <li>
                      <Link to="/account-setting">
                        <AccountSettingIcon /> <span>Account settings</span>
                      </Link>
                    </li>
                    {/* <li>
											<Link to="/dashboard/account-setting">
												<ChangePasswordIcon />{" "}
												<span>Change Password</span>
											</Link>
										</li> */}

                    <li onClick={handleLogout}>
                      <Link>
                        <LogOutIcon /> <span>Logout</span>
                      </Link>
                    </li>
                    <li>
                      {/* <button
												type="button"
												onClick={handleManageSubscription}
												className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
											>
												Manage Subscription
											</button> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-lg-none" onClick={() => setCollapsed(true)}>
                <BarIcon />
              </div>
            </div>
          </section>
          <div className="article-children">{children}</div>
        </article>
      </main>
      <CreateNewJobPopup />
      <OutOfCreditPopup
        open={open}
        setOpen={setOpen}
        heading={`You've Hit the Limit!`}
        text={
          userInfo.productName !== "Gold"
            ? "You've reached your maximum for this feature, but your potential is unlimited. Upgrade now to continue without interruption."
            : "You've hit your job creation limit. To create more jobs, consider deleting any old or completed jobs. Thanks for staying active!."
        }
        userInfo={userInfo}
      />
    </>
  );
};
const sidebarMenu = [
  {
    icon: <JobsIcon />,
    name: "My Jobs",
    url: "/jobs",
  },
  {
    icon: <JobManagementIcon />,
    name: "Job Management",
    url: "/job-management",
  },

  {
    icon: <DashboardIcon />,
    name: "Dashboard",
    url: "/dashboard",
  },
  {
    icon: <JobDescriptionIcon />,
    name: "Job Posting",
    url: "/jd-rewrite",
  },
  {
    icon: <ReachoutIcon />,
    name: "Candidate R3achout",
    url: "/r3achout",
  },
  {
    icon: <SearchAssistantIcon />,
    name: "Search Assistant",
    url: "/search",
  },
  {
    icon: <IntakeIcon />,
    name: "Intake",
    url: "/intake",
  },
  {
    icon: <InterviewIcon />,
    name: "Interview",
    url: "/interview",
  },
];
export default DashboardLayout;
