import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SuccessModal = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  return (
    <>
      <Modal show={open} className="success-reg-modal" centered>
        <Modal.Body>
          <div className="success-modal text-center">
            <div className="icon">
              <SuccessIcon />
            </div>
            <h5>Registration Completed Successfully</h5>
            <p>
              Congratulations! Your registration has been successfully
              completed. Enjoy full access to all features now!
            </p>
            <button
              type="button"
              className="__btn mx-auto"
              onClick={() => {
                setOpen(false);
                setTimeout(() => {
                  navigate("/dashboard");
                }, 200);
              }}
            >
              Explore Now
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const SuccessIcon = () => (
  <svg
    width="224"
    height="223"
    viewBox="0 0 224 223"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="112"
      cy="111.25"
      r="111.25"
      fill="#0C579B"
      fill-opacity="0.02"
    />
    <circle
      cx="112.411"
      cy="110.84"
      r="84.5664"
      fill="#0C579B"
      fill-opacity="0.12"
    />
    <circle cx="112.41" cy="110.84" r="63.2196" fill="#0C579B" />
    <path
      d="M129.471 97.5664L105.637 121.4L94.804 110.566"
      stroke="#FEFEFF"
      strokeWidth="4.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SuccessModal;
