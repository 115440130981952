import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import shape from "../../assets/img/title-shape.png";
import { ArrowRight } from "../../components/Icon";
import { useContext } from "react";
import { AuthContext } from "../../Context/UserContext";
const OnBoarding1 = () => {
  const [index, setIndex] = useState("");
  const { user } = useContext(AuthContext);

  const [title, setSelectedRecruiter] = useState(null);

  const handleClick = (recruiter) => {
    setSelectedRecruiter(recruiter);
  };

  const navigate = useNavigate();

  const handleUpdate = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_END_POINT}/update/account/${user?.email}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ title }),
      }
    );

    if (response.ok) {
      navigate("/onboarding-2");
      // navigate("/dashboard?reg-completed")
      //console'User updated successfully.');
    } else {
      console.error("Failed to update user.");
    }
  };
  return (
    <>
      <div className="onboarding-section-wrapper">
        <div className="onboarding-hero">
          <div className="container">
            <div>
              <h2 className="title">
                <img src={shape} alt="" />
                Welcome to <span className="text-base">R3achout.AI</span>
              </h2>
              <div className="subtitle mb-3 mb-md-0">
                Tell us about yourself
              </div>
              <div className="subtitle">
                To tailor your experience, let us know what best describes your
                role
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="onboarding-wrapper">
            <div className="row g-3 g-md-4 gap-md-20px">
              {data?.map((item, i) => (
                <div className="col-6 col-lg-4 col-xl-3" key={i}>
                  <button
                    type="button"
                    className={`role-btn ${index == item ? "active" : ""}`}
                    onMouseDown={() => setIndex(item)}
                    onClick={() => handleClick(item)}
                  >
                    {item}
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="pb-2">
            <div className="__btn-grp flex-wrap d-flex justify-content-center column-gap-4 pb-5">
              <button className="__btn-outline column-gap-4" type="button">
                Skip
              </button>
              <button
                className="__btn column-gap-4 __sm-btn-shadow"
                type="button"
                onClick={handleUpdate}
              >
                <span>Next</span> <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const data = [
  "Agency Owner",
  "Agency Recruiter",
  "Recruitment Manager",
  "Recruiter",
  "Talent Acquisition Specialist",
  "Acquisition",
  "Contract Recruiter",
  "Advisor",
  "Human Resources Recruiter",
  "Staffing Coordinator",
  "Sourcing Specialist",
  "HR Coordinator",
  "Talent Sourcer",
  "Technical Recruiter",
  "Campus Recruiter",
  "Executive Recruiter",
  "RPO Recruiter",
  "Employment Branding Specialist",
  "Other",
];
export default OnBoarding1;
