import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "../Context/UserContext";

function PublicRoute({ redirectTo }) {
  const { user } = useUserContext();

  return user ? <Navigate to={redirectTo} replace /> : <Outlet />;
}

export default PublicRoute;
