import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "../Context/UserContext";

function PrivateAuthRoute() {
  const { user, userInfo } = useUserContext();

  return user ? <Outlet /> : <Navigate to="/login" replace />;
}

export default PrivateAuthRoute;
