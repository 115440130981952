import React from "react";
import { useUserContext } from "../../../../../Context/UserContext";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Sourcing = ({
  textRef3,
  textRef4,
  textRef9,
  createNarrowKeywordsHTML,
  createBooleanSearchHTML,
  createExpandKeywordsHTML,
  handleOpenJd,
}) => {
  const { searchLoading, errors } = useUserContext();

  return (
    <div className="mb-4">
      <div
        className={`display-text rounded p-3 open-box`}
        style={{
          minHeight: "100px",
          maxHeight: "auto", // Updated to 'auto'
          overflowY: "auto", // Updated to 'auto'
          display: "block",
        }}
      >
        <div className="search-feature-container">
          <div
            style={{
              width: "100%",
            }}
            className="flex flex-col gap-4"
          >
            <p className="!font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D] ">
              Boolean Search String
            </p>
            <div
              className="feature-box"
              ref={textRef3}
              contentEditable
              spellCheck="false"
              dangerouslySetInnerHTML={createBooleanSearchHTML}
            ></div>
          </div>
          <div
            style={{
              width: "100%",
            }}
            className="flex flex-col gap-4"
          >
            <p className="!font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D] ">
              Keywords to Narrow your search
            </p>
            <div
              className="feature-box"
              ref={textRef4}
              contentEditable
              spellCheck="false"
              dangerouslySetInnerHTML={createNarrowKeywordsHTML}
            ></div>
          </div>

          <div
            style={{
              width: "100%",
            }}
            className="flex flex-col gap-4"
          >
            <p className="!font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D] ">
              Keywords to Expand your search
            </p>
            <div
              className="feature-box"
              ref={textRef9}
              contentEditable
              spellCheck="false"
              dangerouslySetInnerHTML={createExpandKeywordsHTML}
            ></div>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end mt-4 pt-3">
          <div className="d-flex  flex-col items-center gap-20px save-info">
            {errors && errors.sourcingSearchError && (
              <p className="text-red-500  font-semibold">
                {" "}
                {errors.sourcingSearchError}{" "}
              </p>
            )}
            <button
              onClick={() => handleOpenJd("sourcing")}
              className="__btn m-0 px-xl-5 w-[295px] !font-[500] !leading-5 !text-base !capitalize  "
              disabled={searchLoading.sourceLoading}
            >
              {searchLoading && searchLoading.sourceLoading ? (
                <div
                  className="!flex justify-around  w-full"
                  style={{
                    display: "flex",
                    // justifyContent: "space-between",
                    gap: "2rem",
                    alignItems: "center",
                  }}
                >
                  {/*  <p className="font-semibold leading-5 text-base capitalize text-[#2D3E4D] "></p> */}
                  <p className="mb-0"> Loading…</p>
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
              ) : (
                "Get Sourcing Info"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sourcing;
