import { useEffect } from "react";
import banner_bg from "../assets/img/banner/banner-bg.png";
import Footer from "./landing/components/Footer";
import Header from "./landing/components/Header";

const TermsOfServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header isSecondary={true} />

      <div
        className="container"
        style={{
          marginTop: "150px",
        }}
      >
        <h3 className="text-center text-black">Terms of Service</h3>

        <p
          className="text-black mt-5"
          style={{
            fontStyle: "italic",
          }}
        >
          Effective Date of these Terms of Service: January 2, 2024
        </p>
        <p
          className="text-black "
          style={{
            marginTop: "-14px",
            fontStyle: "italic",
          }}
        >
          Date of Latest Update to these Terms of Service: January 8, 2024
        </p>
        <p>
          Welcome to www.R3achout.AI! R3achout.AI., an Ontario company doing
          business under the trade name R3achout.AI owns and operates
          www.R3achout.AI
        </p>
        <p>
          The present Terms of Service set forth the terms upon which
          R3achout.AI (hereinafter referred to as “<b>R3achout</b>”, “<b>we</b>
          ”, “<b>us</b>”, “<b>our</b>” and other similar terms) shall provide
          access and usage of the website www.R3achout.AI and corresponding
          applications, including web and mobile applications (hereinafter
          collectively referred to as the “<b>Platform</b>”).
        </p>
        <p>
          Users of the Platform shall be required to comply with these Terms of
          Service, in full. Prior to using the Platform, all users shall be
          obligated to acknowledge and agree to the present Terms of Service. If
          any user does not accept to be bound by these Terms of Service, the
          user’s sole recourse shall be to not use the Platform.
        </p>
        <p>
          For reference purposes, these Terms of Service shall encompass any
          other rules or policies implemented by the R3achout, each of which
          will be deemed incorporated in these Terms of Service by reference.
          Users of the Platform acknowledge that we may update these Terms of
          Service and other related policies and rules from time to time, at our
          discretion.
        </p>
        <p>
          These Terms of Service contain a mandatory arbitration of disputes
          provision that requires the use of arbitration on an individual basis
          to resolve disputes, rather than jury trials or class actions.
        </p>
        <p>
          <b>I. DEFINITIONS</b>
        </p>

        <p>
          When used in the present Terms of Service, the following capitalized
          terms shall have the definitions set forth below:
        </p>
        <p>
          “<b>Disputes</b>” shall refer to all disputes between a User and us
          arising out of or relating to these Terms of Service.
        </p>
        <p>
          “<b>DMCA</b>” shall refer to the Digital Millennium Copyright Act, a
          1998 United States copyright law.
        </p>
        <p>
          “<b>Generated Content</b>” shall refer to any and all recruitment and
          hiring content generated by the Services based upon the User Content
          submitted by a User.
        </p>
        <p>
          “<b>Improvements</b>” shall refer to ideas, concepts, information,
          techniques, suggestions, data and feedback submitted to us by Users
          regarding the improvement of the Platform and/or the Services.
        </p>
        <p>
          “<b>Services</b>” shall refer to the artificial intelligence (AI)
          product provided on the Platform intended to assist recruiters with
          respect to attracting, hiring and screening candidates. The Services
          shall further include: (i) candidate search assistance, (ii) rewriting
          of job descriptions to attract competent and qualified candidates,
          (iii) generating intake questions destined for hiring managers to help
          select the ideal candidate, which intake questions shall be generated
          by artificial intelligence (AI) technologies, and (iv) assistance with
          respect to interviewing candidates against a job description. The
          Services shall further include a built-in editor allowing Users to
          copy and save any selected generated outputs.
        </p>
        <p>
          “<b>Terms of Service</b>” shall refer to the latest version of these
          Terms of Service and shall include any and all updates, amendments,
          modifications and supplements made thereto from time to time.
        </p>
        <p>
          “<b>Third Party Sites</b>” shall refer to third party websites,
          networks, software, applications, systems, products and services
          linked or referenced on the Platform.
        </p>
        <p>
          “<b>User Account</b>” shall refer to the individual user account
          created by a User on the Platform.
        </p>
        <p>
          “<b>User Content</b>” shall refer to any text, files, documents,
          graphics, videos and images uploaded by a User to its User Account and
          saved therein, rendering such User Content available solely to the
          uploading User.
        </p>
        <p>
          “<b>Users</b>” shall refer to all users of the Platform. The term “
          <b>User</b>” shall refer to an individual User of the Platform.
        </p>
        <p>
          <b>II. BINDING TERMS</b>
        </p>
        <p>
          These Terms of Service shall constitute a binding and enforceable
          agreement between Users and us. Users acknowledge that we shall not be
          bound by any other agreements, terms or policies, except for the
          present Terms of Service and any policies implemented by us, including
          but not limited to our Privacy Policy.{" "}
        </p>
        <p>
          Users agree to read and review these Terms of Service in full prior to
          accessing or using the Platform. Moreover, Users are encouraged to
          review these Terms of Service periodically to ensure they are familiar
          with the latest version of these Terms of Service.{" "}
        </p>
        <p>
          Users of the Platform are required to act with integrity, in
          compliance with applicable laws and the obligations set forth in these
          Terms of Service and other policies developed, which shall be
          published on the Platform.
        </p>
        <p>
          <b>III. ACCEPTANCE OF THESE TERMS OF SERVICE</b>
        </p>
        <p>
          By accessing, visiting, browsing or using the Platform, Users agree to
          be bound and governed by the present Terms of Service, in their
          entirety. Users further agree to be bound by these Terms of Service as
          though they manually signed them.{" "}
        </p>
        <p>
          Any usage of the Platform shall be deemed to be an acceptance of these
          Terms of Service. If a User does not agree with any portion of these
          Terms of Service, such User’s sole recourse shall be to not access or
          use the Platform.{" "}
        </p>
        <p>
          <b>IV. ELIGIBILITY </b>
        </p>
        <p>
          By using the Platform, every User confirms as follows to us: (i) the
          User is at least eighteen (18) years old, or has reached the age of
          majority as set by its country of residence, whichever is higher; (ii)
          the User holds the required authority and capacity to use the Platform
          and be bound by these Terms of Service; (iii) and the User is not
          subject to any restrictions that would prevent the User from using the
          Platform in the User’s country of origin or residence.
        </p>
        <p>
          <b>V. UPDATES TO THESE TERMS OF SERVICE </b>
        </p>
        <p>
          We may update, change, amend or revise these Terms of Service at any
          time at our discretion. The most recent version of these Terms of
          Service shall be published on the Platform, and shall specify the date
          of the latest update to these Terms of Service.{" "}
        </p>
        <p>
          While we shall use reasonable efforts to notify Users of changes to
          these Terms of Service, we cannot guarantee that Users shall be
          informed of changes to these Terms of Service prior to their
          publication on the Platform. Consequently, Users are encouraged to
          periodically review these Terms of Service to ensure they comply with
          the latest version thereof.{" "}
        </p>
        <p>
          All updates to these Terms of Service shall be binding as of the date
          specified in the header of these Terms of Service published on the
          Platform. Any usage of the Platform following to the publication of
          the updated Terms of Service shall constitute Users’ agreement to be
          bound by such updated Terms of Service.{" "}
        </p>
        <p>
          <b>VI. PRIVACY POLICY</b>
        </p>
        <p>
          Our Privacy Policy, as published on the Platform, is expressly
          incorporated in these Terms of Service by reference. Any and all
          personal information and data provided on the Platform shall be
          governed by our Privacy Policy.{" "}
        </p>

        <p>
          The Privacy Policy shall detail how we collect, use, store and handle
          personal information and data obtained from the Platform.
        </p>
        <p>
          <b>VII. USAGE OF ARTIFICIAL INTELLIGENCE (AI)</b>
        </p>
        <p>
          Users understand and acknowledge that we may use generative artificial
          intelligence (GAI) technologies, namely OpenAI, to produce or
          otherwise generate Generated Content in connection with the Services.{" "}
        </p>
        <p>
          Considering Generated Content shall be generated using AI
          technologies, we are unable to guarantee the quality, suitability,
          accuracy and appropriateness of the Generated Content. We shall not be
          liable for any errors, issues or losses caused by Generated Content
          provided as part of the Services.{" "}
        </p>
        <p>
          <b>VIII. USER ACCOUNTS</b>
        </p>
        <p>
          8.1 <u>User Account Creation</u>
        </p>
        <p>
          User Accounts shall be mandatory to access and use the Services. To
          create a User Account, a User shall submit its valid email address.
          User Accounts shall be secured by a password. All usernames and
          passwords shall remain confidential and personal.{" "}
        </p>
        <p>
          Users may log in their User Accounts through Google API by way of
          their Gmail accounts.
        </p>
        <p>
          In case of any security breach or compromised password, Users must
          notify us at their earliest convenience by sending us an email or
          notification on the Platform.
        </p>
        <p>
          8.2 <u>Provision of Information</u>
        </p>
        <p>
          Users confirm and validate that all information provided as part of
          their User Accounts is true, complete and current. Users shall update
          information contained in their User Accounts on an as needed basis to
          ensure the information remains current.
        </p>
        <p>
          Users further confirm that they have not had a User Account on the
          Platform that we previously terminated or suspended. Moreover, Users
          shall not transfer their User Accounts, or sell, lease, or assign them
          to a third party.
        </p>
        <p>
          8.3 <u>Responsibility for User Accounts</u>
        </p>
        <p>
          Users shall be held responsible for all acts, content, and omissions
          arising out of or relating to their User Accounts. Users agree not to
          engage in unacceptable use of their User Accounts and to further
          strictly comply with the provisions contained in these Terms of
          Service. Users may not use a User Account that does not belong to
          them.
        </p>
        <p>
          Users shall be held liable for any purchases and charges incurred by
          or using their User Accounts.
        </p>
        <p>
          While we implemented strict security measures, we are unable to
          guarantee that User Accounts shall be fully secure and protected
          against unauthorized access at all times. We decline all liability for
          losses or damages caused by unauthorized usage of User Accounts.
          Consequently, Users shall assume all risks associated with their
          uploads, usage, purchases and actions relating to their User Accounts.
        </p>
        <p>
          <b>IX. SUBSCRIPTIONS</b>
        </p>
        <p>
          9.1 <u>Subscription Price and Payments</u>
        </p>
        <p>
          The Platform allows Users to purchase subscriptions to benefit from
          the ability to generate more User Content and receive more Generated
          Content. We offer tiered subscriptions depending on usage needs.
        </p>
        <p>There are three (3) subscriptions available: </p>
        <p>(i) Day-Pass (24-hour access); </p>
        <p>(ii) Silver (consisting of monthly billing); and </p>
        <p>(iii) Gold (consisting of annual billing). </p>
        <p>
          The pricing for the subscriptions shall be set by us. The pricing may
          consist of a fixed fee, an annual fee or a recurring monthly fee.{" "}
        </p>
        <p>
          Users shall be responsible for verifying the price of the purchase
          prior to making the corresponding payment. Unless otherwise indicated,
          the payments for subscriptions are exclusive of any taxes and duties.
          Users are responsible for paying taxes charged in connection with
          their purchases of the selected subscription.{" "}
        </p>
        <p>
          We accept payments by credit card, and other payment methods listed on
          the Platform. Upon their purchase of a subscription, Users agree that
          we shall charge the payment information provided, which may consist of
          a credit card. We may use a third party to store Users’ payment
          information to allow for recurring payments.{" "}
        </p>
        <p>
          If the payment information provided is invalid or unacceptable, we
          reserve the right to cancel the corresponding subscription.
        </p>
        <p>
          9.2 <u>Renewals</u>
        </p>
        <p>
          Unless otherwise specified in the subscription confirmation and unless
          cancelled, the subscription shall automatically renew for subsequent
          periods. We shall be authorized to automatically charge Users’ payment
          information provided on or prior to the renewal date.{" "}
        </p>
        <p>
          The subscription shall remain in effect until the earlier of (a) the
          User’s failure to pay the subscription fees on their due date, or (b)
          the cancellation of the subscription according to these Terms of
          Service.{" "}
        </p>
        <p>
          9.3 <u>Billing Errors</u>
        </p>

        <p>
          We endeavour to provide clear and transparent billing for the
          subscriptions purchased on the Platform. Despite our best efforts,
          billing errors may occur. In the event of a billing error, we shall
          provide the appropriate refund or credit against future payments owed.
          The billing error shall promptly be corrected.{" "}
        </p>
        <p>
          9.4 <u>Subscription Cancelations</u>
        </p>
        <p>
          For reference purposes, the Day-Pass cannot be cancelled and is deemed
          a final sale.{" "}
        </p>
        <p>
          Users may cancel their Silver or Gold subscriptions, at any time,
          using the customer portal link found in the User Account settings. The
          subscription shall then be cancelled, and the User Account shall
          revert back to a free account granting the User the limited accesses,
          unless otherwise specified in the cancelation confirmation provided.
          Once the subscription is cancelled, the User shall no longer benefit
          from the ability to generate additional User Content.{" "}
        </p>
        <p>
          If the subscription cancelation is requested within three (3) calendar
          days of the start of the subscription, we shall refund the
          subscription fees paid. Any cancellation request submitted after the
          three (3) calendar day period shall not give rise to any refunds.
        </p>
        <p>
          Users’ payment information shall cease to be billed on the effective
          cancelation date. Unless otherwise indicated, the corresponding User
          Account shall remain active, even if the User no longer benefits from
          a subscription.{" "}
        </p>

        <p>
          <b>X. FREE TRIALS</b>
        </p>
        <p>
          We may offer a subscription at no charge on a trial basis to certain
          Users for a limited duration. If a User is offered such free trial,
          the terms applicable to the free trial shall be submitted at the time
          of the User’s signing up for the free trial. The User’s usage of the
          free trial shall comply with the terms applicable as well as these
          Terms of Service.{" "}
        </p>
        <p>
          Unless otherwise specified, only those Users who have not previously
          purchased a subscription shall be eligible for a free trial. Upon
          signing up for a free trial, Users shall be asked to sign up for a
          paid subscription. If the User fails to cancel the free trial prior to
          its end, the User’s credit card or payment information shall be
          charged and the paid subscription shall automatically follow.{" "}
        </p>
        <p>
          <b>XI. PROHIBITED USE OF THE PLATFORM</b>
        </p>
        <p>
          Users shall be responsible for all conduct, actions and omissions
          relating to their usage of the Platform and the Services. Users agree
          to not engage in unacceptable use of the Platform, which refers to
          using the Platform to:
        </p>
        <p>
          • Breach any applicable international or local law, government order,
          regulation or policy;
        </p>
        <p>
          • Engage in unlawful or illegal, fraudulent, malicious or otherwise
          discriminatory conduct, or encourage others to use the Platform for
          such illicit purposes;
        </p>
        <p>
          • Reproduce, share, copy, distribute, commercialize or otherwise
          transmit the Platform or any portion thereof, except in accordance
          with these Terms of Service;
        </p>
        <p>
          • Share, transmit, distribute or otherwise declare known false or
          illicit statements or remarks;{" "}
        </p>
        <p>
          • Send, transmit, share, upload or publish spam, chain letters and
          unsolicited materials;
        </p>
        <p>
          • Impersonate an individual, entity, business, or company, or
          intentionally mislead others about the User’s identity;
        </p>
        <p>
          • Access portions of the Platform using any unauthorized means or
          technology, or bypass the Platform’s established security measures;
        </p>
        <p>
          • Collect personal information or data regarding Users of the
          Platform, without obtaining the Users’ consent;
        </p>
        <p>
          • Use automated means, including spiders, robots, crawlers, data
          mining tools, or the like to download or scrape data from the
          Platform;
        </p>
        <p>• Send or transmit harmful components, viruses or damaging files;</p>
        <p>
          • Impair, interfere with or damage the Platform, its servers or any
          portion thereof in any way;
        </p>
        <p>
          • Intimidate, bully, harass, threaten or otherwise cyberstalk Users of
          the Platform;
        </p>
        <p>
          • Decompile, reverse engineer or hack any portion of the Platform or
          attempt to decompile, reverse engineer or hack the Platform or any
          portion thereof;
        </p>
        <p>
          • Refer Users to another platform, application or platform, or
          artificially generating traffic for another platform;
        </p>
        <p>
          • Upload, share, distribute, publish, transmit or otherwise provide
          content or information that violates a third party’s intellectual
          property rights;
        </p>
        <p>
          • Remove, obscure or modify any copyright, trademark or other
          proprietary rights notice marks published on the Platform
        </p>
        <p>
          • Sell, lease, assign or transfer their User Accounts on the Platform;
        </p>
        <p>
          • Use or attempt to use another’s User Account, or create a false
          identity on the Platform;
        </p>
        <p>
          • Share, transmit, distribute or otherwise disperse spam,
          advertisements or unsolicited material, such as ads;
        </p>
        <p>
          • Act in a manner that conflicts with the spirit of these Terms of
          Service.
        </p>
        <p>
          Users shall use and access Generated Content in a manner that is
          consistent with the <i>OpenAI Mission</i> and <i>OpenAI Charter</i>,
          each of which shall be deemed to be incorporated into these Terms of
          Service by reference.{" "}
        </p>
        <p>
          Users further agree to use reasonable efforts to reduce the
          likelihood, severity, and scale of any societal harm caused by their
          usage of the Services, including the use of and sharing Generated
          Content by strictly complying with the{" "}
          <i>OpenAI API Usage Guidelines</i>. We reserve the right to request
          information from Users regarding the practices implemented to reduce
          safety risks, and Users agree to provide such information upon
          request.{" "}
        </p>
        <p>
          Users may be held personally responsible for their failure to comply
          with these Terms of Service, and our guidelines and standards.
          Further, Users agree that we may, without liability and without
          penalty, revoke a User’s right to use the Platform if the User
          breached or attempted to breach any obligation in these Terms of
          Service.
        </p>
        <p>
          <b>XII. CONTENT</b>
        </p>
        <p>
          12.1 <u>Content Uploading</u>
        </p>
        <p>
          Users may upload User Content consisting of text, files, documents,
          graphics, and images, which will be saved in their User Accounts and
          made available solely to them. Users may create what is commonly known
          as “job projects”, thereby recording information relating to a job,
          including the hiring company, the hiring manager, the department, the
          workstream and the job description. All such information shall be
          saved and reserved under each job created by the User through its User
          Account via the Platform
        </p>
        <p>
          For reference purposes, User Content shall include any and all
          copyrightable works, including but not limited to videos, audiovisual
          materials, recordings, sounds, music, musical compositions, lyrics,
          photographs, images, drawings, graphics, artwork, literary works,
          writing, texts, designs, ideas, concepts, and other intellectual
          property rights, including but not limited to original materials
          uploaded by the User in its User Account.
        </p>
        <p>
          12.2 <u>Ownership of User Content and Responsibility</u>
        </p>
        <p>
          The uploading User represents and warrants that it is the sole and
          exclusive owner of the User Content uploaded to its User Account.{" "}
        </p>
        <p>
          We do not claim any ownership rights to the User Content. The User
          Content uploaded shall be the uploading User’s sole responsibility.
          Users may not upload of share User Content which is not their property
          or for which they have not obtained all required consents and
          approvals to upload and share. Failure to comply with this obligation
          may result in copyright infringement and the violation of intellectual
          property rights.{" "}
        </p>
        <p>
          We reserve the right to apply sanctions against the infringing User.
          Such sanctions may include the removal of the infringing User’s User
          Account from the Platform, reporting conduct to the appropriate
          authorities and instituting appropriate legal recourses, without
          limitation. The infringing User waives any rights it had, may have or
          may have against us, and fully releases us, for cooperating with
          authorities and performing investigations, without limitation.
        </p>
        <p>
          12.3 <u>12.3 License to Use Improvements</u>
        </p>
        <p>
          Users agree to grant us a perpetual, irremovable, worldwide,
          unlimited, royalty-free, unpaid, assignable and sublicensable license
          to use the Improvements to improve the Platform or in connection with
          the Platform. The license to the Improvements shall allow us to use,
          distribute, share and employ the Improvements without requiring any
          additional consents or permissions. The license shall be provided
          without compensation owed by us.
        </p>
        <p>
          <b>XIII. CONTENT USAGE AND LICENSING</b>
        </p>
        <p>
          13.1 <u>Usage of User Content and License to Generated Content</u>
        </p>
        <p>
          As such, as part of the Services, User Content shall be used for
          purposes of creating Generated Content through the use of the Services
          and Artificial Intelligence (AI). The Services shall utilize OpenAI to
          create the Generated Content. Considering the usage of OpenAI, Users
          acknowledge that OpenAI may possess ownership or licensing rights to
          the Generated Content.{" "}
        </p>
        <p>
          Subject to Users’ compliance with these Terms of Service, we hereby
          grant Users a non-exclusive, royalty-free, worldwide, limited,
          non-sublicensable license to use, copy, modify, sell, distribute,
          display, create derivative works based upon the Generated Content for
          internal business purposes and/or business operations.
        </p>
        <p>
          13.2 <u>13.2 License to the User Content </u>
        </p>
        <p>
          By publishing or uploading User Content in their User Accounts, Users
          hereby grant us and our affiliates a nonexclusive, worldwide,
          perpetual, irrevocable, royalty-free and transferable license to use,
          publish, distribute, modify and commercialize the User Content, in any
          and all media or form of communication whether now existing or
          hereafter developed, without obtaining additional consent, and without
          restriction. The license granted to us shall allow us to store User
          Content in our servers.{" "}
        </p>
        <p>
          <b>XIV. USER REPRESENTATIONS AND WARRANTIES</b>
        </p>
        <p>
          To upload User Content and use the Services, every User represents and
          warrants the following to us:
        </p>
        <p>
          1. The User is the sole owner of, and has good and valid title to all
          the User Content published or uploaded on its User Account.{" "}
        </p>
        <p>
          2. The User Content constitutes the User’s original work and does not
          violate any third party intellectual property right or any
          legislation.{" "}
        </p>
        <p>
          3. The User Content is not libelous, defamatory, obscene, abusive or
          otherwise illegal. More specifically, the User Content shall not (i)
          infringe applicable laws and regulations, (ii) be unlawful, illegal,
          unethical or defamatory, (iii) advocate violence, murder, terrorism,
          theft or criminal activity, or (iv) consist of plagiarized content or
          content constituting a third party’s work or intellectual property.
        </p>
        <p>
          4. No pending or threatened claim or litigation known to the User
          would have a material adverse impact on the User’s ability to grant us
          a license to use and publish the User Content as required by these
          Terms of Service.
        </p>
        <p>
          5. The User agrees to release and discharge us, from any and all
          liability and claims that may arise out of the User Content published
          or uploaded by the User on the Platform, as further specified in these
          Terms of Service.
        </p>
        <p>
          <b>XV. LIABILITY FOR CONTENT AND REMOVAL</b>
        </p>
        <p>
          We cannot review all User Content submitted by Users in their User
          Accounts on the Platform. The uploading User shall be solely
          responsible for any User Content uploaded, sent or otherwise
          transmitted on the Platform. As such, we shall not be liable for any
          infringing, objectionable or otherwise problematic Content.{" "}
        </p>
        <p>
          We shall be under no obligation to remove any User Content from the
          Platform, however, we may remove any User Content that infringes these
          Terms of Service, any legal obligation or if we determine it would
          preferable to do so due to the nature of the Content. As such, we may
          remove, delete or otherwise edit any User Content that is obscene,
          defamatory or otherwise unlawful, as determined at our discretion.
        </p>
        <p>
          Users may remove User Content stored within their User Accounts on the
          Platform at any time. We shall not be liable if User Content or
          Generated Content is no longer available, unretrievable or otherwise
          inaccessible for any reason.{" "}
        </p>
        <p>
          <b>XVI. COPYRIGHT INFRINGEMENTS</b>
        </p>
        <p>
          We comply with all applicable intellectual property and copyright
          laws. We remain committed to preventing intellectual property and
          copyright infringement on the Platform.{" "}
        </p>
        <p>
          In compliance with the DMCA, we encourage Users to promptly report any
          potential copyright infringement to our attention. If a User owns or
          possesses rights to a trademark or intellectual property right and the
          User has reason to believe its intellectual property or trademark has
          been published on the Platform in violation of rights, or if the User
          has reason to believe there is a violation of any third party
          intellectual property right, the User shall send us a written notice
          to this effect.{" "}
        </p>
        <p>All such notices shall contain the following information:</p>
        <p>
          1. a link to the content on the Platform which is subject to the claim
          of copyright infringement;
        </p>
        <p>
          2. a description of the intellectual property right subject to the
          notice, including the date of the intellectual property’s conception
          or development, photos and other relevant information;
        </p>
        <p>3. the User’s contact information;</p>
        <p>
          4. the User’s interest in the intellectual property subject to the
          notice, if the User is not the lawful owner. If the User is not the
          owner of the intellectual property or trademark, the User may need to
          provide a confirmation or proof that the User is authorized to act on
          behalf of the intellectual property or trademark owner;
        </p>
        <p>
          5. the User’s written confirmation that the notice was drafted and
          sent in good faith; and{" "}
        </p>
        <p>
          6. the User’s written confirmation to the effect that all statements
          and information contained in the notice are true and complete to the
          best of the User’s knowledge.
        </p>
        <p>
          To be valid, the notice must be manually or electronically signed by
          the User and dated.{" "}
        </p>
        <p>
          If the notice is founded, we shall promptly remove the violating
          content from the Platform. We undertake to notify all Users involved
          with such content of its removal. We reserve the right to terminate or
          suspend the accounts of Users who infringe the DMCA, intellectual
          property and/or copyrights, without notice or delay.
        </p>
        <p>
          <b>XVII. THIRD PARTY SITES</b>
        </p>
        <p>
          The Platform may include links or references to Third Party Sites.
          User acknowledges that we are not affiliated with the Third Party
          Sites. The links and references to the Third Party Sites are provided
          for convenience purposes only.
        </p>
        <p>
          We do not endorse any of the Third Party Sites, unless explicitly
          otherwise. Users acknowledge and agree that the inclusion of any links
          or references to Third Party Sites on the Platform shall not imply our
          endorsement or association with such Third Party Sites. The Third
          Party Sites are operated by independent third parties.
        </p>
        <p>
          We make no representations and provides no guarantees with the respect
          to (i) the availability of the Third Party Sites and (ii) the
          functionality of the Third Party Sites. The usage of the Third Party
          Sites by Users shall be at the Users’ sole risk.{" "}
        </p>
        <p>
          Any issues arising out of the Third Party Sites shall be resolved
          exclusively between the User and the relevant Third Party Site. We
          shall not be a party to any litigation, dispute or controversy arising
          out of a User’s use of the Third Party Sites, or usage thereof in
          connection with the Platform.{" "}
        </p>
        <p>
          <b>XVIII. DISCLAIMERS AND EXCLUSION OF WARRANTIES </b>
        </p>
        <p>
          The Platform and the Services shall be provided on an “as is” basis
          and “as available”, without any warranties or guarantees of any kind.
          We shall not provide any of the following implied warranties with
          respect to the Platform and the Services: implied warranties of title,
          non-infringement, merchantability and fitness for a particular
          purpose, and any warranties implied by usage of trade.{" "}
        </p>
        <p>
          We cannot guarantee the Platform and the Services shall meet Users’
          needs or expectations.
        </p>
        <p>
          Without limiting the generality of the foregoing, we cannot guarantee
          that the Platform shall be accessible and available for usage without
          error, omissions or interruption. While we shall use reasonable
          efforts to correct issued or defects, we cannot guarantee that issues
          or defects shall be corrected in a timely manner or corrected at all.{" "}
        </p>
        <p>
          Considering the risks associated with online activity, we cannot
          guarantee that the Platform shall be free of harmful components, such
          as viruses. In consideration thereof, each User’s usage of the
          Platform and the Services shall be at the User’s sole risk and
          expense.
        </p>
        <p>
          <b>XIX. LIMITATIONS OF LIABILITY</b>
        </p>
        <p>
          We shall not be liable for any loss, damage or harm caused by the
          Platform and the usage thereof. Further, we disclaim all liability
          for: (i) any issue affecting our servers, including unauthorized
          access to personal information and data; (ii) any service
          interruptions, bugs or other issues affecting the Platform; and (iii)
          a virus or other harmful component impairing the Platform or the
          User’s device. For purposes of these Terms of Service, harmful
          material shall refer to viruses, worms, Trojan horses, time-bombs,
          keystroke loggers, adware or other programs intended to harm or
          adversely affect computer software or hardware.
        </p>
        <p>
          In no event shall we be liable to Users for any of the following: (i)
          the suspension, termination, restriction or any limitations to User
          Accounts on the Platform; (ii) the suspension, restriction,
          termination or lack of access to features and/or Services on the
          Platform; (iii) the unavailability of the Services, errors affecting
          such Services or the quality of such Services; and (iv) any erroneous,
          incomplete, false, infringing, threatening, defamatory, unlawful or
          otherwise misleading information published on the Platform.
        </p>
        <p>
          To the extent permitted by applicable law, we disclaim full liability
          for any losses and damages arising out of or in connection with the
          Platform and its Services, including but not limited to indirect,
          general, special, punitive, incidental, consequential and accessory
          damages. This exclusion of liability shall apply regardless of whether
          such liability is based on contract, tort, strict liability or another
          theory of law. Further, this exclusion shall apply even if we were
          aware of the possibility of such damages arising.
        </p>
        <p>
          Further, we shall not be liable for any indirect, special or
          consequential loss or damages arising from Users’ access and/or usage
          of the Platform and the Services, including but not limited to loss of
          profits, loss of revenue, loss of business or anticipated earnings,
          loss of goodwill, loss of privacy and personal injury.
        </p>
        <p>
          To the fullest extent permitted by applicable law, our aggregate
          liability shall not exceed: (i) the sum of one hundred dollars ($
          100.00) or (ii) the sum paid for a subscription in the six (6) months
          preceding the event giving rise to the liability, whichever is
          greater.
        </p>
        <p>
          <b>XX. INDEMNIFICATION OBLIGATION</b>
        </p>
        <p>
          Each User agrees to indemnify and hold us, our agents, employees,
          officers, directors, contractors, and affiliates harmless from and
          against all losses, damages, liabilities, costs and expenses in
          connection with any claims, actions, proceedings, investigations,
          loss, fines, penalties, fees, charges, damages, or suits brought by a
          third party, arising out of{" "}
        </p>
        <p>(i) the User’s use of the Platform; </p>
        <p>
          (ii) the User’s gross negligence, recklessness or intentionally
          wrongful act(s),{" "}
        </p>
        <p>
          (iii) any violation, contravention or breach of any agreement or
          obligation of the User pursuant to these Terms of Service;{" "}
        </p>
        <p>
          (iv) any incorrectness in any representation or warranty made by the
          User in connection with these Terms of Service; or and/or{" "}
        </p>
        <p>
          (v) the User’s execution or non-performance of its obligations under
          these Terms of Service.{" "}
        </p>
        <p>
          <b>XXI. CHANGES TO THE PLATFORM</b>
        </p>
        <p>
          We reserve the right to make changes to the Platform, including,
          without limitation, updating features, removing features and editing,
          disabling, removing, condensing or otherwise changing the information
          published on the Platform. We may also terminate, suspend, disable,
          discontinue or otherwise cease granting access to the Platform, in
          full or in part, to certain territories or for Users residing in
          certain areas, without limitation. We shall use reasonable efforts to
          notify affected Users in advance.
        </p>
        <p>
          Users hereby agree that we shall not be liable for any changes or
          modifications made to the Platform and the Services, or for the
          suspension, discontinuance, or termination of the Platform, the
          Services or any portion thereof.{" "}
        </p>
        <p>
          <b>XXII. TERMINATION OF USER ACCOUNTS</b>
        </p>
        <p>
          We reserve the right to investigate, verify, evaluate and otherwise
          look into any potential violations of these Terms of Service, any
          other applicable policies, or any laws, regulations or binding terms.
          Users agree to fully cooperate with us during the course of any such
          investigations by submitting documentation as requested or by
          providing information our request.{" "}
        </p>
        <p>
          We reserve the right to terminate a User Account upon the occurrence
          of any or all of the following: (i) The User failed or neglected to
          pay the sums due on their due date; or (ii) the User’s usage of the
          Platform breaches these Terms of Service, any applicable law or is
          contrary to public interest. We may also terminate a User Account for
          any reason that we consider appropriate, at our sole discretion and
          without notice.{" "}
        </p>
        <p>
          Users may terminate their User Accounts at any time by following the
          instructions for deleting accounts on the Platform. If a User has a
          subscription and terminates its User Account prior to the end of the
          subscription, the User acknowledges that no refunds shall be provided
          for the unused portion of the subscription.
        </p>
        <p>
          Upon the termination of a User Account on the Platform, all
          corresponding User Content, Generated Content and account activity
          shall be deleted, except to the extent necessary to comply with legal
          obligations and to protect our legitimate business interests. We
          therefore encourage Users to backup any User Content and Generated
          Content they wish to keep prior to the termination or deletion of
          their User Accounts on the Platform.{" "}
        </p>
        <p>
          <b>XXIII. ASSIGNMENT </b>
        </p>
        <p>
          We may assign all or part of our rights and obligations hereunder to
          any person, entity, business or company. Users may not assign all or
          part of their rights and obligations hereunder, without our prior
          written consent. Any assignment in violation of the foregoing shall be
          null and void.
        </p>
        <p>
          <b>XXIV. SEVERABILITY</b>
        </p>
        <p>
          Any section or provision of these Terms of Service which is, or
          becomes, illegal, invalid or unenforceable shall be severed from these
          Terms of Service. The remainder of the Terms of Service shall remain
          binding and enforceable.
        </p>
        <p>
          <b>XXV. ENTIRE AGREEMENT</b>
        </p>
        <p>
          These Terms of Service, together with our Privacy Policy, constitute
          the entire agreement between us and Users pertaining to their subject
          matter and supersede all prior agreements, understandings,
          negotiations and discussions between us and Users.{" "}
        </p>
        <p>
          There are no warranties, representations or other agreements in
          connection with the subject matter of these Terms of Service except as
          specifically set forth herein.
        </p>
        <p>
          <b>XXVI. THIRD PARTY RIGHTS</b>
        </p>
        <p>
          These Terms of Service apply and are binding between us and the Users.
          These Terms of Service shall not grant third parties any rights,
          unless otherwise stated.
        </p>
        <p>
          <b>XXVII. GOVERNING LAW </b>
        </p>
        <p>
          These Terms of Service shall be governed by and construed in
          accordance with the laws of the province of Ontario (excluding any
          conflict of laws rule or principle, which might refer such
          interpretation to the laws of another jurisdiction). These Terms of
          Service shall not be governed by the United Nations Convention on
          Contracts for the International Sale of Goods.
        </p>
        <p>
          <b>XXVIII. DISPUTE RESOLUTON</b>
        </p>
        <p>
          28.1 <u>Jurisdiction of the Courts</u>
        </p>
        <p>
          Except for those submitted to arbitration, Disputes shall be submitted
          to the exclusive jurisdiction of the competent courts located in the
          province of Ontario. Every User further waives any objection to
          jurisdiction or venue in any proceeding before said courts.{" "}
        </p>
        <p>
          The following types of Disputes shall be brought before the competent
          courts located in the province of Ontario: (i) any Dispute seeking an
          injunction or other type of equitable relief, or (ii) a Dispute to
          enforce the rights and obligations set forth in these Terms of
          Service. All Disputes relating to matters other than those specified
          in these Terms of Service shall be handled by mandatory arbitration.
        </p>
        <p>
          28.2 <u>Amicable Negotiations</u>
        </p>
        <p>
          Prior to undertaking any legal or arbitration proceedings, the parties
          shall make reasonable efforts to resolve all Disputes amicably. In
          good faith, Users shall employ their best efforts, which shall be no
          less than commercially reasonable efforts, to resolve the Dispute with
          us in an amicable manner.{" "}
        </p>
        <p>
          28.3 <u>Arbitration Proceedings</u>
        </p>
        <p>
          In the event that the Dispute cannot be resolved amicably within
          thirty (30) days, the unresolved Dispute shall be referred to and
          resolved by binding arbitration. The Dispute shall then be handled
          through arbitration administered in accordance with the standards set
          forth by the{" "}
          <i>
            Rules of Conciliation and Arbitration of the International Chamber
            of Commerce.
          </i>{" "}
        </p>
        <p>
          The number of arbitrators shall be one (1). Unless otherwise
          specified, the place of arbitration shall be in the province of
          Ontario. Each party shall bear its own costs and fees for
          participating in the arbitration. Such fees and costs shall include
          administrative costs and the fees payable to the arbitrator.
        </p>
        <p>
          The arbitration proceedings shall be kept strictly confidential. The
          arbitrator shall be granted the required authority to award damages.
          The arbitration award shall be final and binding.{" "}
        </p>
        <p>
          28.4 <u>Remedies and Injunctions</u>
        </p>
        <p>
          Users recognizes that any violation of these Terms of Service may
          cause us significant harm and damages, especially if such violation or
          breach relates to data security, intellectual property or unauthorized
          usage of the Platform. Further, Users acknowledges that monetary
          damages may not be a sufficient remedy for a breach or violation of
          these Terms of Service. We shall be entitled, without waiving any
          other rights or remedies, to such injunctive or equitable relief as
          may be deemed proper by a court of competent jurisdiction.
        </p>
        <p>
          28.5 <u>Cost Recovery</u>
        </p>
        <p>
          If any litigation or arbitration proceeding is commenced in connection
          with these Terms of Service, the prevailing party, as determined by
          the court or arbitrator, shall be entitled to claim all reasonable
          attorney fees, costs and necessary disbursements incurred in such
          action or proceeding.
        </p>
        <p>
          28.6 <u>Waivers to Jury Trial and Class Action</u>
        </p>
        <p>
          Users waive their right to a jury trial in any litigation or
          arbitration proceedings commenced in connection with these Terms of
          Service, the Platform or the Services. Users acknowledge that the
          arbitration proceedings shall be individual and shall not consist of
          class arbitration. All claims shall be brought in Users’ individual
          capacity, and not as part of a class action or another party’s claim.{" "}
        </p>
        <p>
          28.7 <u>Time Frame</u>
        </p>
        <p>
          Any claim or Dispute relating to these Terms of Service and the
          Platform shall be introduced within one (1) year of the event giving
          rise to the claim. Any claim or Dispute introduced after the one (1)
          year delay shall be barred.
        </p>
        <p>
          <b>XXIX. QUESTIONS AND COMMENTS</b>
        </p>
        <p>
          We invite all Users to send questions, inquiries, feedback and
          comments regarding the Platform or these Terms of Service. Questions,
          comments and notices may be sent to us by email (
          <a href="#">legal@R3achout.AI</a>) or registered or certified mail to
          our registered offices:
        </p>
        <p>
          <b>R3achout.AI</b>
          <br />
          7 Bayview Station Rd.,
          <br />
          Ottawa, Ontario
          <br />
          Canada, K1Y 2C5
        </p>
      </div>

      <Footer />
    </>
  );
};

export default TermsOfServices;
