import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Logo from "../../components/Logo";

import { Pagination } from "swiper/modules";
import Input from "./components/Input";
import { AuthContext, useUserContext } from "../../Context/UserContext";
import { useState } from "react";
import { useContext } from "react";
import Popup from "../../components/Popup";
import { GoogleAuthProvider, unlink } from "firebase/auth";
import google from "../../assets/img/google.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const SignUp = () => {
  const navigate = useNavigate();
  const { creatUser } = useContext(AuthContext);
  const { providerLogin } = useContext(AuthContext);
  const { setRefetch } = useUserContext();
  const { login, logout } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [tab, setTab] = useState("google");
  const [password, setpassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [disable, setDisable] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorType, setErrorType] = useState("");

  async function checkUserSignupMethod(email, firstName) {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_END_POINT}/user/signupMethod`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, firstName }),
      }
    );

    const data = await response.json(); // Parsing the JSON response

    if (!data.exists) {
      // Handle scenario where no user exists
    } else if (!data.signedUpWithGoogle) {
      setErrorMessage(
        "You have already signed up with this email,please Sigin using email and password from here "
      );

      setErrorType("EmailSignIn");
      return;

      // Handle scenario where user did not sign up with Google
    } else {
      // Handle scenario where user signed up with Google
    }
  }

  const handleGoogleSignIn = () => {
    if (disable) {
      setErrorMessage("Please accept the Terms of Service");
      return;
    }
    const googleProvider = new GoogleAuthProvider();

    providerLogin(googleProvider)
      .then(async (result) => {
        // Access the user's email from the result object
        const user = result.user;
        const email = user.email;

        // Access the user's name and profile photo
        const firstName = user.displayName; // User's first name
        const photo = user.photoURL; // User's profile photo URL

        try {
          // Assuming createUser returns a Promise
          // const signupCheck = await checkUserSignupMethod(email, firstName);
          // if (!signupCheck.continue) {
          //   console.log({ user });
          //   await unlink(user, "google.com").catch((error) => {
          //     console.error("Failed to unlink:", error);
          //   });
          //   logout();

          //   return;
          // }
          if (result) {
            const jobData = {
              words: 0,
              limitWords: 1000,
              email: email.toLowerCase(),
              firstName: firstName,
              company: "",
              location: "",
              // Include the user's photo URL in the jobData object
              photo: photo,
              // customerId: '',
              subscribed: false,
              productName: "Free",
              signupMethod: "Google",
            };

            const userData = {
              email: email,
            };

            try {
              const response = await fetch(
                `${process.env.REACT_APP_SERVER_END_POINT}/api/welcome/sendEmail`,

                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ email, firstName }),
                }
              );

              if (response.ok) {
                ////('Email sent successfully');
                // Handle success as needed
              } else {
                console.error("Failed to send email");
                // Handle error as needed
              }
            } catch (error) {
              console.error("Error sending email", error);
              // Handle error as needed
            }

            const userResponse = await fetch(
              `${process.env.REACT_APP_SERVER_END_POINT}/post/user`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(jobData),
              }
            );

            if (userResponse.ok) {
              setRefetch((prevref) => !prevref);
              // logout();

              navigate("/home");
            } else {
              console.error("POST request failed");
            }
          } else {
            console.error("User creation failed");
          }
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => console.error(error));
  };

  //google popup registration
  const handleLogin = async (event) => {
    if (firstName.length < 1) {
      setErrorMessage("Kindly provide your first name, please.");
      return;
    }
    if (lastName.length < 1) {
      setErrorMessage("Kindly provide your last name, please.");
      return;
    }

    if (email.length < 1) {
      setErrorMessage("Kindly provide your email, please.");
      return;
    }
    if (password.length < 1) {
      setErrorMessage("Kindly provide your password, please.");
      return;
    }

    if (disable) {
      setErrorMessage("Please accept the Terms of Service");
    } else {
      // send email
      setErrorMessage("");

      try {
        const jobData = {
          words: 0,
          limitWords: 1000,
          email: email.toLowerCase(),
          firstName: firstName,
          lastName: lastName,
          company: "",
          location: "",
          amount_paid: 0,
          subscribed: false,
          customerId: "",
          productName: "Free",
          signupMethod: "emailAndPassword",
        };

        const userCredential = await creatUser(email, password);

        if (userCredential) {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_END_POINT}/api/welcome/sendEmail`,

            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email, firstName }),
            }
          );

          if (response.ok) {
            //('jobData',jobData)
            //send email

            try {
              const userResponse = await fetch(
                `${process.env.REACT_APP_SERVER_END_POINT}/post/user`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(jobData),
                }
              );

              if (userResponse.ok) {
                logout();

                navigate("/login");
                ////("Email sent successfully");
                // Handle success as needed
              } else {
                console.error("Failed to send email");
                // Handle error as needed
              }
            } catch (error) {
              console.error("Error sending email", error);
              // Handle error as needed
            }

            //send email
          } else {
            const responseData = await response.json();

            if (responseData.error) {
              // Set error message to state
              setErrorMessage(responseData.error);
            } else {
              console.error("POST request failed");
            }
          }
        } else {
          console.error("User creation failed");
        }
      } catch (error) {
        // Handle Firebase authentication errors
        if (error.code === "auth/email-already-in-use") {
          setErrorMessage("Email is already in use.");
        } else if (error.code === "auth/weak-password") {
          setErrorMessage("Password must be at least 6 characters.");
        } else {
          console.error(error);
        }
      }
    }
  };
  const yourFunction = () => {
    // Your additional logic here
    setDisable(false);
  };

  return (
    <>
      <section className="register-section">
        <div className="row g-0">
          <div className="col-lg-6 d-none d-lg-block">
            <div className="register-left">
              <div className="register-left-inner">
                <Logo variant="white" />
                <div className="slide-wrapper">
                  <Swiper
                    slidesPerView={1}
                    modules={[Pagination]}
                    pagination={{ clickable: true, className: "ddd" }}
                  >
                    {[
                      {
                        title: "Start your Journey With US",
                        description:
                          "Embark on a transformative journey with R3achout.AI. This AI-driven web application is a game-changer in email creation for recruiters. Packed with a robust set of features, it's designed to refine your recruitment strategies and help you outshine your competition. Experience the ultimate recruitment copilot with R3achout.AI.",
                      },
                      {
                        title: "Maximize Your Time, Maximize Your Impact",
                        description:
                          "Time is of the essence in recruitment, and R3achout.AI is your ultimate recruitment sidekick. Created by recruiters, for recruiters, our tool empowers you to accomplish more in less time, significantly boosting your recruitment efficiency. Transform your approach and achieve better results with R3achout.AI.",
                      },
                      {
                        title: "Elevate Your Candidate Screening Process",
                        description:
                          "Screen candidates with unmatched proficiency using R3achout.AI. Our platform equips you with the insights and tools to ask the right questions, allowing you to thoroughly vet candidates and maintain an exceptional quality in your candidate pool. Be the expert in candidate assessment with R3achout.AI at your side.",
                      },
                    ].map((slide, i) => (
                      <SwiperSlide key={i}>
                        <div
                          className={`${
                            i === 2 ? "slide-item-elevate" : "slide-item"
                          }`}
                        >
                          <h4 className="title">{slide.title}</h4>
                          <p>{slide.description}</p>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          {tab === "google" ? (
            <div className="col-lg-6 !flex !flex-col !items-center !justify-center  ">
              <div className="container  mb-20">
                <div className="register-wrapper">
                  <div className="auth-logo d-lg-none">
                    <Logo />
                  </div>
                  <div className="text-center">
                    <h3 className="auth-title">Register to R3achout.AI</h3>
                    <div className="auth-subtitle">
                      Create your account. It’s free, no credit card required
                      and only takes a few minutes.
                    </div>
                  </div>

                  <div className="mt-4 pt-lg-3">
                    <div className="login-wrapper pt-2   ">
                      <button
                        onClick={handleGoogleSignIn}
                        type="button"
                        className="backup-login  !bg-[#23b574] text-white hover:!bg-white hover:!text-black transition ease-in-out duration-500  "
                      >
                        <img src={google} alt="" />{" "}
                        <span>Sign up with Google</span>
                      </button>

                      <div className="d-flex flex-wrap justify-content-between mt-3">
                        <label className="form-check form--check flex-grow-1">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={yourFunction}
                          />

                          <span
                            className="form-check-label"
                            for="flexCheckChecked"
                          >
                            By clicking here,I state that I have
                            read,understood,and agree to the{" "}
                            <a href="/tos" target="_blank">
                              {" "}
                              Terms of Service
                            </a>
                          </span>
                        </label>
                      </div>

                      {errorType === "EmailSignIn" ? (
                        <p className="my-3 mt-4 text-center text-base !font-semibold !text-red-500">
                          {errorMessage.slice(0, -5)}{" "}
                          <Link to="/login" className="text-blue-500">
                            here
                          </Link>
                          .
                        </p>
                      ) : (
                        <p className="my-3 mt-4 text-center text-base !font-semibold !text-red-500">
                          {errorMessage}
                        </p>
                      )}
                    </div>

                    <div className="or ">
                      <span>Or Sign up with Email</span>
                    </div>

                    <button
                      // data-bs-toggle="modal"
                      data-bs-target="#job-save-pop"
                      onClick={() => {
                        setTab("email");
                        setErrorMessage("");
                      }}
                      className="backup-login font-[500] hover:bg-[#23b574] hover:text-white transition ease-in-out duration-500 "
                      type="submit"
                    >
                      Sign up with Email
                    </button>

                    <div className="mt-4 pt-lg-3 text-center alt-txt">
                      <span className="font-medium">
                        Already have an account? please
                      </span>{" "}
                      <Link to="/login" className="text-blue-500">
                        Login
                      </Link>
                    </div>
                  </div>

                  <div className="text-center mt-4 mt-xl-5 pt-4 ">
                    &copy;{new Date().getFullYear()} R3achout.AI all rights
                    reserved
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-6 !flex !flex-col !items-center !justify-center  ">
              <div className="container  mb-20">
                <div className="register-wrapper">
                  <div className="auth-logo d-lg-none">
                    <Logo />
                  </div>
                  <div className="text-center">
                    <h3 className="auth-title">Register to R3achout.AI</h3>
                    <div className="auth-subtitle">
                      Create your account. It’s free, no credit card required
                      and only takes a few minutes.
                    </div>
                  </div>
                  <div className="w-full flex items-center mb-12 gap-3">
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      onClick={() => {
                        setTab("google");
                        setErrorMessage("");
                      }}
                      className="cursor-pointer"
                    />

                    <button
                      type="button"
                      onClick={() => {
                        setTab("google");
                        setErrorMessage("");
                      }}
                      className="font-semibold "
                    >
                      {" "}
                      Back
                    </button>
                  </div>

                  <div className="row mb-4">
                    <div className="col-6">
                      <Input
                        placeholder="Ex : John"
                        label="First Name"
                        required
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="col-6">
                      <Input
                        placeholder="Doe"
                        label="Last Name"
                        required
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <Input
                      label="Email"
                      required
                      placeholder="example@mail.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      label="Password"
                      required
                      placeholder="**********"
                      type="password"
                      eye
                      onChange={(e) => setpassword(e.target.value)}
                    />
                  </div>
                  <div className="d-flex flex-wrap justify-content-between mt-3">
                    <label className="form-check form--check flex-grow-1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={yourFunction}
                      />

                      <span className="form-check-label" for="flexCheckChecked">
                        By clicking here,I state that I have read,understood,and
                        agree to the{" "}
                        <a href="/tos" target="_blank">
                          {" "}
                          Terms of Service
                        </a>
                      </span>
                    </label>
                  </div>
                  <div className="mt-4 pt-lg-3">
                    <button
                      // data-bs-toggle="modal"
                      data-bs-target="#job-save-pop"
                      onClick={handleLogin}
                      className="submit-btn"
                      type="submit"
                    >
                      Register Now
                    </button>
                  </div>

                  {/* {errorMessage && (
        <h4
          style={{ color: "red" }}
          className="my-3 mt-4 text-center text-base"
        >
          {errorMessage}{" "}
        </h4>
      )} */}

                  {errorType === "EmailSignIn" ? (
                    <p className="my-3 mt-4 text-center text-base !font-semibold !text-red-500">
                      {errorMessage.slice(0, -5)}{" "}
                      <Link to="/login" className="text-blue-500">
                        here
                      </Link>
                      .
                    </p>
                  ) : (
                    <p className="my-3 mt-4 text-center text-base !font-semibold !text-red-500">
                      {errorMessage}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default SignUp;
