import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UpdateAccount from "./UpdateAccount";
import Test from "./Test";
import moment from "moment";

const DetailsUser = () => {
  const { email } = useParams();
  const [userInfo, setUserInfo] = useState({});
  const [refetch, setrefetch] = useState(false);

  // take user info
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/user/accountType?email=${email}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        ////({ data });

        setUserInfo(data);

        // Save userInfo to local storage
        localStorage.setItem("userInfo", JSON.stringify(data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [email, refetch]);

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>User Details</h2>
      <div style={styles.userProfile}>
        <img
          src={userInfo.photo}
          alt="User Profile"
          style={styles.profilePicture}
        />
        <h3 style={styles.userName}>{userInfo.firstName}</h3>
        <p style={styles.userEmail}>{userInfo.email}</p>
      </div>
      <table style={styles.table}>
        <tbody>
          {Object.entries(userInfo).map(([key, value]) => (
            <tr key={key} style={styles.tableRow}>
              <td style={styles.tableCell}>{key}</td>
              <td style={styles.tableCell}>
                {typeof value === "object"
                  ? JSON.stringify(value)
                  : key === "createdAt" ||
                    key === "expiredDate" ||
                    (key === "loginTime" &&
                      key !== "limitWords" &&
                      key !== "email" &&
                      key !== "_id")
                  ? moment(value).format("DD/MM/YYYY hh:mm:ss A")
                  : value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <UpdateAccount
        refetch={refetch}
        setrefetch={setrefetch}
        email={email}
      ></UpdateAccount>

      <Test></Test>
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    textAlign: "center",
  },
  header: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  userProfile: {
    marginBottom: "20px",
  },
  profilePicture: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    objectFit: "cover",
    marginBottom: "10px",
  },
  userName: {
    fontSize: "18px",
    marginBottom: "5px",
  },
  userEmail: {
    color: "#555",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableRow: {
    border: "1px solid #ddd",
  },
  tableCell: {
    padding: "10px",
    border: "1px solid #ddd",
  },
};

export default DetailsUser;
