import axios from "axios";
import React, { useState, useEffect } from "react";
import { useUserContext } from "../../../Context/UserContext";

const UpdateAccount = ({ email, setrefetch, refetch }) => {
  const { handleAction } = useUserContext();
  const [userData, setUserData] = useState({
    words: "",

    accountType: "",
  });

  const handleUpdate = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_END_POINT}/update/account/${email}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        alert("update done");
        handleAction(9);
        // window.location.reload();
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error updating user data:", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    try {
      const confirmed = window.confirm("Are you sure you want to delete?");

      if (confirmed) {
        setIsLoading(true);
        const response = await axios.delete(
          `${process.env.REACT_APP_SERVER_END_POINT}/delete/account/${email}`
        );
        // Handle success, e.g., show a success message, update state, or navigate to another page
        alert("Account deleted !");
        window.location.href = "/userlist"; // Redirect to userlist after successful deletion
      } else {
        // Handle cancellation, e.g., show a message or update state
        console.log("Deletion canceled");
      }
    } catch (error) {
      console.error(error.response.data.message);
      // Handle error, e.g., show an error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  const [inputDate, setInputDate] = useState("");
  const [resultDate, setResultDate] = useState(null);

  const handleInputChange = (event) => {
    setInputDate(event.target.value);
  };

  const handleCalculate = async () => {
    try {
      const response = await axios.put(
        // `http://localhost:5000/test-cron/`,
        `${process.env.REACT_APP_SERVER_END_POINT}/test-cron`,
        // `https://reactout-server.onrender.com/test-cron`,
        { date: inputDate, email }
      );

      setrefetch(!refetch);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <h1>Update Account</h1>

      <div>
        <label>Date and Time:</label>
        <input
          type="datetime-local"
          defaultValue={inputDate}
          onChange={handleInputChange}
        />
        <button onClick={handleCalculate}>Test Cron</button>
      </div>

      <br />
      <label>
        Words:
        <input
          type="text"
          name="words"
          value={userData.words}
          onChange={handleChange}
        />
      </label>
      <br />
      <br />

      {/* <label>
                package Name:
                <select name="productName" value={userData.productName} onChange={handleChange}>
                    <option value="">Select...</option>
                    <option value="bronze">Bronze</option>
                    <option value="Silver">Silver</option>
                    <option value="Gold">Gold</option>
                </select>
            </label> */}
      <br />
      <br />

      <label>
        Account type:
        <select
          name="accountType"
          value={userData.accountType}
          onChange={handleChange}
        >
          <option value="">Select...</option>
          <option value="admin">Admin</option>
          <option value="moderator">Moderator</option>
          <option value="user">user</option>
        </select>
      </label>
      <br />
      <br />
      <button onClick={handleUpdate}>Update Account</button>
    </div>
  );
};

export default UpdateAccount;
