import React, { useContext, useEffect, useRef, useState } from "react";
import TextEditor from "../../../../components/TextEditor";
import Input from "../../../auth/components/Input";
import axios from "axios";
import { AuthContext, useUserContext } from "../../../../Context/UserContext";
import OpenAI from "openai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutOfCreditPopup from "../../components/OutOfCreditPopup";
import JobDescriptionPopup from "../../components/JobDescriptionPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useCtrlASelect } from "../../../../hooks/useCtrlSelect";

const Interview = () => {
  const { projectId, projectUpdateId } = useUserContext();
  const {
    FullJobData,
    JobName,
    numberOfQst,
    setNumberOfQst,
    roleLevel,
    setRoleLevel,
    questionsComplexity,
    setQuestionsComplexity,
    areaFocus,
    setAreaFocus,
    questionsType,
    setQuestionsType,
    interviewQualifications,
    setInterviewQualifications,
    userInfo,
    handleInterviewClick,
    interviewLoading,
    isInterviewSaved,
    errors,
    OpenPopup,
    setOpenPopup,
    interviewBotResponses0,
    setInterviewBotResponses0,
    // interviewBotResponses1 ,
    // setInterviewBotResponses1 ,
  } = useUserContext();

  const [value, setValue] = useState("");

  const [open, setOpen] = useState(true);
  const [decodedValue, setDecodedValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [firstEffectFinished, setFirstEffectFinished] = useState(false);
  const [more, setMore] = useState(false);
  const [moreOptionsPopup, setMoreOptionsPopup] = useState(false);

  // get job title from localstorage
  let savedJobTitle = localStorage.getItem("jobTitle");
  const checkForChanges = () => {
    const newValue = localStorage.getItem("jobTitle");
    if (newValue !== savedJobTitle) {
      // Value has changed, update savedJobTitle
      savedJobTitle = newValue;
      // Do something with the updated value
      //(savedJobTitle);
    }
  };
  const pollingInterval = 500; // 500 milliseconds
  setInterval(checkForChanges, pollingInterval);
  checkForChanges();

  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);

  useCtrlASelect(textareaRef1);
  useCtrlASelect(textareaRef2);

  //   get job details from localstorage
  let savedJobDetails = localStorage.getItem("jd");
  const checkForChangesDetails = () => {
    const newValue = localStorage.getItem("jd");
    if (newValue !== savedJobDetails) {
      // Value has changed, update savedJobTitle
      savedJobDetails = newValue;
      // Do something with the updated value
      //('savedJobDetails', savedJobDetails);
    }
  };
  const pollingIntervalDetails = 500; // 500 milliseconds
  setInterval(checkForChangesDetails, pollingIntervalDetails);
  checkForChangesDetails();

  //   get job details from localstorage
  /////////////////////////////--Get data from localStorage--////////////////////////////

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setDecodedValue(decodeURIComponent(value));
  }, [value]);

  //nc

  const [response, setResponse] = useState("");

  const allValues = [];

  for (const item of response) {
    const values = Object.values(item);
    allValues.push(...values);
  }
  const get = "editorinterview";
  const post = "interview";
  // const heading = `Interview - ${JobName ? JobName : ""} `;
  const heading = `Interview - ${""} `;
  const deleteEditor = "editorInterviewCollection";
  // Get the current date and time
  const currentDate = new Date();

  // Define options for formatting
  const options = {
    day: "numeric",
    month: "long",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const singleObject = FullJobData[0];
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPEN_AI_KEY,
    dangerouslyAllowBrowser: true,
  });

  const [jdEmptyPopup, setjdEmptyPopup] = useState(false);

  const [jobData, setJobData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/get/interview/inputBox/${projectId}`
        );
        const result = await response.json();
        if (result) {
          setJobData(result);
          setInterviewBotResponses0(result[0]?.interviewBotResponses0 || "");
          // setInterviewBotResponses1 //(result[0]?.interviewBotResponses1 // || "");
          setQuestionsComplexity(result[0]?.questionsComplexity || "-");
          setNumberOfQst(result[0]?.numberOfQst || "3");
          setQuestionsType(result[0]?.questionsType || "-");
          setRoleLevel(result[0]?.roleLevel || "-");
          setAreaFocus(result[0]?.areaFocus || "-");
          setInterviewQualifications(result[0]?.interviewQualifications || "");
        }

        setFirstEffectFinished(true);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (projectId) {
      fetchData();
    }
  }, [projectId, isInterviewSaved]);

  // useEffect(() => {
  //   if (firstEffectFinished) {
  //     const apiUrl = `${process.env.REACT_APP_SERVER_END_POINT}/update/interview/inputBox/${projectId}`;

  //     // Your job data, including interviewBotResponses
  //     const jobData = {
  //       interviewBotResponses0: interviewBotResponses[0],
  //       interviewBotResponses1 //: interviewBotResponses[1],
  //     };

  //     // Fetch options
  //     const requestOptions = {
  //       method: "PUT", // Change this to PUT
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(jobData),
  //     };

  //     // Make the PUT request using fetch
  //     fetch(apiUrl, requestOptions)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         ////console'Data from server:', data);
  //         // Handle the response data as needed
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //         // Handle errors
  //       });
  //   }
  // }, [interviewBotResponses, firstEffectFinished]);

  // useEffect(() => {
  //   setInterviewBotResponses([]);
  // }, [projectId]);

  const handleOpenJd = () => {
    if (singleObject == undefined) {
      alert("Please select job");
      return;
    }

    if (singleObject.jd == undefined) {
      setjdEmptyPopup(true);
    } else {
      handleInterviewClick(projectId);
    }
  };

  const { user } = useContext(AuthContext);

  const handleMoreOption = () => {
    if (userInfo.productName !== "Gold") {
      // setMoreOptionsPopup(true);
    }

    setMore(!more);
  };

  return (
    <>
      <section className="jobs-management bg-body-2  min-h-[80vh] ">
        <form onSubmit={handleSubmit} className="dashboard-form-2">
          <div className="row g-4 flex-lg-nowrap">
            <div
              className={` ${
                !open ? "col-lg-12" : "col-lg-8 "
              } lg:min-h-[55vh]`}
            >
              <div className="flex flex-col lg:flex-row gap-3 h-full  ">
                <div className="col-lg-4  h-full mt-3">
                  <div className="form-group mb-4">
                    <Input
                      label="Number Of Questions"
                      onChange={(e) => {
                        setNumberOfQst(e.target.value);

                        // setDirty(true);
                      }}
                      options={numbers}
                      value={numberOfQst}
                    />
                  </div>

                  {more && (
                    <>
                      <div className="form-group mb-4">
                        <Input
                          label="Role level"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              userInfo.productName === "Gold" ||
                              userInfo.productName === "bronze"
                            ) {
                              setRoleLevel(value);
                            } else {
                              setMoreOptionsPopup(true);
                            }
                            // setDirty(true);
                          }}
                          options={roles}
                          value={roleLevel}
                          isDisabled={
                            userInfo.productName !== "Gold" &&
                            userInfo.productName !== "bronze"
                          }
                        />
                      </div>
                      <div className="form-group mb-4">
                        <Input
                          label="Complexity of questions"
                          placeholder="complexity of questions"
                          value={questionsComplexity}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              userInfo.productName === "Gold" ||
                              userInfo.productName === "bronze"
                            ) {
                              setQuestionsComplexity(value);
                            } else {
                              setMoreOptionsPopup(true);
                            }
                            // setDirty(true);
                          }}
                          options={complexities}
                          isDisabled={
                            userInfo.productName !== "Gold" &&
                            userInfo.productName !== "bronze"
                          }
                        />
                      </div>
                      <div className="form-group mb-4">
                        <Input
                          label="Focus area "
                          placeholder="Focus area"
                          value={areaFocus}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              userInfo.productName === "Gold" ||
                              userInfo.productName === "bronze"
                            ) {
                              setAreaFocus(value);
                            } else {
                              setMoreOptionsPopup(true);
                            }
                            // setDirty(true);
                          }}
                          options={focuses}
                          isDisabled={
                            userInfo.productName !== "Gold" &&
                            userInfo.productName !== "bronze"
                          }
                        />
                      </div>
                      <div className="form-group mb-4">
                        <Input
                          label="Type of questions"
                          value={questionsType}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              userInfo.productName === "Gold" ||
                              userInfo.productName === "bronze"
                            ) {
                              setQuestionsType(value);
                            } else {
                              setMoreOptionsPopup(true);
                            }
                            // setDirty(true);
                          }}
                          options={types}
                          isDisabled={
                            userInfo.productName !== "Gold" &&
                            userInfo.productName !== "bronze"
                          }
                        />
                      </div>

                      <div className="form-group mb-4">
                        {console.log(
                          userInfo.productName !== "Gold" &&
                            userInfo.productName !== "bronze"
                        )}
                        <Input
                          label="Qualifications"
                          placeholder={"qualifications"}
                          value={interviewQualifications}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              userInfo.productName === "Gold" ||
                              userInfo.productName === "bronze"
                            ) {
                              setInterviewQualifications(value);
                            } else {
                              setMoreOptionsPopup(true);
                            }
                            // setDirty(true);
                          }}
                          isDisabled={
                            userInfo.productName !== "Gold" &&
                            userInfo.productName !== "bronze"
                          }
                        />
                      </div>
                    </>
                  )}
                  <button
                    className="__btn-outline w-100 m-0  !font-[500] !leading-5 !text-base !capitalize "
                    onClick={handleMoreOption}
                  >
                    {!more ? "More Options..." : "Less Options..."}
                  </button>

                  <div className="d-flex  flex-col items-center   gap-20px save-info">
                    {errors.interviewErrors && (
                      <p className="text-red-500 text-sm  font-semibold">
                        {" "}
                        {errors.interviewErrors}
                      </p>
                    )}
                    <button
                      onClick={handleOpenJd}
                      className="__btn w-100 mt-8 !font-[500] !leading-5 !text-base !capitalize"
                      disabled={interviewLoading}
                    >
                      {interviewLoading ? (
                        <div className="!flex justify-around gap-2 items-center  w-full">
                          <p className="mb-0"> Loading…</p>
                          <FontAwesomeIcon icon={faCircleNotch} spin />
                        </div>
                      ) : (
                        "Get Interview Questions"
                      )}
                    </button>
                  </div>
                </div>

                <div className="flex flex-col gap-3 w-full">
                  <h1 className=" form--label !font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D] ">
                    Suggested Interview Questions{" "}
                  </h1>
                  {/* <label className="form--label mb-4">Interview Assessment</label> */}

                  <div
                    className="form-control form--control __output-text overflow-y-auto"
                    // style={{ maxHeight: "200px", overflowY: "auto" }}
                    readOnly={true}
                    ref={textareaRef1}
                    contentEditable
                    spellCheck="false"
                    dangerouslySetInnerHTML={{
                      __html: loading
                        ? ""
                        : interviewBotResponses0
                            .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>") // Convert markdown bold to HTML bold
                            .replace(/\n/g, "<br />"),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={`${open ? "col-lg-4" : "col-lg-12"}`}>
              <div>
                <TextEditor
                  get={get}
                  post={post}
                  deleteEditor={deleteEditor}
                  title={heading}
                  savedJobTitle={savedJobTitle}
                  value={response}
                  setValue={setValue}
                  open={open}
                  setOpen={setOpen}
                />
              </div>
            </div>
          </div>
        </form>
      </section>
      <OutOfCreditPopup
        open={OpenPopup}
        setOpen={setOpenPopup}
        heading={`You've Hit the Limit!`}
        text={
          userInfo.productName !== "Gold"
            ? "You've reached your maximum for this feature, but your potential is unlimited. Upgrade now to continue without interruption."
            : "You've used all your word credits for this month. Stay tuned, your credits will refresh soon."
        }
        userInfo={userInfo}
      />

      <OutOfCreditPopup
        open={moreOptionsPopup}
        setOpen={setMoreOptionsPopup}
        heading={`Unlock More Options!`}
        text={
          "Why limit yourself? As a subscriber,you get more tools,more features and more possibilities. Eelevate your experience with R3chout.AI and join our community"
        }
        userInfo={userInfo}
      />

      <JobDescriptionPopup
        open={jdEmptyPopup}
        setOpen={setjdEmptyPopup}
        handleUserInput={(projectId) => handleInterviewClick(projectId)}
      ></JobDescriptionPopup>
      <ToastContainer />
    </>
  );
};

const roles = [
  {
    name: "-",
    value: "-",
  },
  {
    name: "Junior",
    value: "Entry-level",
  },
  {
    name: "Intermediate",
    value: "Mid-level",
  },
  {
    name: "Senior",
    value: "Senior-level",
  },
  {
    name: "Lead",
    value: "Lead",
  },
  {
    name: "Advisor",
    value: "Advisor",
  },
  {
    name: "Manager",
    value: "Manager",
  },
  // {
  //   name: "Director",
  //   value: "Director",
  // },
  // {
  //   name: "Executive",
  //   value: "Executive",
  // },
  // {
  //   name: "Chief Officer",
  //   value: "Chief Officer",
  // },
];

const numbers = [
  {
    name: "1",
    value: "1",
  },
  {
    name: "2",
    value: "2",
  },
  {
    name: "3",
    value: "3",
  },
  {
    name: "4",
    value: "4",
  },
  {
    name: "5",
    value: "5",
  },
  {
    name: "6",
    value: "6",
  },
];

const complexities = [
  {
    name: "-",
    value: "-",
  },
  {
    name: "Simple",
    value: "Basic",
  },
  {
    name: "Medium",
    value: "Intermediate",
  },
  {
    name: "Advanced",
    value: "Complex",
  },
];

const focuses = [
  {
    name: "-",
    value: "-",
  },
  {
    name: "Work ethics",
    value: "Work ethics",
  },
  {
    name: "Skills",
    value: "Competencies",
  },
  {
    name: "Technical skills",
    value: "Technical expertise",
  },
  {
    name: "Problem-solving",
    value: "Problem-solving",
  },
  {
    name: "Communication",
    value: "Communication",
  },
  {
    name: "Teamwork",
    value: "Teamwork",
  },
  {
    name: "Leadership",
    value: "Leadership",
  },
  {
    name: "Adaptability",
    value: "Adaptability",
  },
];

const types = [
  {
    name: "-",
    value: "-",
  },
  {
    name: "Behavioral",
    value: "Behavioral",
  },
  {
    name: "Situational",
    value: "Situational",
  },
  {
    name: "Case Study",
    value: "Case Study",
  },
  {
    name: "Technical",
    value: "Technical",
  },
  {
    name: "Motivational",
    value: "Motivational",
  },
  {
    name: "Competency",
    value: "Competency",
  },
];

// Set the progress value (between 0 and 100)

export default Interview;
