import React from "react";
import { useUserContext } from "../../../../../Context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const Candidate = ({
  textRef1,
  createIdealCandidatePersonaHTML,
  textRef2,
  createJobTitlesHTML,
  handleOpenJd,
}) => {
  const { searchLoading, errors } = useUserContext();

  return (
    <div className="mb-4 ">
      <div
        className={`display-text rounded p-3   open-box`}
        style={{
          minHeight: "100px",
          maxHeight: "auto", // Updated to 'auto'
          overflowY: "auto", // Updated to 'auto'
          //   display: isOpen[0] ? "block" : "none",
        }}
      >
        <div className="search-feature-container">
          <div
            style={{
              width: "100%",
            }}
            className="flex flex-col gap-4  "
          >
            <p className="!font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D] ">
              Ideal Candidate Persona
            </p>
            <div
              className="feature-box "
              tabIndex="0"
              ref={textRef1}
              contentEditable
              spellCheck="false"
              dangerouslySetInnerHTML={createIdealCandidatePersonaHTML}
            ></div>
          </div>
          <div
            style={{
              width: "80%",
            }}
            className="jobtitle-box flex flex-col gap-4"
          >
            <p className="!font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D] ">
              Job Titles
            </p>
            <div
              className="feature-box "
              ref={textRef2}
              contentEditable
              spellCheck="false"
              dangerouslySetInnerHTML={createJobTitlesHTML}
            ></div>
          </div>
        </div>

        <div className="col-12 d-flex justify-content-end mt-4 pt-3">
          <div className="d-flex  flex-col items-center   gap-20px save-info">
            {errors && errors.candidateSearchError && (
              <p className="text-red-500 text-sm  font-semibold">
                {" "}
                {errors.candidateSearchError}
              </p>
            )}
            <button
              onClick={() => handleOpenJd("candidate")}
              className="__btn m-0 px-xl-5 w-[295px] !font-[500] !leading-5 !text-base !capitalize "
              disabled={searchLoading.candidateLoading}
            >
              {searchLoading && searchLoading.candidateLoading ? (
                <div className="!flex justify-around gap-2 items-center  w-full">
                  <p className="mb-0"> Loading…</p>
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
              ) : (
                "Get Candidate Insights"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Candidate;
