import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashboardLayout from "../../components/DashboardLayout";
import {
  ArrowRight,
  OpenJobsIcon,
  TotalJobsIcon,
  UrgentJobsIcon,
} from "../../components/Icon";
import {
  IntakeIcon,
  InterviewIcon,
  JobDescriptionIcon,
  ReachoutIcon,
  SearchAssistantIcon,
} from "../../components/SidebarIcons";
import SuccessModal from "../auth/SuccessModal";
import JobsTable from "./components/JobsTable";
import Dashboard from "./Dashboard";
import { AuthContext } from "../../Context/UserContext";
import CircleChart from "./components/CircleChart";
import JobStageChart from "./components/JobStageChart";
import Chart1 from "./components/Chart1";
import Chart2 from "./components/Chart2";
import ChartPriorities from "./components/ChartPriorities";

const Home = () => {
  const { search } = useLocation();

  //dashboard

  const { user, userInfo, action } = useContext(AuthContext);

  const email = user.email; // take email from firebase
  const [filterEmail, setFilterEmail] = useState(email); // New state for email filtering

  const [jobData, setJobData] = useState({});

  useEffect(() => {
    const fetchJobCounts = async () => {
      try {
        // Generate a unique cache key based on user's email and action
        const cacheKey = `jobDashboardDataCache_${user?.email}_${action}`;

        // Check if the data is available in the cache
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
          setJobData(JSON.parse(cachedData));
        }

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/get/allJob/dashboard?email=${user?.email}`
        ); // Include the filter email in the request

        const data = await response.json();
        setJobData(data);

        // Save the data to the cache with the updated cache key
        // localStorage.setItem(cacheKey, JSON.stringify(data));
      } catch (error) {
        console.error("Error fetching job counts:", error);
      }
    };

    fetchJobCounts();
  }, [user?.email, action]);

  const chart__data = {
    label: ["Initial", "Intake", "Sourcing", "Interview", "offer"],
    dataSet: jobData && jobData.stagarray ? jobData.stagarray : [],
  };
  //console'chart__data',chart__data)

  ////console'jobData.stagarray',jobData.stagarray)
  const data = [
    {
      icon: <TotalJobsIcon />,
      title: "Total Jobs",
      count: jobData.totalJobsCount,
    },
    {
      icon: <OpenJobsIcon />,
      title: "Open Jobs",
      count: jobData.openJobsCount,
    },
    {
      icon: <UrgentJobsIcon />,
      title: "Urgent Jobs",
      count: jobData.urgentJobsCount,
    },
    // {
    //   component: <Chart2 jobData={jobData} />,
    // },
  ];

  //dashboard
  return (
    <>
      <DashboardLayout title="Home">
        {/* <div className="row gy-2">
					{data1?.map(({ icon, name, url }, i) => (
						<div className="col-sm-6 col-md-4" key={i}>
							<div className="home-item">
								<Link to={`/dashboard/${url}`} className="link" />
								<div className="icon">{icon}</div>
								<h6 className="name">{name}</h6>
							</div>
						</div>
					))}
				</div>
			 */}

        {/* <div className="row g-4 mb-32">
            {data?.map((item, i) => (
              <div className="col-sm-6 col-md-4" key={i}></div> */}

        <section className="my-1">
          <div className="row g-4 mb-32">
            {data?.map(({ icon, title, count }, i) => (
              <div className="col-sm-6 col-md-4" key={i}>
                <div className="dashboard-card">
                  <div className="dashboard-card-header">
                    {icon} <h6>{title}</h6>
                  </div>
                  <div className="text-end count">{count}</div>
                </div>
              </div>
            ))}
          </div>

          {userInfo.totaljob > 0 && (
            <section
              style={{ display: "flex" }}
              className="row g-4 mb-32  flex flex-col   lg:flex-row"
            >
              {/*  */}

              <div
                style={{ flex: 2 }}
                className="col-sm-12 col-md-4 w-full lg:w-auto"
              >
                <div className="__section-title">
                  <h5>Job Priorities</h5>
                </div>
                <ChartPriorities jobData={jobData}> </ChartPriorities>
                {/* <Chart1 ></Chart1> */}
              </div>
              {/*  */}
              <div
                style={{ flex: 3 }}
                className="col-sm-12 col-md-6  w-full lg:w-auto"
              >
                <div className="">
                  <div className="__section-title">
                    <h5>Job Stages</h5>
                  </div>
                  <JobStageChart data={chart__data} />
                </div>
              </div>
              {/*  */}
              <div
                style={{ flex: 2 }}
                className="col-sm-12 col-md-4  w-full lg:w-auto"
              >
                <div className="__section-title">
                  <h5>Job Ratio</h5>
                </div>
                <Chart1 highjob={false} jobData={jobData}></Chart1>
              </div>
              {/*  */}
            </section>
          )}
        </section>

        <section className="recent-project-section">
          <div className="__section-title">
            <h5>Recent Projects</h5>
            <Link to="/jobs" className="see-all">
              See All <ArrowRight />
            </Link>
          </div>
          <JobsTable />
        </section>

        {search === "?reg-completed" && <SuccessModal />}
      </DashboardLayout>
    </>
  );
};

const data1 = [
  {
    icon: <ReachoutIcon />,
    name: "Candidate R3achout",
    url: "r3achout",
  },
  {
    icon: <SearchAssistantIcon />,
    name: "Search Assistant",
    url: "search",
  },
  {
    icon: <JobDescriptionIcon />,
    name: "Job Posting",
    url: "job-management",
  },
  {
    icon: <IntakeIcon />,
    name: "Intake",
    url: "intake",
  },
  {
    icon: <InterviewIcon />,
    name: "Interview Questions",
    url: "interview",
  },
];

export default Home;
