import { Navigate } from "react-router-dom";
import { useUserContext } from "../Context/UserContext";
import { useEffect, useState } from "react";

const PrivateRoute = ({ children }) => {
  const { userInfo } = useUserContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  const allowedEmails = [
    "nader.nasar@gmail.com",
    "nader@r3achout.ai",
    "riadhossinr4@gmail.com",
    "mohammadjahid0007@gmail.com",
    "reachoutpro.ai@gmail.com",
    "riadhossinr7@gmail.com",
    "malikbendevv@gmail.com",
  ];

  if (loading) return <p>Loading...</p>;

  const isVerifiedUser =
    !!userInfo.email && allowedEmails.includes(userInfo.email);

  return isVerifiedUser ? children : <Navigate to="/" />;
};
export default PrivateRoute;
