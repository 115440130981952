import React, { useContext, useEffect, useState } from "react";
import Input from "../pages/auth/components/Input";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext, useUserContext } from "../Context/UserContext";
import OutOfCreditPopup from "../pages/dashboard/components/OutOfCreditPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const CreateNewJobPopup = () => {
  const { projectId, projectUpdateId, userInfo, handleAction, JobNameUpdate } =
    useUserContext();

  const navigate = useNavigate();
  const [name, setName] = useState("");

  //nc
  const [uniqueID, setUniqueid] = useState("");

  function generateUniqueID() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 8; // Adjust the length as needed for the random part
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    // Append the fixed part
    result += userInfo?.email;

    return result;
  }

  const { user } = useContext(AuthContext);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Generate a unique ID
    const uniqueID = generateUniqueID(); // Assuming you have a generateUniqueID function

    //('uniqueID', uniqueID)
    const jobData = {
      email: user.email,
      name,
      selectStatus: "Open",
      selectPriority: "Low",
      selectstage: "initial",
      uniqueID, // Add the unique ID to the jobData object
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_END_POINT}/post/createJob`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jobData),
        }
      );

      if (response.ok) {
        JobNameUpdate(name);
        handleAction(1);
        setName("");
        // You can add further logic here, such as navigating to another page or displaying a success message.
      } else {
        console.error("Failed to create job");
        // Handle the error here
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle network or other errors here
    }

    projectUpdateId(uniqueID);

    navigate("/job-management");
  };

  // const [userInfo, setUserInfo] = useState([]);

  // // take user info
  // useEffect(() => {
  // 	const fetchData = async () => {
  // 		try {
  // 			const response = await fetch(`https://nadereach-server.vercel.app/user/accountType?email=${user?.email}`);
  // 			if (!response.ok) {
  // 				throw new Error(`HTTP error! Status: ${response.status}`);
  // 			}
  // 			const data = await response.json();
  // 			setUserInfo(data);

  // 			// Save userInfo to local storage
  // 			localStorage.setItem('userInfo', JSON.stringify(data));
  // 		} catch (error) {
  // 			console.error('Error fetching data:', error);
  // 		}
  // 	};

  // 	fetchData();
  // }, [user?.email,action]);
  // ////('userInfo :::', userInfo,action)
  //nc
  return (
    <>
      <div className="modal fade" id="create-new-job-popup">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-modal">
            <div className="modal-header pb-0 border-0 justify-content-center">
              <h5 className="modal-title pt-4">Create New Job </h5>

              <button
                type="button"
                className="btn-close top-right-stick"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ backgroundImage: "revert-layer" }}
              ></button>

              {/* <FontAwesomeIcon
          icon={faClose}
          className="cursor-pointer "
          onClick={() => {
       
          }}
        /> */}
            </div>

            <div className="modal-body pt-4 px-sm-5 pb-5">
              <form onSubmit={handleSubmit}>
                <Input
                  placeholder="Write a Job Title Ex. Front End Developer"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <button
                  data-bs-dismiss="modal"
                  type="submit"
                  className="__btn w-100 mt-4"
                >
                  Create
                </button>
              </form>
              {/* {
								userInfo?.amount_paid === 25 && userInfo?.totaljob < 10 ? (

									<form onSubmit={handleSubmit}>
										<Input placeholder="Write a Job Title Ex. Front End Developer"
											onChange={(e) => setName(e.target.value)}
										/>

										<button
											data-bs-dismiss="modal"
											type="submit" className="__btn w-100 mt-4">
											Create
										</button>

									</form>

								) : userInfo?.amount_paid === 50 ? (

									<form onSubmit={handleSubmit}>
										<Input placeholder="Write a Job Title Ex. Front End Developer"
											onChange={(e) => setName(e.target.value)}
										/>

										<button
											data-bs-dismiss="modal"
											type="submit" className="__btn w-100 mt-4">
											Create
										</button>

									</form>

								) : userInfo?.amount_paid === 75 ? (

									<form onSubmit={handleSubmit}>
										<Input placeholder="Write a Job Title Ex. Front End Developer"
											onChange={(e) => setName(e.target.value)}
										/>

										<button
											data-bs-dismiss="modal"
											type="submit" className="__btn w-100 mt-4">
											Create
										</button>

									</form>

								) : (

									<div>
										<div>
											<div>
												<div>
													<div>
														<div>




															<p>
																<span style={{ color: 'red', fontWeight: 'bold' }}>Alert: </span>
																Usage limit exceeded.
															</p>
															<p>
																Upgrade to our monthly plan for unlimited benefits.
															</p>
														</div>

													</div>

												</div>

											</div>

										</div>

									</div>



								)
							} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewJobPopup;
