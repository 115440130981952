import React, { useContext, useRef, useEffect, useState } from "react";
import { AngleDown } from "../../../../components/Icon";
import TextEditor from "../../../../components/TextEditor";
import Input from "../../../auth/components/Input";
import axios from "axios";
import { AuthContext, useUserContext } from "../../../../Context/UserContext";
import { cssTransition } from "react-toastify";
import { RingLoader } from "react-spinners";
import OutOfCreditPopup from "../../components/OutOfCreditPopup";
import JobDescriptionPopup from "../../components/JobDescriptionPopup";
import { OpenAiApi } from "../../../../utils/OpenAiApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useCtrlASelect } from "../../../../hooks/useCtrlSelect";

const Reachout = () => {
  //account
  const [userInfo, setUserInfo] = useState([]);
  const [heading, setHeading] = useState("");
  const [loading, setLoading] = useState(true);

  const {
    errors,
    emailLoading,
    handleEmailClick,
    JobName,
    emailType,
    seEmailType,
    setPersona,
    setTonality,
    setname,
    persona,
    setskills,
    skills,
    Tonality,
    name,
    setName,
    setDecodedValue,
    emailResponse: response,
    setEmailResponse,
    OpenPopup,
    setOpenPopup,
    projectId,
    isEmailSaved,
  } = useUserContext();
  const { user } = useContext(AuthContext);

  useEffect(() => {}, []);

  const textInputRef = useRef(null);

  useCtrlASelect(textInputRef);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/user/accountType?email=${user?.email}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setUserInfo(data);
        // setjobName(data?.JobName);

        // Save userInfo to local storage
        localStorage.setItem("userInfo", JSON.stringify(data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (user?.email && projectId) {
      fetchData();
    }
  }, [user?.email, projectId]);

  useEffect(() => {
    setHeading(`R3achout:${""}  - `);
  }, [JobName]);

  // Initialize first name and last name
  const [lastName, setLName] = useState("");

  useEffect(() => {
    // Assuming userInfo is available in the scope of this component
    // You may want to check if userInfo is not null or undefined before accessing its properties

    // Example: If userInfo is an object with firstName and lastName properties
    setName(userInfo?.firstName);
    setLName(userInfo?.lastName);

    // Add any other logic you want to perform in the useEffect
  }, [userInfo]); // Add dependencies if needed

  useEffect(() => {
    // Check if the user object exists and has a displayName
    if (user && user.displayName) {
      // Split the displayName into first name and last name assuming it is space-separated
      const displayNameParts = user.displayName.split(" ");

      // Assuming the first part is the first name and the second part is the last name
      if (displayNameParts.length >= 1) {
        setName(displayNameParts[0]);
      }

      if (displayNameParts.length >= 2) {
        setLName(displayNameParts[1]);
      }
    }
  }, [user]);

  //account

  const [value, setValue] = useState("");
  const [open, setOpen] = useState(true);
  const [more, setMore] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  let savedJobTitle = localStorage.getItem("jobTitle");
  const checkForChanges = () => {
    const newValue = localStorage.getItem("jobTitle");
    if (newValue !== savedJobTitle) {
      // Value has changed, update savedJobTitle
      savedJobTitle = newValue;
      // Do something with the updated value
      //(savedJobTitle);
    }
  };
  const pollingInterval = 500; // 500 milliseconds
  setInterval(checkForChanges, pollingInterval);
  // checkForChanges();

  // get job title from localstorage

  //   get job details from localstorage
  let savedJobDetails = localStorage.getItem("jd");
  const checkForChangesDetails = () => {
    const newValue = localStorage.getItem("jd");
    if (newValue !== savedJobDetails) {
      // Value has changed, update savedJobTitle
      savedJobDetails = newValue;
      // Do something with the updated value
      //('savedJobDetails', savedJobDetails);
    }
  };
  const pollingIntervalDetails = 500; // 500 milliseconds
  setInterval(checkForChangesDetails, pollingIntervalDetails);
  checkForChangesDetails();

  const copyContentToEditor = () => {
    // Select the div containing the content you want to copy
    const contentDiv = document.querySelector(".__output-text");
    if (contentDiv) {
      // Get the HTML content of the div
      const htmlContent = contentDiv.innerHTML;

      setValue(htmlContent); // Where `setValue` is the function to update the state
    }
  };

  useEffect(() => {
    setDecodedValue(decodeURIComponent(value));
  }, [value]);

  //nc

  const [firstEffectFinished, setFirstEffectFinished] = useState(false);
  const get = "email";
  const post = "email";
  // const heading = `R3achout:${JobName ? JobName : ""} `;
  const deleteEditor = "emailreachoutcollection";

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/get/email/inputBox/${projectId}`
        );
        const result = await response.json();

        setEmailResponse(result[0]?.response || "");
        setskills(result[0]?.skills ? result[0].skills : "");
        seEmailType(result[0]?.emailType ? result[0]?.emailType : "Cold email");
        setTonality(
          result[0]?.Tonality ? result[0].Tonality : "Professional and polite"
        );
        setname((result[0]?.name && result[0].name) || "");
        setPersona(result[0]?.persona ? result[0].persona : "Lou Adler");
        setFirstEffectFinished(true);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (projectId) {
      fetchData();
    }

    // Set the state to true once done
  }, [projectId, isEmailSaved]);

  useEffect(() => {
    // Your API endpoint
    if (firstEffectFinished) {
      // Your API endpoint
      const apiUrl = `${process.env.REACT_APP_SERVER_END_POINT}/update/email/inputBox/${projectId}`;

      // Your job data, including botResponses
      const jobData = {
        // response: response,
        skills,
        Tonality,
        persona,
        name,
        emailType,
      };

      // Fetch options
      const requestOptions = {
        method: "PUT", // Change this to PUT
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jobData),
      };

      // Make the PUT request using fetch
      fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // Handle the response data as needed
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
        });
    }
  }, [
    response,
    emailType,
    Tonality,
    name,
    skills,
    persona,
    firstEffectFinished,
  ]);

  return (
    <>
      <section className="jobs-management bg-body-2">
        <form onSubmit={handleSubmit} className="dashboard-form-2">
          <div className="row g-4 flex-lg-nowrap">
            <div className="col-lg-4">
              {/*  */}
              <div className=" mb-4">
                <Input
                  label="Type of email"
                  onChange={(e) => {
                    seEmailType(e.target.value);
                    // setDirty(true);
                  }}
                  options={emailOption}
                  value={emailType}
                />
              </div>

              {/* <div className="mb-4">
								<label className="form--label">Type of email</label>

								<div className=" dropdown" >
									<button
										className="form-control form--control rounded text-start d-flex align-items-center justify-content-between"
										type="button"
										data-bs-toggle="dropdown"
									>
										<span>{emailType}</span>
										<span className="angle">
											<AngleDown />
										</span>
									</button>
									<div className="dropdown-menu __dropdown-menu-4 w-100" >
										<ul className="account-menu" >
											<li>Initial Outreach</li>
											<li>
												<label onClick={() => seEmailType("LinkedIn InMail")}>
													LinkedIn InMail
												</label>
											</li>
											<li>
												<label
													onClick={() => seEmailType("Cold email")}
												>
													Cold email
												</label>
											</li>
											<li>
												<label
													onClick={() => seEmailType("Conversation Starter")}
												>
													Conversation Starter
												</label>
											</li>
											<li>
												<label
													onClick={() => seEmailType("Network Expansion")}
												>
													Network Expansion

												</label>
											</li>








										</ul>
									</div>
								</div>
							</div> */}
              {more && (
                <>
                  {/* <div className="form-group mb-4">
										<Input
											label="Job Title"
											placeholder="Enter Job Title here"
											onChange={(e) => setTile(e.target.value)}
										/>
									</div> */}
                  <div className="form-group mb-4">
                    <Input
                      label="Candidate Info"
                      placeholder="Candidate Name"
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                        // setDirty(true);
                      }}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <Input
                      label="Candidate’s skills "
                      placeholder="Candidate’s skills & experience"
                      value={skills}
                      onChange={(e) => {
                        setskills(e.target.value);
                        // setDirty(true);
                      }}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <Input
                      label="Tone"
                      value={Tonality}
                      onChange={(e) => {
                        setTonality(e.target.value);
                        // setDirty(true);
                      }}
                      options={personaOption}
                    />
                  </div>

                  <div className="form-group mb-4">
                    <Input
                      label="Brand / Voice"
                      value={persona}
                      onChange={(e) => {
                        setPersona(e.target.value);
                        // setDirty(true);
                      }}
                      options={options2}
                    />
                  </div>
                </>
              )}
              <button
                className="__btn-outline w-100 m-0  !font-[500] !leading-5 !text-base !capitalize "
                onClick={() => setMore(!more)}
              >
                {!more ? "More Options..." : "Less Options..."}
              </button>

              <div className="d-flex  flex-col items-center   gap-20px save-info">
                {errors.emailErrors && (
                  <p className="text-red-500 text-sm  font-semibold">
                    {" "}
                    {errors.emailErrors}
                  </p>
                )}
                <button
                  onClick={() => handleEmailClick(projectId)}
                  className="__btn w-100 mb-32 h-50px mt-4 !font-[500] !leading-5 !text-base !capitalize  "
                  disabled={emailLoading}
                >
                  {emailLoading ? (
                    <div className="!flex justify-around gap-2 items-center  w-full">
                      <p className="mb-0"> Loading…</p>
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    </div>
                  ) : (
                    "Craft Email"
                  )}
                </button>
              </div>

              {/* <button
                style={{ marginTop: "8px" }}
                onClick={handleButtonClick}
                type="button"
                className="__btn w-100 mb-32 h-50px"
              >
                Craft email
              </button> */}
              {/* {loading ? (
                <>

                  <div class="popup-container">
                    <div class="center-body">
                      <div class="loader-circle-6"></div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )} */}
            </div>
            <div className={`${open ? "col-lg-4" : "col-lg-8"}`}>
              <div>
                <label className="form--label mb-4 !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Suggested email content
                </label>
                <div
                  className="form-control form--control __output-text"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                  readOnly={true}
                  ref={textInputRef}
                  contentEditable
                  spellCheck="false"
                  dangerouslySetInnerHTML={{
                    __html: loading
                      ? ""
                      : response
                          ?.replace(/\n/g, "<br />")
                          ?.replace(
                            /\*\*(.*?)\*\*/g,
                            (match, p1) => `<b>${p1}</b>`
                          ),
                  }}
                />

                {/* <Input
									className="form-control form--control __output-text"
									textarea
									value={response}
								/> */}
                {/* <div
									className="form-control form--control __output-text"
									style={{ maxHeight: '200px', overflowY: 'auto' }}
									contentEditable
									dangerouslySetInnerHTML={{ __html: response }}
								/> */}
              </div>
            </div>
            <div className={`${open ? "col-lg-4" : "col-lg-1"}`}>
              {/* <button onClick={copyContentToEditor}>Copy Content</button> */}

              <div>
                <TextEditor
                  get={get}
                  post={post}
                  deleteEditor={deleteEditor}
                  title={heading}
                  value={value}
                  setValue={setValue}
                  open={open}
                  setOpen={setOpen}
                />
              </div>
            </div>
          </div>
        </form>
        <OutOfCreditPopup
          open={OpenPopup}
          setOpen={setOpenPopup}
          heading={`You've Hit the Limit!`}
          text={
            userInfo.productName !== "Gold"
              ? "You've reached your maximum for this feature, but your potential is unlimited. Upgrade now to continue without interruption."
              : "You've used all your word credits for this month. Stay tuned, your credits will refresh soon."
          }
          userInfo={userInfo}
        />
        {/* <OutOfCreditPopup open={OpenPopup} setOpen={setOpenPopup} />
        <JobDescriptionPopup
          open={jdEmptyPopup}
          setOpen={setjdEmptyPopup}
          handleUserInput={handleButtonClick}
        ></JobDescriptionPopup> */}
      </section>
    </>
  );
};
const personaOption = [
  {
    name: "Professional",
    value: "Professional and polite",
  },
  {
    name: "Energitic",
    value: " Enthusiastic and Energitic",
  },
  {
    name: "Compelling",
    value: "Compelling and Enticing",
  },
  {
    name: "Witty",
    value: "Witty and Smart",
  },
  {
    name: "Friendly",
    value: "Warm and Friendly",
  },
  {
    name: "Optimistic",
    value: "Positive and  Optimistic",
  },
  {
    name: "Concise",
    value: "Short and Concise",
  },
];

const options2 = [
  {
    name: "Lou Adler",
    value: "Lou Adler",
  },
  {
    name: "Stacy Zapar",
    value: "Stacy Zapar",
  },
  {
    name: "Johnny Campbell",
    value: "Johnny Campbell",
  },
  {
    name: "Greg Savage",
    value: "Greg Savage",
  },
  {
    name: "Maisha Cannon",
    value: "Maisha Cannon",
  },
  {
    name: "Glen Cathey",
    value: "Glen Cathey",
  },
  {
    name: "Jim Stroud",
    value: "Jim Stroud",
  },
  {
    name: "Amy Miller",
    value: "Amy Miller",
  },
  {
    name: "John Sullivan",
    value: "John Sullivan",
  },
  {
    name: "Steve Jobs",
    value: "Steve Jobs",
  },
  {
    name: "Sheryl Sandberg",
    value: "Sheryl Sandberg",
  },
  {
    name: "Jeff Bezos",
    value: "Jeff Bezos",
  },
  {
    name: "Elon Musk",
    value: "Elon Musk",
  },
  {
    name: "Satya Nadella",
    value: "Satya Nadella",
  },
  {
    name: "Tim Cook",
    value: "Tim Cook",
  },
];

const emailOption = [
  {
    name: "Cold email",
    value: "Cold email",
  },
  {
    name: "LinkedIn InMail",
    value: "LinkedIn InMail",
  },
  {
    name: "Conversation Starter",
    value: "Conversation Starter",
  },
  {
    name: "Network Expansion",
    value: "Network Expansion",
  },
];

export default Reachout;
