import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EyeIcon, PenIcon, TrashIcon } from "../../../components/Icon";
import axios from "axios";
import { AuthContext, useUserContext } from "../../../Context/UserContext";

const JobsTable = () => {
  const { projectId, projectUpdateId, handleAction, JobNameUpdate } =
    useUserContext();
  const navigate = useNavigate();
  const handleLinkClick = (e, uniqueID, name) => {
    e.preventDefault(); // Prevent the default link behavior, if needed

    projectUpdateId(uniqueID); // Update projectId using projectUpdateId
    JobNameUpdate(name); // Update projectId using projectUpdateId
    navigate("/job-management");
  };
  //nc

  const [jobs, setJobs] = useState([]);
  //console'jobs',jobs)

  const { user } = useContext(AuthContext);

  // Define the fetchData function
  async function fetchData() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_END_POINT}/get/allJob?email=${user?.email}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setJobs(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // Call fetchData in useEffect
  useEffect(() => {
    fetchData();
  }, [user?.email, projectId]); //jobs

  //nc

  const handleDelete = async (jobs) => {
    // Display a confirmation dialog to the user
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this job?"
    );

    // Check if the user confirmed the deletion
    if (confirmDelete) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_SERVER_END_POINT}/delete/job/${jobs}`
        );
        handleAction(1);
        fetchData();
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    } else {
      // User canceled the deletion
    }
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table __table">
          <thead>
            <tr>
              <th>
                <div className="no-break !font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D] ">
                  Job Title
                </div>
              </th>
              <th>
                <div className="!font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D]">
                  Status
                </div>
              </th>
              <th>
                <div className="!font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D] ">
                  Hiring Manager
                </div>
              </th>
              <th>
                <div className=" !font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D]">
                  Stage
                </div>
              </th>
              <th>
                <div className=" !font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D]">
                  Priority
                </div>
              </th>
              <th className=" !font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {jobs?.map((product, i) => (
              <tr key={i}>
                <td>{product.name}</td>
                <td>
                  <div
                    className={`__status  !font-normal !leading-5 !text-base !capitalize !text-[#556571]   ${
                      product.selectStatus == "Open" ? "success" : ""
                    }`}
                  >
                    {product.selectStatus}
                  </div>
                </td>
                <td className="!font-normal !leading-5 !text-base !capitalize !text-[#556571]">
                  {product.manager}
                </td>
                <td className="!font-normal !leading-5 !text-base !capitalize !text-[#556571]">
                  {product.selectstage}
                </td>
                <td className="!font-normal !leading-5 !text-base !capitalize !text-[#556571]">
                  {product.selectPriority}
                </td>
                <td>
                  <div className="d-flex gap-3">
                    {/* <Link to={`/dashboard/jobs/${title}`}>
												<EyeIcon />
											</Link> */}
                    <Link
                      to={"/job-management"}
                      onClick={(e) =>
                        handleLinkClick(e, product.uniqueID, product.name)
                      }
                    >
                      <EyeIcon />
                    </Link>
                    {/* <Link to="/dashboard/job-management">
												<PenIcon />
											</Link> */}
                    <p onClick={() => handleDelete(product._id)}>
                      <TrashIcon />
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
const data = [
  {
    title: "Frontend Developer x",
    status: "Open",
    manager: "John Doe",
    stage: "Initial",
    priority: "High",
  },
  {
    title: "Frontend Developer",
    status: "Closed",
    manager: "John Doe",
    stage: "Job Description",
    priority: "High",
  },
  {
    title: "Frontend Developer",
    status: "Open",
    manager: "John Doe",
    stage: "Intake",
    priority: "High",
  },
  {
    title: "UI/UX Designer",
    status: "Closed",
    manager: "Hiring Manager",
    stage: "Sourcing",
    priority: "High",
  },
  {
    title: "UI/UX Designer",
    status: "Open",
    manager: "Hiring Manager",
    stage: "Interview",
    priority: "Medium",
  },
  {
    title: "Frontend Developer",
    status: "Open",
    manager: "John Doe",
    stage: "Initial",
    priority: "High",
  },
  {
    title: "Frontend Developer",
    status: "Closed",
    manager: "John Doe",
    stage: "Job Description",
    priority: "High",
  },
  {
    title: "Frontend Developer",
    status: "Open",
    manager: "John Doe",
    stage: "Intake",
    priority: "High",
  },
  {
    title: "UI/UX Designer",
    status: "Closed",
    manager: "Hiring Manager",
    stage: "Sourcing",
    priority: "High",
  },
  {
    title: "UI/UX Designer",
    status: "Open",
    manager: "Hiring Manager",
    stage: "Interview",
    priority: "Medium",
  },
];

export default JobsTable;
