import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useUserContext } from "../../../../Context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const LocationPopUp = ({
  open,
  setOpen,
  handleUserInput,
  setIsUsingLocation,
  isUsingLocation,
}) => {
  const { setLocation, location } = useUserContext();

  return (
    <Modal show={open} className="description-popup popup-modal" centered>
      <div className="w-full flex justify-end  p-2">
        <FontAwesomeIcon
          icon={faClose}
          className="cursor-pointer "
          onClick={() => {
            setOpen(false);
            setIsUsingLocation((isUsingLocation) => !isUsingLocation);
          }}
        />
      </div>

      <div className="modal-header px-sm-5 pt-4 text-center border-0 pb-0">
        <h5 className="modal-title modal-title-2">
          Please add a location here or from job management tab{" "}
        </h5>
      </div>
      <div className="modal-body pt-4 px-sm-5 pb-5">
        <div className="d-flex gap-20px">
          <input
            className="!border-black !border rounded-md w-64 !py-3 !px-4"
            placeholder="location"
            onChange={(e) => {
              setLocation(e.target.value);
            }}
            value={location}
          />

          <button
            type="submit"
            className="__btn  w-0 flex-grow-1"
            onClick={() => {
              setOpen(false);
              //   handleSkip();
              handleUserInput();
              setLocation("");
            }}
          >
            Add
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LocationPopUp;
