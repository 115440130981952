import { ArcElement, Chart as ChartJS } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement);

const ChartPriorities = ({ jobData, highjob }) => {
  ////console'jobData.closeJobsCount', jobData.openJobsCount)
  const data = {
    datasets: [
      {
        data: [
          jobData.urgentJobsCount,
          jobData.highPriorities,
          jobData.mediumPriorities,
          jobData.lowPriorities,

          //   jobData.openJobsCount,
          //   jobData.urgentJobsCount,
        ],
        backgroundColor: ["#EB5757", "#FFA412", "#8367c7", "#00FF00"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div
      className="chart-1"
      style={{ width: "100%", minHeight: "250px", maxHeight: "50px" }}
    >
      <div className="pie-chart">
        <Doughnut data={data} />
        <div className="center-label">
          <div className=" !font-[500] !capitalize ">Open</div>
          <div className="count">{jobData.openJobsCount}</div>
        </div>
      </div>
      <ul className="chart-label">
        <li>
          <span className="icon" style={{ background: "#EB5757" }}></span>{" "}
          <span className="info !font-[500]  !text-base !capitalize ">
            {jobData.urgentJobsCount} Urgent
          </span>
        </li>
        <li>
          <span className="icon" style={{ background: "#FFA412" }}></span>{" "}
          <span className="info !font-[500] !leading-5 !text-base !capitalize">
            <span style={{ color: "#0C579B" }}>{jobData.highPriorities}</span>{" "}
            High
          </span>
        </li>
        <li>
          <span className="icon" style={{ background: "#8367c7" }}></span>{" "}
          <span className="info !font-[500] !leading-5 !text-base !capitalize">
            <span style={{ color: "#0C579B" }}>{jobData.mediumPriorities}</span>{" "}
            Medium
          </span>
        </li>

        <li>
          <span className="icon" style={{ background: "#00FF00" }}></span>{" "}
          <span className="info !font-[500] !leading-5 !text-base !capitalize">
            <span style={{ color: "#0C579B" }}>{jobData.lowPriorities}</span>{" "}
            Low
          </span>
        </li>
      </ul>
    </div>
  );
};

export default ChartPriorities;
