import React from "react";
import DashboardLayout from "../../../components/DashboardLayout";
import Interview from "./components/Interview";
import JobSingleLayout from "./components/JobSingleLayout";
import { useUserContext } from "../../../Context/UserContext";

const InterviewPage = () => {
	const { FullJobData, handleJobData } = useUserContext();
 
	// Define breadcrumbs here, after FullJobData is available
	const breadcrumbs = [
		{
			name: "My Jobs",
		},
		{
			name: FullJobData[0]?.name,
		},
		{
			name: "Interview",
		},
	];
	return (
		<>
			<DashboardLayout breadcrumbs={breadcrumbs}>
				<JobSingleLayout>  
					<Interview />
				</JobSingleLayout>
			</DashboardLayout>
		</>
	);
};



export default InterviewPage;
