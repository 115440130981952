import React from "react";
import { Link } from "react-router-dom";
import banner_bg from "../../../assets/img/banner/banner-bg.png";
import img from "../../../assets/img/banner/banner.png";
const Banner = () => {
  return (
    <>
      <section
        className="banner-section"
        style={{
          background: `url(${banner_bg}) no-repeat center center / cover`,
        }}
        id="home"
      >
        <div className="container">
          <div className="banner-content">
            <h1 className="title">
              Welcome to <span>R3achout.AI</span> <br /> The Ultimate
              Recruiter's AI Tool
            </h1>
            <p className="text">
              {/* We are like Google, for jobs. Take a hybrid approach to
              recruitment and let our trained AI copilot your recruitment
              efforts. We turn emails message into personalized and meaningful
              connections, assist with sourcing, uncovering talent, and
              qualifying candidates. Empower your recruitment process, achieve
              remarkable results, and stay ahead of the competition. */}
              <br /> Recruit with Precision, Build a Reputation for Excellence.
            </p>
            {/* <p className="text">
              In today’s fast-paced recruiting world, standing out matters more
              than ever. At R3achout.AI, we understand the power of connection,
              where your emails are not just messages, they're the first step in
              building meaningful relationships. We empower recruiters like you
              to enhance your recruitment processes, get results, ensuring you
              stay one step ahead of the competition.
            </p> */}
            <Link to="/login" className="banner-btn  mt-4">
              <span className="border"></span>
              Get Started
            </Link>
          </div>
        </div>
      </section>
      <div className="container">
        <img src={img} alt="" className="banner-img" />
      </div>
    </>
  );
};

export default Banner;
