import React, { useEffect, useRef, useState } from "react";
import { useUserContext } from "../../../../Context/UserContext";
import OpenAI from "openai";
import { cssTransition } from "react-toastify";
import OutOfCreditPopup from "../../components/OutOfCreditPopup";
import JobDescriptionPopup from "../../components/JobDescriptionPopup";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useCtrlASelect } from "../../../../hooks/useCtrlSelect";
import "./styles.css";
import LocationPopUp from "./LocationPopUp";
import Candidate from "./SearchTabs/Candidate";
import Sourcing from "./SearchTabs/Sourcing";
import OnlineResources from "./SearchTabs/OnlineResources";
import TechInsight from "./SearchTabs/TechInsight";
const JobSingleSearch = () => {
  const {
    FullJobData,
    location,
    setLocation,
    searchLoading,
    errors,
    candidateResponse,
    setCandidateResponse,
    sourcingResponse,
    setSourcingResponse,
    techResponse,
    setTechResponse,
    OpenPopup,
    setOpenPopup,
    singleObject,
    handleCandidateInsight,
    characterCount,
    response,
    setResponse,
    openWordsLimitPopup,
    setOpenWordsLimitPopup,
    handleSourcing,
    handleTechInsight,
    onlineResourcesResponse,
    setonlineResourcesResponse,
    handleOnlineResources,
    userInfo,
  } = useUserContext();

  const [isUsingLocation, setIsUsingLocation] = useState("");
  const [isLocationPopup, setIsLocationPopup] = useState("");
  const [loading, setLoading] = useState("");
  const [tab, setTab] = useState(
    localStorage.getItem("selectedSearchTab") || "Candidate Insight"
  );

  // get job title from localstorage
  let savedJobTitle = localStorage.getItem("jobTitle");
  const checkForChanges = () => {
    const newValue = localStorage.getItem("jobTitle");
    if (newValue !== savedJobTitle) {
      // Value has changed, update savedJobTitle
      savedJobTitle = newValue;
      // Do something with the updated value
      //(savedJobTitle);
    }
  };
  const pollingInterval = 500; // 500 milliseconds
  setInterval(checkForChanges, pollingInterval);
  checkForChanges();

  const createIdealCandidatePersonaHTML = () => {
    return {
      __html:
        candidatePersona
          ?.split("\n")
          .map((line, index) => `${line}<br />`)
          .join("") || "",
    };
  };

  const createJobTitlesHTML = () => {
    return {
      __html: loading
        ? ""
        : jobTitles
            ?.split("\n")
            .map((line, index) => `${line}<br />`)
            .join("") || "",
    };
  };
  const createBooleanSearchHTML = () => {
    return {
      __html: loading
        ? ""
        : booleanSearch
            ?.split("\n")
            .map((line, index) => `${line}<br />`)
            .join("") || "",
    };
  };

  const createNarrowKeywordsHTML = () => {
    return {
      __html: loading
        ? ""
        : keywords
            ?.split("\n")
            .map((line, index) => `${line}<br />`)
            .join("") || "",
    };
  };

  const createExpandKeywordsHTML = () => {
    return {
      __html: loading
        ? ""
        : Expandkeywords?.split("\n")
            .map((line, index) => `${line}<br />`)
            .join("") || "",
    };
  };

  const createJobBoardsHTML = () => {
    return {
      __html: loading
        ? ""
        : jobBoards
            ?.split("\n")
            .map((line, index) => `${line}<br />`)
            .join("") || "",
    };
  };

  const createCommunitiesHTML = () => {
    return {
      __html: loading
        ? ""
        : communities
            ?.split("\n")
            .map((line, index) => `${line}<br />`)
            .join("") || "",
    };
  };

  const createTechStackHTML = () => {
    return {
      __html: loading
        ? ""
        : techStack
            ?.split("\n")
            .map((line, index) => `${line}<br />`)
            .join("") || "",
    };
  };

  const createTechKeywordHTML = () => {
    return {
      __html: loading
        ? ""
        : techKeywords
            ?.split("\n")
            .map((line, index) => `${line}<br />`)
            .join("") || "",
    };
  };

  const handleTabClick = (tabb) => {
    setTab(tabb);
    localStorage.setItem("selectedSearchTab", tabb);
  };
  //   get job details from localstorage
  let savedJobDetails = localStorage.getItem("jd");
  const checkForChangesDetails = () => {
    const newValue = localStorage.getItem("jd");
    if (newValue !== savedJobDetails) {
      // Value has changed, update savedJobTitle
      savedJobDetails = newValue;
      // Do something with the updated value
      //('savedJobDetails', savedJobDetails);
    }
  };
  const pollingIntervalDetails = 500; // 500 milliseconds
  setInterval(checkForChangesDetails, pollingIntervalDetails);
  checkForChangesDetails();

  //   get job details from localstorage

  const [jdEmptyPopup, setjdEmptyPopup] = useState(false);
  const [searchType, setsearchType] = useState("");

  const handleSearchResponse = (val, location) => {
    if (val === "candidate") {
      handleCandidateInsight(projectId);
    } else if (val === "sourcing") {
      handleSourcing(projectId);
    } else if (val === "tech") {
      handleTechInsight(projectId);
    } else if (val === "onlineResources") {
      handleOnlineResources(location, projectId);
    }
  };

  const allValues = [];

  for (const item of response) {
    const values = Object.values(item);
    allValues.push(...values);
  }

  // useEffect(() => {
  //   handleWords(characterCount);
  // }, [characterCount]);

  //consoleresponse)

  // database input code
  const { projectId } = useUserContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/get/search/inputBox/${projectId}`
        );

        let result = ""; // default to empty if there are issues
        if (response.ok) {
          // checks if status code is 2xx
          const text = await response.text(); // temporarily read response as text
          if (text) {
            try {
              result = JSON.parse(text); // try parsing text as JSON
            } catch (e) {
              console.error("Error parsing JSON:", e);
            }
          } else {
            console.log("No content returned from the server");
          }
        } else {
          console.error("Bad response", response.status, await response.text());
        }
        setCandidateResponse(result?.candidateResponse || "");
        setSourcingResponse(result?.sourcingResponse || "");
        setonlineResourcesResponse(result?.onlineResourcesResponse || "");
        setTechResponse(result?.techResponse || "");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (projectId) {
      fetchData();
    }
  }, [projectId]);

  const candidatePersona = candidateResponse?.[0]?.response;

  const jobTitles = candidateResponse?.[1]?.response;

  const booleanSearch = sourcingResponse?.[0]?.response;
  const keywords = sourcingResponse?.[1]?.response;
  const Expandkeywords = sourcingResponse?.[2]?.response;

  const jobBoards = onlineResourcesResponse?.[0]?.response.replace(
    /\*\*(.*?)\*\*/g,
    (match, p1) => `<b>${p1}</b>`
  );
  const communities = onlineResourcesResponse?.[1]?.response.replace(
    /\*\*(.*?)\*\*/g,
    (match, p1) => `<b>${p1}</b>`
  );

  const techStack = techResponse?.[0]?.response;
  const techKeywords = techResponse?.[1]?.response;

  // database input code

  const [isOpen, setIsOpen] = useState([true, true, true]);

  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);
  const textareaRef3 = useRef(null);
  const textareaRef4 = useRef(null);
  const textareaRef5 = useRef(null);
  const textareaRef6 = useRef(null);
  const textareaRef7 = useRef(null);
  const textareaRef8 = useRef(null);
  const textareaRef9 = useRef(null);

  useCtrlASelect(textareaRef1);
  useCtrlASelect(textareaRef2);
  useCtrlASelect(textareaRef3);
  useCtrlASelect(textareaRef4);
  useCtrlASelect(textareaRef5);
  useCtrlASelect(textareaRef6);
  useCtrlASelect(textareaRef7);
  useCtrlASelect(textareaRef8);
  useCtrlASelect(textareaRef9);

  const toggleBox = (index) => {
    const updatedIsOpen = [...isOpen];

    updatedIsOpen[index] = !updatedIsOpen[index];
    setIsOpen(updatedIsOpen);
  };

  const handleOpenJd = (val) => {
    setsearchType(val);
    if (isUsingLocation && val !== "onlineResources") {
      if (singleObject == undefined) {
        alert("Please select job");
        return;
      }

      if (singleObject?.jd == undefined) {
        setjdEmptyPopup(true);
      } else {
        handleSearchResponse(val);
      }
    } else if (!isUsingLocation) {
      if (singleObject == undefined) {
        alert("Please select job");
        return;
      }

      if (singleObject?.jd == undefined) {
        setjdEmptyPopup(true);
      } else {
        handleSearchResponse(val);
      }
    } else if (isUsingLocation && location && val === "onlineResources") {
      if (singleObject == undefined) {
        alert("Please select a job");
        return;
      }

      if (singleObject?.jd == undefined) {
        setjdEmptyPopup(true);
      } else {
        handleSearchResponse(val, location);
      }
    } else if (isUsingLocation && !location && val === "onlineResources") {
      setIsLocationPopup(true);
    }
  };

  const tabs = [
    "Candidate Insight",
    "Sourcing",
    "Online Resources",
    "Technology Insight",
  ];
  //
  return (
    <section className="jobs-management bg-body-2">
      {/* <p
        style={{
          fontSize: "16px",
        }}
      >
        *Insights are based on your job description found in{" "}
        <Link
          to="/job-management"
          style={{
            color: "black",
          }}
        >
          <b>Job Management Tab.</b>
        </Link>
      </p> */}

      <p className="font-[500] text-[#2D3E4D]  ">Search Assistant</p>
      <div className="flex gap-3 mt-4 flex-wrap  ">
        {tabs.map((tabb, index) => {
          return (
            <p
              className={`!border-[#D8E1E8] border rounded-2xl !p-2 !px-4  mb-10 cursor-pointer font-[500] 
          ${tab === tabb && "bg-[#E9F8F1] text-[#23B574]"}    
              
              `}
              key={index}
              onClick={() => handleTabClick(tabb)}
            >
              {tabb}
            </p>
          );
        })}
      </div>
      {tab === "Candidate Insight" ? (
        <Candidate
          textRef1={textareaRef1}
          textRef2={textareaRef2}
          createIdealCandidatePersonaHTML={createIdealCandidatePersonaHTML()}
          createJobTitlesHTML={createJobTitlesHTML()}
          handleOpenJd={handleOpenJd}
        />
      ) : tab === "Sourcing" ? (
        <Sourcing
          textRef3={textareaRef3}
          textRef4={textareaRef4}
          textRef9={textareaRef9}
          createNarrowKeywordsHTML={createNarrowKeywordsHTML()}
          createBooleanSearchHTML={createBooleanSearchHTML()}
          createExpandKeywordsHTML={createExpandKeywordsHTML()}
          handleOpenJd={handleOpenJd}
        />
      ) : tab === "Online Resources" ? (
        <OnlineResources
          textRef5={textareaRef5}
          textRef8={textareaRef8}
          createJobBoardsHTML={createJobBoardsHTML()}
          createCommunitiesHTML={createCommunitiesHTML()}
          isUsingLocation={isUsingLocation}
          setIsUsingLocation={setIsUsingLocation}
          handleOpenJd={handleOpenJd}
        />
      ) : tab === "Technology Insight" ? (
        <TechInsight
          textRef6={textareaRef6}
          textRef7={textareaRef7}
          createTechStackHTML={createTechStackHTML()}
          createTechKeywordHTML={createTechKeywordHTML()}
          handleOpenJd={handleOpenJd}
        />
      ) : (
        ""
      )}

      <OutOfCreditPopup
        open={OpenPopup}
        setOpen={setOpenPopup}
        heading={
          userInfo?.productName === "Gold"
            ? "Query Limit Notification"
            : `You've Hit the Limit!`
        }
        text={
          userInfo?.productName === "Gold"
            ? "Thank you for using our services! As a valued Gold member, please note that you've reached the maximum number of queries for this job. For further assistance, feel free to contact us via the Feedback button in the upper right corner. Alternatively, you can create a new job with the same description and continue using the search assistant."
            : "You've reached your maximum for this feature, but your potential is unlimited. Upgrade to Gold to execute the Search feature without interruption."
        }
        userInfo={userInfo}
      />

      <OutOfCreditPopup
        open={openWordsLimitPopup}
        setOpen={setOpenWordsLimitPopup}
        heading={`You've Hit the Limit!`}
        text={
          userInfo.productName !== "Gold"
            ? "You've reached your maximum for this feature, but your potential is unlimited. Upgrade now to continue without interruption."
            : "You've used all your word credits for this month. Stay tuned, your credits will refresh soon."
        }
        userInfo={userInfo}
      />
      <JobDescriptionPopup
        open={jdEmptyPopup}
        setOpen={setjdEmptyPopup}
        handleUserInput={() => handleSearchResponse(searchType, location)}
      ></JobDescriptionPopup>

      <LocationPopUp
        open={isLocationPopup}
        setOpen={setIsLocationPopup}
        setIsUsingLocation={setIsUsingLocation}
        isUsingLocation={isUsingLocation}
        handleUserInput={() => handleOpenJd(searchType)}
      ></LocationPopUp>
    </section>
  );
};

export default JobSingleSearch;
