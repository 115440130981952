import React, { useContext, useState } from "react";
import DashboardLayout from "../../../components/DashboardLayout";
import Input from "../../auth/components/Input";
import ImageUpload from "./ImageUpload";
import { AuthContext, useUserContext } from "../../../Context/UserContext";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
const AccountSetting = () => {
  const { handleuserInfo, userInfo, user, handleAction } = useUserContext();

  // Initialize first name and last name
  const [name, setName] = useState(userInfo?.firstName);
  const [lname, setLName] = useState(userInfo?.lastName);

  useEffect(() => {
    // Assuming userInfo is available in the scope of this component
    // You may want to check if userInfo is not null or undefined before accessing its properties

    // Example: If userInfo is an object with firstName and lastName properties
    setName(userInfo?.firstName);
    setLName(userInfo?.lastName);

    // Add any other logic you want to perform in the useEffect
  }, [userInfo]); // Add dependencies if needed

  // useEffect(() => {
  //   // Check if the user object exists and has a displayName
  //   if (user && user.displayName) {
  //     // Split the displayName into first name and last name assuming it is space-separated
  //     const displayNameParts = user.displayName.split(" ");

  //     // Assuming the first part is the first name and the second part is the last name
  //     if (displayNameParts.length >= 1) {
  //       setName(displayNameParts[0]);
  //     }

  //     if (displayNameParts.length >= 2) {
  //       setLName(displayNameParts[1]);
  //     }
  //   }
  // }, [user]);

  const [email, setEmail] = useState(user?.email);
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");

  const [location, setLocation] = useState("");
  const [words, setlimit] = useState("");

  useEffect(() => {
    setCompany(userInfo.company);
    setLocation(userInfo.location);
    setTitle(userInfo.title);
    // setlimit(userInfo?.words)
  }, [userInfo]);

  const [subscription, setSubscription] = useState("Free");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  //
  const handleUpdate = async () => {
    const firstName = name;
    const lastName = lname;
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_END_POINT}/update/account/${user?.email}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          company,
          location,
          title,
          words,
          firstName,
          lastName,
        }),
      }
    );

    if (response.ok) {
      handleAction(1);
      //console'User updated successfully.');
    } else {
      console.error("Failed to update user.");
    }
  };

  //

  useEffect(() => {
    handleuserInfo(userInfo);
  }, [userInfo]);

  const [isChecked, setIsChecked] = useState(true);
  const [isCheckedTest, setIsCheckedTest] = useState(false);

  // Handler function for checkbox changes
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checked state
  };

  // const handleTest = async () => {
  //   setIsCheckedTest(!isChecked); // Toggle the checked state

  //   await axios.post("https://nadereach-server.vercel.app/test-cron", {
  //     email: user?.email,
  //   });
  // };

  const [customerId, setCustomerId] = useState("null");

  useEffect(() => {
    setCustomerId(userInfo.customer_id);
  }, [userInfo]);

  const handleManageSubscription = async () => {
    try {
      // Assuming you have a customerId stored in Firestore or similar
      ////({ customerId })

      const response = await axios.post(
        // "https://nadereach-server.vercel.app/create-portal",
        `${process.env.REACT_APP_SERVER_END_POINT}/create-portal`,

        // 'https://nadereach-server.vercel.app/create-portal'

        { customerId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.url) {
        window.location.href = response.data.url; // Redirect to the customer portal
      }
    } catch (error) {
      console.error("Error accessing customer portal:", error);
    }
  };

  const allowedEmails = [
    "nader.nasar@gmail.com",
    "nader@R3achout.AI",
    "Riadhossinr4@gmail.com",
    "mohammadjahid0007@gmail.com",
    "reachoutpro.ai@gmail.com",
  ];

  const allowedUser = allowedEmails.includes(userInfo?.email);

  return (
    <DashboardLayout title="Account Settings">
      <section className="account-layout-header">
        <div className="content">
          <ImageUpload />
          <h4 className="name text-base-2"> </h4>
          <div className="d-flex align-items-center justify-content-center">
            <span>{title} </span>
            <span
              className="mx-3"
              style={{
                height: "19px",
                width: "2px",
                background: "#CED5DA",
                borderRadius: "1px",
              }}
            ></span>{" "}
            <span>{company}</span>
          </div>
        </div>
      </section>
      <form onSubmit={handleSubmit}>
        <div className="row g-4">
          <div className="col-lg-6">
            <h6 className="personal-details-title">
              Personal Details
              {allowedUser && (
                <span>
                  <Link
                    className="my-3 text-xs"
                    to={"/account-setting/me "}
                    style={{
                      fontSize: "15px",
                      marginLeft: "10px",
                      color: "black",

                      textDecoration: "none", // Remove default underline
                      color: "#808080", // Set default text color
                      cursor: "pointer",
                    }}
                  >
                    Admin Panel
                  </Link>
                </span>
              )}
            </h6>

            <div className="bg-body-2 p-32-10">
              <div className="row g-4">
                <div className="col-6">
                  <Input
                    label="First Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-6">
                  <Input
                    label="Last Name"
                    value={lname}
                    onChange={(e) => setLName(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <h1 className="form-label form--label">Email Address</h1>
                  <Input disabled value={user?.email} setValue={setEmail} />
                </div>
                <div className="col-12">
                  <h1 className="form-label form--label">Title</h1>
                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <h1 className="form-label form--label">Company</h1>
                  <Input
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <h1 className="form-label form--label">Location</h1>
                  <Input
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
                {userInfo?.accountType == "admin" ||
                user?.email == "nader.nasar@gmail.com" ||
                user?.email == "nader@R3achout.AI" ||
                user?.email == "riadhossinr4@gmail.com" ? (
                  <>
                    <div className="col-12">
                      <Input
                        label="Set words limit"
                        value={words}
                        onChange={(e) => setlimit(e.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {/* <div className="col-12">
									<Input label="Password" type="password" required />
								</div>
								<div className="col-12">
									<Input
										label="Confirm Password"
										type="password"
										required
									/>
								</div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h6 className="personal-details-title"> Subscription</h6>
            {/* <button
												type="button"
												onClick={handleManageSubscription}
												className=" personal-details-title"
											>
												Manage Subscription
											</button> */}
            <div className="radio-grp">
              <label className="radio-item">
                <div className="info">
                  {userInfo.productName === "Free" && (
                    <span className="__badge-sm">Current Plan</span>
                  )}
                  <h6>Free</h6>

                  <span>1k / Month</span>
                </div>
                <input
                  type="radio"
                  name="subscription"
                  className="form-check-input"
                  checked={userInfo.productName === "Free"}
                  onChange={(e) => setSubscription("Free")}
                />
              </label>
              <label className="radio-item">
                <div className="info">
                  {userInfo.productName === "bronze" && (
                    <span className="__badge-sm">Current Plan</span>
                  )}
                  <h6>Day-Pass</h6>
                  <span>10k / 24hours</span>
                </div>
                <input
                  type="radio"
                  name="subscription"
                  className="form-check-input"
                  checked={userInfo.productName === "bronze"}
                  disabled
                  onChange={(e) => setSubscription("daily")}
                />
              </label>
              <label className="radio-item">
                <div className="info">
                  {userInfo.productName === "Silver" && (
                    <span className="__badge-sm">Current Plan</span>
                  )}
                  <h6>Monthly</h6>
                  <span>50k / Month</span>
                </div>
                <input
                  type="radio"
                  name="subscription"
                  className="form-check-input"
                  checked={userInfo.productName === "Silver"}
                  disabled
                  onChange={(e) => setSubscription("monthly")}
                />
              </label>
              <label className="radio-item">
                <div className="info">
                  {userInfo.productName === "Gold" && (
                    <span className="__badge-sm">Current Plan</span>
                  )}
                  <h6>Yearly</h6>
                  <span>85K / month</span>
                </div>
                <input
                  type="radio"
                  name="subscription"
                  className="form-check-input"
                  checked={userInfo.productName === "Gold"}
                  disabled
                  onChange={(e) => setSubscription("yearly")}
                />
              </label>
            </div>
            {/* {console.log({ userInfo })}{" "}
            {userInfo.firstInvoice && ( */}
            <h6
              onClick={handleManageSubscription}
              className="mt-4 __btn   hover:!underline cursor-pointer "
            >
              Manage Subscription
            </h6>
            {/* )} */}
            <h6 className="personal-details-title mt-4">Notification</h6>
            <label className="switch-item bg-body-2">
              <div className="form-check form-switch form--switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={isChecked}
                  onChange={handleCheckboxChange}
                />
              </div>

              <div className="info">
                <h6>News & Updates</h6>
                <span>Newsletters and Feature updates</span>
              </div>
            </label>
            {/* <h6
              // onClick={handleManageSubscription}
              className="mt-4"
              style={{
                textDecoration: "none", // Remove default underline
                color: "#808080", // Set default text color
                cursor: "pointer", // Change cursor to pointer on hover
              }}
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")} // Add underline on hover
              onMouseOut={(e) => (e.target.style.textDecoration = "none")} // Remove underline when not hovering
            >
              Test Subscription
            </h6> */}
            <label className="switch-item bg-body-2">
              {/* <div className="form-check form-switch form--switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={isCheckedTest}
                  onChange={handleTest}
                />
              </div> */}

              {/* <div className="info">
               
                <span>
                  Test your word subscription in one minute (The words will be
                  Zero once the test is successful)
                </span>
              </div> */}
            </label>
          </div>
          <div className="">
            <button
              onClick={handleUpdate}
              className="__btn h-50px mt-6 "
              type="button"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default AccountSetting;
