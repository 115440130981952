import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../components/DashboardLayout";
import { SearchIcon } from "../../components/Icon";
import Input from "../auth/components/Input";
import JobsTable from "./components/JobsTable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext, useUserContext } from "../../Context/UserContext";

const Jobs = () => {
  const { projectId, projectUpdateId, JobNameUpdate } = useUserContext();
  const handleSubmit = (e) => {
    e.preventDefault();
    //('search')
  };
  const [searchValue, setSearchValue] = useState("");
  const [jobData, setJobData] = useState(null); // State to store the job data
  //('jobData', jobData)
  const { user } = useContext(AuthContext);
  const email = user?.email; // Your email for comparison

  useEffect(() => {
    if (email && searchValue) {
      let urlAdress = `${process.env.REACT_APP_SERVER_END_POINT}/estore/search/${searchValue}?email=${email}`;

      fetch(urlAdress)
        .then((res) => res.json())
        .then((data) => {
          setJobData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [searchValue, email]);

  const navigate = useNavigate();
  const handleLinkClick = (e, uniqueID) => {
    e.preventDefault(); // Prevent the default link behavior, if needed

    projectUpdateId(uniqueID); // Update projectId using projectUpdateId
    navigate("/job-management");
  };
  return (
    <>
      <DashboardLayout title="My Jobs">
        <section className="my-jobs-section">
          <div className="__section-title">
            <h5>My Jobs</h5>
            <form
              className="search-form"
              onSubmit={handleSubmit}
              style={{ position: "relative" }}
            >
              {/* <Input onChange={(e) => setSearchValue(e.target.value)} placeholder="Search here.." />
  <button className="no-gutter" type="submit">
    <SearchIcon />
  </button> */}

              {searchValue && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    backgroundColor: "white",
                    zIndex: 1,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px", // Rounded corners
                  }}
                >
                  {jobData?.map((product, index) => (
                    <div key={product.uniqueID}>
                      <Link
                        to="/job-management"
                        onClick={(e) => {
                          handleLinkClick(e, product.uniqueID);
                        }}
                        style={{
                          textDecoration: "none",
                          display: "block",
                          padding: "10px",
                          fontSize: "small",
                          margin: 0,
                          borderBottom:
                            index < jobData.length - 1
                              ? "1px solid #ccc"
                              : "none",
                          transition: "background-color 0.3s, color 0.3s",
                          color: "black", // Default text color
                          borderRadius: "8px", // Rounded corners for each link
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#f5f5f5";
                          e.target.style.color = "blue"; // Text color on hover
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "white";
                          e.target.style.color = "black"; // Default text color on leave
                        }}
                      >
                        {product.name}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </form>
          </div>
          <JobsTable />
          <div className="d-flex justify-content-end mt-32">
            {/* <button className="__btn w-100 max-w-223 h-42 py-0">
							Export As Excel
						</button> */}
          </div>
        </section>
      </DashboardLayout>
    </>
  );
};

export default Jobs;
