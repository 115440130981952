import { useEffect } from "react";
import Footer from "./landing/components/Footer";
import Header from "./landing/components/Header";

const PrivaryPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header isSecondary={true} />

      <div
        className="container"
        style={{
          marginTop: "150px",
        }}
      >
        <h3 className="text-center text-black">Privacy Policy</h3>

        <p
          className="text-black mt-5"
          style={{
            fontStyle: "italic",
          }}
        >
          Effective Date of this Privacy Policy: January 12, 2024
        </p>
        <p
          className="text-black "
          style={{
            marginTop: "14px",
            fontStyle: "italic",
          }}
        >
          Date of Latest Update to this Privacy Policy: January 13, 2024
        </p>
        <p>
          R3achout.AI., an Ontario company, owns and operates www.R3achout.AI
          and its corresponding applications, including web and mobile
          applications (hereinafter collectively referred to as the “
          <b>Platform</b>”).
        </p>
        <p>
          R3achout.AI is and remains committed to protecting the privacy and
          security of the personal information submitted by users of the
          Platform. The present Privacy Policy states how personal information
          obtained by or in connection with the Platform is collected, gathered,
          stored and used. In addition, the present Privacy Policy specifies the
          rights granted to users of the Platform in connection with their
          personal information.
        </p>
        <p>
          <b>I. DEFINITIONS</b>
        </p>
        <p>
          Any capitalized terms defined in this Privacy Policy shall have the
          meaning assigned to them in the Terms of Service applicable to the
          Platform, as published on the Platform.
        </p>
        <p>
          In the present Privacy Policy, R3achout.AI shall be referred to as “
          <b>we</b>”, “<b>us</b>”, “<b>our</b>” and other similar expressions.
        </p>
        <p>
          “<b>Non-Personal Information</b>” shall refer to information that
          cannot be used to personally identify a specific person. Non-Personal
          Information may include anonymous usage data, demographic information,
          preferences selected and preferences generated based on data submitted
          and number of clicks.
        </p>
        <p>
          “<b>Personal Information</b>” shall refer to information that
          personally identifies or relates to a specific person. Personal Data
          is referred to as personal information or personally identifiable
          information in data protection laws and regulations.
        </p>
        <p>
          “<b>Privacy Policy</b>” shall refer to the latest version of this
          Privacy Policy and shall include any and all updates, amendments,
          modifications and supplements made thereto from time to time.
        </p>

        <p>
          “<b>Services</b>” shall refer to the artificial intelligence (AI)
          product provided on the Platform intended to assist recruiters with
          respect to attracting, hiring and screening candidates. The Services
          shall further include: (i) candidate search assistance, (ii) rewriting
          of job descriptions to attract competent and qualified candidates,
          (iii) generating intake questions destined for hiring managers to help
          select the ideal candidate, which intake questions shall be generated
          by artificial intelligence (AI) technologies, and (iv) assistance with
          respect to interviewing candidates against a job description. The
          Services shall further include a built-in editor allowing Users to
          copy and save any selected generated outputs.
        </p>
        <p>
          “<b>User Account</b>” shall refer to the individual user account
          created by a User on the Platform.{" "}
        </p>
        <p>
          “<b>Users</b>” shall refer to all users of the Platform. The term
          “User” shall refer to an individual User of the Platform.
        </p>
        <p>
          <b>II. APPLICATION</b>
        </p>
        <p>
          By accessing and using the Platform, Users accept to be bound by the
          present Privacy Policy. By providing Personal Information on or via
          the Platform, Users further consent to our collection, usage, and
          disclosure of Personal Information according to the terms of this
          Privacy Policy. Users acknowledge that we may send marketing emails
          and materials pursuant to the terms of this Privacy Policy.{" "}
        </p>
        <p>
          This Privacy Policy explains how we handle and treat Personal
          Information collected or gathered from the Platform. This Privacy
          Policy applies only to the Platform, and not to any third party
          platforms.{" "}
        </p>
        <p>
          <b>III. COLLECTION OF PERSONAL INFORMATION</b>
        </p>
        <p>
          We may collect both Non-Personal Data and Personal Data from Users.
          Personal Data consists of information that can be used to personally
          identify a User, such as the User’s name, email address, and IP
          address, without limitation.{" "}
        </p>
        <p>
          3.1 <u>Voluntarily Provided Personal Data</u>
        </p>
        <p>
          Upon using, browsing or otherwise interacting with the Platform, Users
          understand that we may collect Users’ Personal Information and
          Non-Personal Information. We shall collect Personal Information that
          Users voluntarily provide on or in connection with the Platform.{" "}
        </p>
        <p>
          Users may provide Personal Information to us when creating a User
          Account on the Platform. Accordingly, Users may specify their full
          names and email addresses to authenticate their User Accounts.
          Additional details may be optional to provide as part of a User
          Account.
        </p>
        <p>
          3.2 <u>Third Party Service Logins</u>
        </p>
        <p>Third Party Service Logins</p>
        <p>
          <b>IV. COLLECTION OF NON-PERSONAL INFORMATION</b>
        </p>
        <p>
          We may obtain Non-Personal Information regarding Users, such as the
          type of browser used to access the Platform, the device used to access
          the Platform, and the date and time at which Users accessed the
          Platform. This Non-Personal Information does not allow for Users to be
          identified.{" "}
        </p>
        <p>
          We may, periodically, conduct surveys in connection with the Platform.
          Participation in surveys shall be voluntary. Users may also be invited
          to submit reviews, or engage in direct communication with us by way or
          email or using our embedded feedback option Unless otherwise stated,
          all data collected in connection with a completed survey and/or review
          hall remain anonymous and shall therefore not allow for Users’
          identification.
        </p>
        <p>
          <b>V. USAGE OF PERSONAL DATA</b>
        </p>
        <p>
          Users acknowledge and agree that we shall use Personal Information to
          fulfill the purpose for which it was collected. We may use Personal
          Information to personalize Users’ experience and improve the Platform,
          all our usage thereof shall be based on this Privacy Policy.{" "}
        </p>
        <p>
          5.1 <u>Consent, Communication and Support</u>{" "}
        </p>
        <p>
          We may use Personal Information to fulfill the purpose for which the
          User provided the same. As such, we may use a User’s Personal
          Information to respond to the User’s questions, support requests and
          other claims with respect to the Platform. Accordingly, we may use a
          User’s Personal Information to satisfy the User’s request or inquiry
          in the manner and as authorized by such User.
        </p>
        <p>
          We may use a User’s Personal Information to communicate with the User
          regarding the Platform, any applicable updates, modifications and
          other matters in connection with the Platform.
        </p>
        <p>
          5.2 <u>Marketing and Promotional Purposes</u>
        </p>
        <p>
          We may send marketing materials, a newsletter and other promotional
          communications by email. Users shall benefit from the option of
          unsubscribing to our marketing newsletter or promotional
          communications by selecting the appropriate option in their User
          Account settings.
        </p>
        <p>
          5.3 <u>Service Provision and Account Management</u>
        </p>
        <p>
          We may use Personal Information for purposes of account management and
          to deliver the Services offered on the Platform. As such, Personal
          Information may be used for purposes of registering and managing User
          Accounts on the Platform. Personal Information may also be used for
          purposes of providing the Services, including but not limited to
          providing Generated Content, and processing payments via or on the
          Platform.
        </p>
        <p>
          5.4 <u>Legal Purposes</u>
        </p>
        <p>
          Our usage of Users’ Personal Information shall be limited to the
          extent required by our legitimate business interests, and in
          accordance with the requirements set forth by applicable data privacy
          laws. Accordingly, we may disclose a User’s Personal Information if
          required to do so by law, a court order. A User’s Personal Information
          may be used to comply with a binding court order, legal obligation or
          applicable legislation.{" "}
        </p>
        <p>
          Further, we may use a User’s Personal Information if we find that such
          User violated or attempted to violate any of our policies in effect,
          including but not limited to our Terms of Service.
        </p>
        <p>
          In compliance with applicable legislation, we shall not use Personal
          Information for purposes beyond those specified in this Privacy
          Policy, unless Users provided their prior written consent or with
          notice.{" "}
        </p>
        <p>
          5.5 <u>Improvements and Efficiency</u>
        </p>
        <p>
          We may use Non-Personal Information to improve the Platform and
          provide customized experiences. We may also aggregate Non-Personal
          Information to establish patterns of usage with respect to the
          Platform to monitor usage information. We intend to analyze and
          interpret data to improve the Platform’s functionality, user
          experience and efficiency.{" "}
        </p>
        <p>
          We may also use information gathered from surveys and reviews to
          identify User needs and improve the Platform.
        </p>
        <p>
          5.6 <u>Analytical and Statistical Purposes</u>
        </p>
        <p>
          We may use Non-Personal Information to obtain analytical and
          statistical data intended to analyze performance data and statistics
          relating to Users, the effectiveness of the Platform, its features,
          the Services and other relevant data. We may also use such
          Non-Personal Information to assist with optimizing the user interface
          and user experience by understanding how Users interact with the
          Platform.{" "}
        </p>
        <p>
          We intend to use the following metrics for analytical and statistical
          purposes: Users’ feature usage, button presses, session duration, page
          views, click-through rates, bounce rates, conversion rates and
          behavioral analyses. Such data may be used to improve the Platform's
          features, understand user behavior, and enhance the user experience.{" "}
        </p>
        <p>
          Accordingly, we may collect and use Non-Personal Information
          consisting of the time spent using a feature, and most used features.
          Further, we may observe and analyze the contrast of such data against
          job titles to determine usage preferences and patterns.
        </p>
        <p>
          5.7 <u>Third Party Providers </u>
        </p>
        <p>
          We shall not sell, trade, rent or otherwise share Personal Information
          with third parties without Users’ prior consent. Notwithstanding the
          foregoing, we may share Personal Information with third-party
          providers who perform services for us in connection with the Platform.
          The vendors and third party providers we use shall only collect, use
          and disclose Personal Information to the extent necessary to allow
          them to perform the services they provide in connection with the
          Platform.{" "}
        </p>
        <p>
          We reserve the right to share Personal and Non-Personal Information
          with authorized third-party providers to allow such authorized
          third-party providers to:
        </p>
        <p>
          - perform duties that we have delegated or subcontracted to them,
          including processing payments and fulfilling transactions;
        </p>
        <p>
          - protect the Platform against attacks or other malicious activity;
        </p>
        <p>
          - provide analytics and services relating to transactions, usage and
          compliance with legal standards.
        </p>

        <p>
          <b>VI. PROTECTION OF PERSONAL INFORMATION</b>
        </p>
        <p>
          We implemented precautions to protect Personal Information against
          unauthorized access and disclosure. We further implemented measures to
          protect and safeguard Personal Information from unauthorized
          disclosure, damages or other breaches. Moreover, security audits shall
          be conducted on a regular basis to ensure data safety.
        </p>
        <p>
          While these safeguarding and protective methods comply with industry
          best practices, these measures do not guarantee that Personal
          Information shall not be unlawfully accessed, disclosed, altered or
          destroyed. Unfortunately, the transmission of Personal Data over the
          Internet is never fully secure.{" "}
        </p>
        <p>
          <b>VII. USER RIGHTS</b>
        </p>
        <p>
          7.1 <u>Right to be Informed</u>
        </p>
        <p>
          Users shall have the right to be informed of our collection and usage
          of their Personal Information, namely Users’ names and email
          addresses.{" "}
        </p>
        <p>
          To exercise their right to be informed, Users may email{" "}
          <a href="#">support@R3achout.AI</a> to request the Personal
          Information collected and the usage we made thereof. When submitting a
          request to be informed to us, Users shall be required to validate
          their identity by completing the verification requirements we set out.
        </p>
        <p>
          7.2 <u>Right to Rectification</u>
        </p>
        <p>
          If a User finds or believes that the Personal Information contained in
          its User Account is erroneous, unusable or false, the affected User
          may request the rectification or correction of the data. If a User
          determines that the Personal Information we hold is incomplete or
          erroneous, the User may request additions or supplements to such
          information. All such requests shall be transmitted to us by way of
          email.{" "}
        </p>
        <p>
          Notwithstanding the foregoing, Users may be able to make the necessary
          corrections directly through their User Accounts.{" "}
        </p>
        <p>
          7.3 <u>Right to be Forgotten</u>
        </p>
        <p>
          Users of the Platform may proceed with the deletion of Personal
          Information collected by us and contained in their User Account.{" "}
        </p>
        <p>
          To exercise this right, Users shall delete all records, including User
          Content and job projects, by selecting the delete button in the jobs
          summary page. Users may also send an email to our attention to request
          that we delete their Personal Information.
        </p>
        <p>
          7.4 <u>Exercise of Rights</u>
        </p>
        <p>
          To exercise any of the rights granted under this Privacy Policy or by
          applicable laws, Users shall send us a written request by email. For
          the request to be valid, it must identify the User in a clear manner,
          allowing us to validate the User’s identity. Secondly, the request
          must specify the Personal Information requested and the justification
          for such request.{" "}
        </p>
        <p>
          If the request is founded, we shall fulfill the User’s request. We may
          use Personal Information to confirm the User’s identity and satisfy
          the request. We reserve the right to refuse a request if it is
          unfounded or does not comply with the terms of this Privacy Policy.
        </p>
        <p>
          <b>VIII. PERSONAL INFORMATION OF CHILDREN</b>
        </p>
        <p>
          The Platform is not intended for children under the age of eighteen
          (18). We do not knowingly target children or collect Personal
          Information from children. If any User has not reached the age of
          majority in the User’s country of residence, such User may only use
          the Platform with the consent of the User’s parent and legal guardian
          and while accompanied by the User’s parent or legal guardian.{" "}
        </p>
        <p>
          If we discover that a child under the age of eighteen (18) has
          provided Personal Information on the Platform, we shall delete the
          child’s Personal Information as soon as reasonably possible. If a User
          has reason to believe a child provided Personal Information on the
          Platform, such User is invited to notify us by sending an email to{" "}
          <a href="#">legal@R3achout.AI</a>.{" "}
        </p>
        <p>
          <b>IX. STORAGE OF PERSONAL DATA</b>
        </p>

        <p>
          We shall maintain and store a User’s Personal Information for the
          duration during which the User has an active Account, or for the
          duration required by us to provide the subscription and/or Generated
          Content purchased, as applicable. As such, we shall retain a User’s
          Personal Information until the User’s User Account becomes dormant.
          Pursuant to one (1) year of inactivity, we shall notify the User, by
          way of email, that its User Account is inactive and requires a login
          within one (1) month to reactivate the User Account. If the User fails
          to login to its User Account within the one (1) month period pursuant
          to the receipt of our notice, the User Account shall be effectively
          deleted from our servers.
        </p>
        <p>
          We may retain a User’s Personal Information for a longer period to
          comply with legal obligations. In addition, we may hold Personal
          Information in our servers for payment purposes until all funds owed
          have been paid or to resolve disputes.{" "}
        </p>
        <p>
          Further, we reserve the right to store and maintain Personal
          Information on file for a longer duration if permitted by applicable
          laws and regulations. Once we have removed a User’s Personal
          Information from our servers, we may maintain Non-Personal Information
          in our servers, indefinitely, for analytical and statistical purposes.{" "}
        </p>
        <p>
          <b>X. UPDATES TO THIS PRIVACY POLICY</b>
        </p>
        <p>
          We may update this Privacy Policy, at any time, at our convenience or
          for compliance purposes if any laws or industry standards were to
          change. If we update this Privacy Policy, the revised Privacy Policy
          shall be published on the Platform.{" "}
        </p>
        <p>
          Any updates to this Privacy Policy shall be effective upon the date
          listed in the updated Privacy Policy, or upon the date at which Users
          were notified of the updated Privacy Policy, whichever is sooner. It
          is each User’s responsibility to review this Privacy Policy
          periodically.{" "}
        </p>
        <p>
          The continued usage and/or access to the Platform by Users following
          the publication of the updated Privacy Policy signifies Users’
          agreement with the updated Privacy Policy. If a User does not agree
          with any changes made to this Privacy Policy, the User’s sole and
          exclusive recourse shall be to cease using the Platform.{" "}
        </p>
        <p>
          <b>XI. QUESTIONS AND COMMENTS</b>
        </p>
        <p>
          If Users have any questions regarding this Privacy Policy, our
          practices, or wish to withdraw a consent for the continued collection,
          use or disclosure of their Personal Information, please contact us by
          sending an email. Any requests in connection with the present Privacy
          Policy should be addressed to us and sent by email to{" "}
          <a href="#">legal@R3achout.AI</a>.{" "}
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivaryPolicy;
