import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Context/UserContext";
import OutOfCreditPopup from "../pages/dashboard/components/OutOfCreditPopup";

const CallUserAccount = ({ userCall }) => {
  const [OpenPopup, setOpenPopup] = useState(false);
  const productName = "riad";
  const limitWords = 50000;

  const { user, userInfo, daypass } = useContext(AuthContext);

  // Call updateUser only when the action value changes to 1
  // useEffect(() => {
  //     const updateUser = async () => {
  //       try {
  //         const response = await fetch(`https://nadereach-server.vercel.app/update/account/subscription/${user?.email}`, {
  //           method: 'PUT',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({ productName ,limitWords}), // Include 'age' in the body if needed
  //         });

  //         if (response.ok) {
  //             alert('limit end')
  //             setOpenPopup(true)
  //           ////('User updated successfully.');
  //         } else {
  //           // Failed to update user.
  //           console.error('Failed to update user.');
  //         }
  //       } catch (error) {
  //         console.error('An error occurred while updating user:', error);
  //       }
  //     };

  //     updateUser(); // Call updateUser when the component mounts or 'age' changes
  //   }, [userCall]);

  return (
    <div>
      <OutOfCreditPopup
        open={OpenPopup}
        setOpen={setOpenPopup}
        heading={`You've Hit the Limit!`}
        text="You've reached your maximum for this feature, but your potential
              is unlimited. Upgrade now to continue without interruption."
      />
    </div>
  );
};

export default CallUserAccount;
