import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import LandingPage from "./pages/landing/LandingPage";
import TermsOfServices from "./pages/TermsOfServices";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import OnBoarding1 from "./pages/auth/OnBoarding1";
import OnBoarding2 from "./pages/auth/OnBoarding2";
import UserList from "./pages/dashboard/UserList/UserList";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Home from "./pages/dashboard/Home";
import Jobs from "./pages/dashboard/Jobs";
import DetailsUser from "./pages/dashboard/UserList/DetailsUser";
import JobsManagementPage from "./pages/dashboard/jobs-single/JobsManagementPage";
import ReachoutPage from "./pages/dashboard/jobs-single/ReachoutPage";
import Chatgpt from "./pages/dashboard/jobs-single/components/Chatgpt";
import Test from "./pages/dashboard/jobs-single/components/Test";
import JobSearchPage from "./pages/dashboard/jobs-single/JobSearchPage";
import JdRewritePage from "./pages/dashboard/jobs-single/JdRewritePage";
import InterviewPage from "./pages/dashboard/jobs-single/InterviewPage";
import IntakePage from "./pages/dashboard/jobs-single/IntakePage";
import AccountSetting from "./pages/dashboard/account-setting/AccountSetting";
import PersonalDetails from "./pages/dashboard/account-setting/PersonalDetails";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import Payment from "./Vip/Payment";
import Success from "./Vip/Success";
import PublicRoute from "./PrivateRoute/PublicRoute";
import PrivateAuthRoute from "./PrivateRoute/PrivateAuthRoute";
import PdfView from "./pages/pdfView";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<LandingPage />} />
      <Route path="tos" element={<TermsOfServices />} />
      <Route path="privacy" element={<PrivacyPolicy />} />
      {/* <Route path="pdf-view" element={<PdfView />} /> */}
      <Route path="onboarding-1" element={<OnBoarding1 />} />
      <Route path="onboarding-2" element={<OnBoarding2 />} />
      <Route
        path="userlist"
        element={
          <PrivateRoute>
            <UserList />
          </PrivateRoute>
        }
      />
      <Route element={<PrivateAuthRoute />}>
        <Route path="home" element={<Home />} />
        <Route path="jobs" element={<Jobs />} />
        <Route path="detailsuser/:email" element={<DetailsUser />} />
        <Route path="job-management" element={<JobsManagementPage />} />
        <Route path="r3achout" element={<ReachoutPage />} />
        <Route path="gpt" element={<Chatgpt />} />
        <Route path="test" element={<Test />} />
        <Route path="search" element={<JobSearchPage />} />
        <Route path="jd-rewrite" element={<JdRewritePage />} />
        <Route path="interview" element={<InterviewPage />} />
        <Route path="intake" element={<IntakePage />} />
        <Route path="dashboard" element={<Home />} />
        <Route path="/dashboard/*" element={<Home />} />

        <Route path="account-setting" element={<AccountSetting />} />
        <Route path="account-setting/me" element={<PersonalDetails />} />
        <Route path="payment" element={<Payment />} />
      </Route>
      <Route element={<PublicRoute redirectTo="/home" />}></Route>
      <Route path="register" element={<SignUp />} />
      <Route path="login" element={<Login />} />

      <Route path="success" element={<Success />} />
    </Route>
  )
);

export default router;
