import React, { useEffect, useState } from "react";
import Input from "../pages/auth/components/Input";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../Context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faClose } from "@fortawesome/free-solid-svg-icons";

const Popup = ({ text }) => {
  //nc
  return (
    <>
      <div className="modal fade" id="job-save-pop">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-modal">
            <div className="modal-header pb-0 border-0 justify-content-center">
              <h5 className="modal-title pt-4">{text}</h5>
              <button
                type="button"
                className="btn-close top-right-stick"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </div>
            <div className="modal-body p-4 px-sm-5"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
