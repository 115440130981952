import React, { useContext, useEffect, useRef, useState } from "react";
import TextEditor from "../../../../components/TextEditor";
import Input from "../../../auth/components/Input";
import axios from "axios";
import { AuthContext, useUserContext } from "../../../../Context/UserContext";
import OpenAI from "openai";
import { cssTransition } from "react-toastify";
import { RingLoader } from "react-spinners";
import OutOfCreditPopup from "../../components/OutOfCreditPopup";
import JobDescriptionPopup from "../../components/JobDescriptionPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { useCtrlASelect } from "../../../../hooks/useCtrlSelect";

const Intake = () => {
  const {
    FullJobData,

    intakeQst,
    meetingAttendee,
    intakeRole,
    setIntakeQst,
    setintakeRole,
    setMeetingAttendee,

    intakeLoading,
    handleIntakeClick,
    OpenPopup,
    setOpenPopup,

    errors,
    intakeBotResponses0,
    setIntakeBotResponses0,
    intakeBotResponses1,
    setIntakeBotResponses1,
    isIntakeSaved,
    intakeQstType,
    setIntakeQstType,
  } = useUserContext();
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(true);
  const [decodedValue, setDecodedValue] = useState("");
  const [firstEffectFinished, setFirstEffectFinished] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [more, setMore] = useState(false);
  const [moreOptionsPopup, setMoreOptionsPopup] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    setDecodedValue(decodeURIComponent(value));
  }, [value]);
  //mc

  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);

  useCtrlASelect(textareaRef1);
  useCtrlASelect(textareaRef2);

  // get job title from localstorage
  let savedJobTitle = localStorage.getItem("jobTitle");
  const checkForChanges = () => {
    const newValue = localStorage.getItem("jobTitle");
    if (newValue !== savedJobTitle) {
      // Value has changed, update savedJobTitle
      savedJobTitle = newValue;
      // Do something with the updated value
    }
  };
  const pollingInterval = 500; // 500 milliseconds
  setInterval(checkForChanges, pollingInterval);
  checkForChanges();

  // get job title from localstorage

  //   get job details from localstorage
  let savedJobDetails = localStorage.getItem("jd");
  const checkForChangesDetails = () => {
    const newValue = localStorage.getItem("jd");
    if (newValue !== savedJobDetails) {
      // Value has changed, update savedJobTitle
      savedJobDetails = newValue;
      // Do something with the updated value
    }
  };
  const pollingIntervalDetails = 500; // 500 milliseconds
  setInterval(checkForChangesDetails, pollingIntervalDetails);
  checkForChangesDetails();

  //   get job details from localstorage
  /////////////////////////////--Get data from localStorage--////////////////////////////

  useEffect(() => {
    setDecodedValue(decodeURIComponent(value));
  }, [value]);

  //nc

  const [response, setResponse] = useState("");

  const allValues = [];

  for (const item of response) {
    const values = Object.values(item);
    allValues.push(...values);
  }
  //mc
  const get = "intactab";
  const post = "intake";

  // const heading = `Intake: ${JobName ? JobName : ""} - `;
  const heading = `Intake: ${""} - `;
  const deleteEditor = "intakecollection";

  // Get the current date and time
  const currentDate = new Date();

  // Define options for formatting
  const options = {
    day: "numeric",
    month: "long",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  // Format the date and time
  const formattedDateTime = currentDate.toLocaleString("en-US", options);

  // Add the formatted date and time to the heading

  //gpt---------------------------------------------------------------------------------------------------

  const singleObject = FullJobData[0];
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPEN_AI_KEY,
    dangerouslyAllowBrowser: true,
  });

  const [userInput, setUserInput] = useState("");
  //('intakeBotResponses', intakeBotResponses);
  const { userInfo } = useContext(AuthContext);

  const [jdEmptyPopup, setjdEmptyPopup] = useState(false);

  //gpt---------------------------------------------------------------------------------------------------

  ///////////Database input box

  const { projectId, projectUpdateId } = useUserContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/get/intake/inputBox/${projectId}`
        );
        const result = await response.json();

        setJobData(result);
        setIntakeBotResponses0(result[0]?.intakeBotResponses0 || "");
        setIntakeBotResponses1(result[0]?.intakeBotResponses1 || "");
        setIntakeQst(result[0]?.intakeQst || "3");
        setIntakeQstType(result[0]?.intakeQstType || "-");
        setMeetingAttendee(result[0]?.meetingAttendee || "-");
        setintakeRole(result[0]?.intakeRole || "-");

        setFirstEffectFinished(true);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (projectId) {
      fetchData();
    }
  }, [projectId, isIntakeSaved]);

  // useEffect(() => {
  //   if (firstEffectFinished) {
  //     const apiUrl = `${process.env.REACT_APP_SERVER_END_POINT}/update/intake/inputBox/${projectId}`;

  //     // Your job data, including intakeBotResponses
  //     const jobData = {
  //       intakeBotResponses0: intakeBotResponses[0],
  //       intakeBotResponses1: intakeBotResponses[1],
  //     };

  //     const requestOptions = {
  //       method: "PUT", // Change this to PUT
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(jobData),
  //     };

  //     // Make the PUT request using fetch
  //     fetch(apiUrl, requestOptions)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         ////console'Data from server:', data);
  //         // Handle the response data as needed
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //         // Handle errors
  //       });
  //   }
  // }, [intakeBotResponses, firstEffectFinished]);

  // useEffect(() => {
  //   const totalWordCount = intakeBotResponses.reduce((total, obj) => {
  //     return (
  //       total +
  //       Object.values(obj).reduce((count, value) => {
  //         return count + value.split(/\s+/).length;
  //       }, 0)
  //     );
  //   }, 0);

  //   setCharacterCount(totalWordCount);
  // }, [intakeBotResponses]);

  // useEffect(() => {
  //   // Function to count words in a string
  //   const countWords = (str) => {
  //     const words = str.split(/\s+/);
  //     return words.length;
  //   };

  //   // Calculate total word count from intakeBotResponses array
  //   const totalWordCount = intakeBotResponses.reduce((count, paragraph) => {
  //     return count + countWords(paragraph);
  //   }, 0);

  //   // Update the state with the total word count
  //   setCharacterCount(totalWordCount);
  // }, [intakeBotResponses]);

  // useEffect(() => {
  //   handleWords(characterCount);
  // }, [characterCount]);

  const handleOpenJd = () => {
    if (singleObject == undefined) {
      alert("Please select job");
      return;
    }

    if (singleObject.jd == undefined) {
      setjdEmptyPopup(true);
    } else {
      handleIntakeClick(projectId);
    }
  };

  const handleMoreOption = () => {
    if (userInfo.productName !== "Gold") {
      // setMoreOptionsPopup(true);
    }

    setMore(!more);
  };
  return (
    <>
      <section className="jobs-management bg-body-2">
        <form onSubmit={handleSubmit} className="dashboard-form-2">
          <div className="row g-4 flex-lg-nowrap">
            <div
              className={` ${
                !open ? "col-lg-12" : "col-lg-8 "
              } lg:min-h-[55vh]`}
            >
              <div className="flex flex-col lg:flex-row gap-3 h-full  ">
                <div className="col-lg-4  h-full mt-3">
                  <div className="form-group mb-4">
                    <Input
                      label="Number Of Questions"
                      onChange={(e) => {
                        setIntakeQst(e.target.value);
                        // setDirty(true);
                      }}
                      options={numbers}
                      value={intakeQst}
                    />
                  </div>

                  {more && (
                    <>
                      <div className="form-group mb-4">
                        <Input
                          label="Role level"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              userInfo.productName === "Gold" ||
                              userInfo.productName === "bronze"
                            ) {
                              setintakeRole(value);
                            } else {
                              setMoreOptionsPopup(true);
                            }
                            // setDirty(true);
                          }}
                          options={roles}
                          value={intakeRole}
                          isDisabled={
                            userInfo.productName !== "Gold" &&
                            userInfo.productName !== "bronze"
                          }
                        />
                      </div>
                      <div className="form-group mb-4">
                        <Input
                          label="Meeting attendee"
                          value={meetingAttendee}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              userInfo.productName === "Gold" ||
                              userInfo.productName === "bronze"
                            ) {
                              setMeetingAttendee(value);
                            } else {
                              setMoreOptionsPopup(true);
                            }
                            // setDirty(true);
                          }}
                          options={meetingAttendees}
                          isDisabled={
                            userInfo.productName !== "Gold" &&
                            userInfo.productName !== "bronze"
                          }
                        />
                      </div>
                      <div className="form-group mb-4">
                        <Input
                          label="Type Of Questions"
                          value={intakeQstType}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (
                              userInfo.productName === "Gold" ||
                              userInfo.productName === "bronze"
                            ) {
                              setIntakeQstType(value);
                            } else {
                              setMoreOptionsPopup(true);
                            }

                            // setDirty(true);
                          }}
                          options={intakeQstTypes}
                          isDisabled={
                            userInfo.productName !== "Gold" &&
                            userInfo.productName !== "bronze"
                          }
                        />
                      </div>
                    </>
                  )}
                  <button
                    className="__btn-outline w-100 m-0  !font-[500] !leading-5 !text-base !capitalize "
                    onClick={handleMoreOption}
                  >
                    {!more ? "More Options..." : "Less Options..."}
                  </button>

                  <div className="d-flex  flex-col items-center   gap-20px save-info">
                    {errors.intakeErrors && (
                      <p className="text-red-500 text-sm  font-semibold">
                        {" "}
                        {errors.intakeErrors}
                      </p>
                    )}
                    <button
                      onClick={handleOpenJd}
                      className="__btn w-100 mb-32  mt-4 !font-[500] !leading-5 !text-base !capitalize"
                      disabled={intakeLoading}
                    >
                      {intakeLoading ? (
                        <div className="!flex justify-around gap-2 items-center  w-full">
                          <p className="mb-0"> Loading…</p>
                          <FontAwesomeIcon icon={faCircleNotch} spin />
                        </div>
                      ) : (
                        "Get Intake Questions"
                      )}
                    </button>
                  </div>
                </div>

                <div className="flex flex-col gap-3 w-full">
                  <h1 className=" form--label !font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D]  ">
                    Suggested Questions{" "}
                  </h1>
                  {/* <label className="form--label mb-4">Intake questions</label> */}
                  <div
                    className="form-control form--control __output-text"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                    readOnly={true}
                    ref={textareaRef1}
                    contentEditable
                    spellCheck="false"
                    dangerouslySetInnerHTML={{
                      __html: loading
                        ? ""
                        : intakeBotResponses0
                            .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>") // Convert markdown bold to HTML bold
                            .replace(/\n/g, "<br />"),
                    }}
                  />
                </div>
              </div>
            </div>

            {/* <div className="d-flex  flex-col items-center   gap-20px save-info">
                {errors.intakeErrors && (
                  <p className="text-red-500 text-sm  font-semibold">
                    {" "}
                    {errors.intakeErrors}
                  </p>
                )}
                <button
                  onClick={handleOpenJd}
                  className="__btn w-100 mb-32 h-50px mt-4 !font-[500] !leading-5 !text-base !capitalize"
                  disabled={intakeLoading}
                >
                  {intakeLoading ? (
                    <div className="!flex justify-around gap-2 items-center  w-full">
                      <p className="mb-0"> Loading…</p>
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    </div>
                  ) : (
                    "Get Intake Questions"
                  )}
                </button>
              </div> */}
            {/* <div className={`flex flex-col ${open ? "col-lg-3" : "col-lg-4"}`}>
              <h1 className=" form--label !font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D]  ">
                Profile refinement
              </h1> */}
            {/* <label className="form--label mb-4">
                Profile refinement
                </label> */}
            {/* </div> */}
            <div className={`${open ? "col-lg-4" : "col-lg-1"}`}>
              <div>
                <TextEditor
                  get={get}
                  post={post}
                  formattedDateTime={formattedDateTime}
                  deleteEditor={deleteEditor}
                  title={heading}
                  value={value}
                  setValue={setValue}
                  open={open}
                  setOpen={setOpen}
                />
              </div>
            </div>
          </div>
        </form>
        <OutOfCreditPopup
          open={OpenPopup}
          setOpen={setOpenPopup}
          heading={`You've Hit the Limit!`}
          text={
            userInfo.productName !== "Gold"
              ? "You've reached your maximum for this feature, but your potential is unlimited. Upgrade now to continue without interruption."
              : "You've used all your word credits for this month. Stay tuned, your credits will refresh soon."
          }
          userInfo={userInfo}
        />
        <OutOfCreditPopup
          open={moreOptionsPopup}
          setOpen={setMoreOptionsPopup}
          heading={`Unlock More Options!`}
          text={
            "Why limit yourself? As a subscriber,you get more tools,more features and more possibilities. Eelevate your experience with R3chout.AI and join our community"
          }
          userInfo={userInfo}
        />
        <JobDescriptionPopup
          open={jdEmptyPopup}
          setOpen={setjdEmptyPopup}
          handleUserInput={(projectId) => handleIntakeClick(projectId)}
        ></JobDescriptionPopup>
      </section>
    </>
  );
};
const roles = [
  {
    name: "-",
    value: "-",
  },
  {
    name: "Junior",
    value: "Entry-level",
  },
  {
    name: "Intermediate",
    value: "Mid-level",
  },
  {
    name: "Senior",
    value: "Senior-level",
  },
  {
    name: "Lead",
    value: "Lead",
  },
  {
    name: "Advisor",
    value: "Advisor",
  },
  {
    name: "Manager",
    value: "Manager",
  },
  // {
  //   name: "Director",
  //   value: "Director",
  // },
  // {
  //   name: "Executive",
  //   value: "Executive",
  // },
  // {
  //   name: "Chief Officer",
  //   value: "Chief Officer",
  // },
];

const numbers = [
  {
    name: "1",
    value: "1",
  },
  {
    name: "2",
    value: "2",
  },
  {
    name: "3",
    value: "3",
  },
  {
    name: "4",
    value: "4",
  },
  {
    name: "5",
    value: "5",
  },
  {
    name: "6",
    value: "6",
  },
];

const meetingAttendees = [
  {
    name: "-",
    value: "-",
  },
  {
    name: "Hiring Manager",
    value: "Hiring Manager",
  },
  {
    name: "Decision Maker",
    value: "Decision Maker",
  },
  {
    name: "Human Resources",
    value: "Human Resources person",
  },
  {
    name: "Client",
    value: "Client, representing an organization with hiring needs",
  },
];

const intakeQstTypes = [
  { name: "-", value: "-" },
  { name: "Intake questions", value: "Intake questions" },
  { name: "profile refinement", value: "Profile refinement" },
];

export default Intake;
