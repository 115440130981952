import React, { useContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./Checkout";
import { AuthContext } from "../Context/UserContext";
import axios from "axios";
import {
  CheckIcon,
  DayPassIcon,
  FreeIcon,
  MonthlyIcon,
  UnCheckIcon,
} from "../components/Icon";
import SectionHeader from "../pages/landing/components/SectionHeader";
import { useNavigate } from "react-router-dom";
const stripePromise = loadStripe("your_stripe_publishable_key");

const Payment = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { user, userInfo } = useContext(AuthContext);
  const [load, setLoad] = useState(false);

  const navigate = useNavigate();

  const handleManageSubscription = async () => {
    try {
      // Assuming you have a customerId stored in Firestore or similar
      ////({ customerId })

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_END_POINT}/create-portal`,

        { customerId: userInfo?.customer_id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.url) {
        window.location.href = response.data.url; // Redirect to the customer portal
      }
    } catch (error) {
      console.error("Error accessing customer portal:", error);
    }
  };
  useEffect(() => {
    console.log(userInfo.productName);
    if (userInfo && userInfo.productName === "Silver") {
      handleManageSubscription();
    } else if (
      (userInfo && userInfo.productName === "Gold") ||
      (userInfo && userInfo.productName === "Custom")
    ) {
      navigate("/dashboard");
    }
  }, [userInfo]);

  const handleSubscribe = async (price, priceId, productName) => {
    const metadata = {
      price,
      productName: productName,
      email: user.email,
      firstName: userInfo.firstName,
      customer_id: userInfo?.customer_id || "",
    };

    console.log({ priceId }, process.env.REACT_APP_DAYPASS_PLAN_ID, {
      productName,
    });
    if (productName === "Enterprise") {
      window.location.href = "https://calendly.com/meet_nader/demo";
      return;
    }

    try {
      // Replace 'https://nadereach-server.vercel.app' with your actual server URL
      setLoad(true);
      const response = await axios.post(
        // "https://nadereach-server.vercel.app/create-checkout-session",
        `${process.env.REACT_APP_SERVER_END_POINT}/create-checkout-session`,

        // "http://localhost:5000/create-checkout-session",
        {
          priceId: priceId,
          metadata: metadata,
        }
      );

      const { url } = response.data;

      window.location.href = url;
      setLoad(false);
    } catch (error) {
      console.error("Error creating checkout session:", error.message);
      setLoad(false);
    }
  };

  // priclist
  const [isMonthly, setIsMonthly] = useState(true);
  const data = [
    // {
    //   name: "Free",
    //   limit: "No Credit card required",
    //   creditCard: true,
    //   duration: "/month",
    //   price: "$00",
    //   btnText: "Sign up for free",
    //   icon: <FreeIcon />,
    //   included: [
    //     {
    //       isIncluded: true,
    //       text: "Trained AI for recruiters",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "All feature enabled",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Built-in Editor tool",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "3 Job projects",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "1000 words/month",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "No Support ",
    //     },
    //   ],
    // },
    {
      name: "Day Pass",
      limit: "Test Driving experience",
      creditCard: false,
      duration: "day",
      price: "$12",
      priceId: process.env.REACT_APP_DAYPASS_PLAN_ID,
      productName: "bronze",
      btnText: "Try it out for a day",
      icon: <DayPassIcon />,
      included: [
        {
          isIncluded: true,
          text: "Trained AI for recruiters",
        },
        {
          isIncluded: true,
          text: "All features enabled",
        },
        {
          isIncluded: true,
          text: "Built-in Editor tool",
        },
        {
          isIncluded: true,
          text: "Sourcing",
        },
        {
          isIncluded: true,
          text: "Enhanced Intake and Interview Questions",
        },
        {
          isIncluded: true,
          text: "10 Job projects",
        },
        {
          isIncluded: true,
          text: "10000 words",
        },
        {
          isIncluded: true,
          text: "Email Support",
        },
      ],
    },

    {
      name: "Silver",
      limit: "Your Comprehensive Solution",
      creditCard: false,
      duration: "monthly",
      price: "$39",
      priceId: process.env.REACT_APP_SILVER_PLAN_ID,
      productName: "Gold",

      btnText: "Get Started",
      icon: <MonthlyIcon />,
      included: [
        {
          isIncluded: true,
          text: "Trained AI for recruiters",
        },
        {
          isIncluded: true,
          text: "All features enabled",
        },
        {
          isIncluded: true,
          text: "Built-in Editor tool",
        },
        {
          isIncluded: true,
          text: "Sourcing",
        },
        {
          isIncluded: true,
          text: "Basic Intake and Interview Questions",
        },
        {
          isIncluded: true,
          text: "25 Job projects",
        },
        {
          isIncluded: true,
          text: "50000 words/month",
        },
        {
          isIncluded: true,
          text: "Email Support",
        },
      ],
    },
    {
      name: "Gold",
      limit: "Get the best of our app !",
      duration: "annually",
      creditCard: false,
      discount: false,
      price: "$595",
      priceId: process.env.REACT_APP_GOLD_PLAN_ID,
      productName: "Gold",

      btnText: "Activate our Top Tier",
      icon: <MonthlyIcon />,
      included: [
        {
          isIncluded: true,
          text: "Trained AI for recruiters",
        },
        {
          isIncluded: true,
          text: "All features enabled",
        },
        {
          isIncluded: true,
          text: "Built-in Editor tool",
        },
        {
          isIncluded: true,
          text: "Sourcing",
        },
        {
          isIncluded: true,
          text: "Enhanced Intake and Interview Questions",
        },
        {
          isIncluded: true,
          text: "75 Job projects",
        },
        {
          isIncluded: true,
          text: "85000 words/month",
        },
        // {
        //   isIncluded: true,
        //   text: "3 Search assistance / Job",
        // },
        {
          isIncluded: true,
          text: "Early access to new features",
        },
        {
          isIncluded: true,
          text: "Email Support",
        },
      ],
    },
    // {
    //   name: "Enterprise",
    //   limit: "Tailored solutions for your needs",
    //   creditCard: false,
    //   // duration: "day",
    //   // price: "Custom",
    //   btnText: "Contact Us",
    //   // icon: <DayPassIcon />,
    //   included: [
    //     {
    //       isIncluded: true,
    //       text: "Trained AI for recruiters",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Trained and Enhanced AI on your hiring data",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "All features enabled",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Built-in Editor tool",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Sourcing",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Enhanced Intake and Interview Questions",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Custom Job projects",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Custom words/month",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Training",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Early access to new features",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Training",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "Business readiness",
    //     },
    //     {
    //       isIncluded: true,
    //       text: "7 / 24 Support",
    //     },
    //   ],
    // },
  ];

  // priclist

  return (
    <div className="flex justify-center items-center h-screen">
      {/* <div className="w-96 bg-white p-8 rounded ">
                <h2 className="text-2xl font-bold mb-4">Choose a Plan</h2>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }} className="space-y-4">
                    {plans.map((plan) => (
                        <div
                            key={plan.id}
                            className={`${selectedPlan === plan.priceId ? 'border-blue-500' : 'border-gray-300'
                                } border p-4 rounded cursor-pointer`}
                            onClick={() => handleSelectPlan(plan.priceId)}
                        >
                            <h3 className="text-lg font-semibold mb-2">{plan.name}</h3>
                            <p className="text-gray-600 mb-2">${plan.price} USD</p>
                            <ul className="list-disc list-inside">
                                {plan.features.map((feature, index) => (
                                    <li key={index} className="text-gray-600">
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <button onClick={() => handleSubscribe(plan.price, plan.priceId)}
                                className='btn btn-primary'
                            >
                                {load ?
                                    'Wait..'
                                    :
                                    'Subscribe'
                                }
                            </button>
                        </div>
                    ))}
                </div>
            </div> */}
      <section className="w-full h-full">
        <section className="pricing-section">
          <div className="pricing-section-top">
            <div id="pricing" className="target-id"></div>
            <div className="container">
              <SectionHeader
                attrTitle="Pricing"
                title="Affordable "
                colorTitle="Pricing Plans"
                subtitle="Accessible Pricing for Everyone. Our pricing plans are designed to meet your specific needs. Whether you’re seeking basic features or comprehensive services, we’ve got you covered."
              />
              {/* <div className="switcher">
						<button className="btn" onClick={() => setIsMonthly(false)}>
							Yearly
						</button>
						<div class="form-check form-switch">
							<input
								class="form-check-input"
								type="checkbox"
								checked={isMonthly}
								onChange={(e) => setIsMonthly(e.target.checked)}
							/>
						</div>
						<button className="btn" onClick={() => setIsMonthly(true)}>
							Monthly
						</button>
					</div> */}
            </div>
          </div>
          <div className="container">
            <div className="pricing-wrapper">
              <div className="row g-4 justify-content-center !flex-nowrap flex flex-col md:flex-row">
                {data?.map((item, i) => (
                  <div className="col-md-6 col-lg-4" key={i}>
                    <div className="plan-card">
                      <div className="plan-card-header">
                        {/* <div className="icon">{item?.icon}</div> */}
                        <div className="cont">
                          <h3 className="name">{item?.name}</h3>
                          <h5 className="limit">{item?.limit}</h5>
                          {item?.creditCard ? (
                            <span className="text text-base">
                              No Credit card required
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <h2 className="plan-card-pricing">
                        {item?.discount ? (
                          <>
                            <span
                              style={{
                                textDecoration: "line-through",
                                color: "red",
                              }}
                            >
                              $468
                            </span>{" "}
                            {item?.price}
                            <sub>/{item?.duration}</sub>
                          </>
                        ) : (
                          <>
                            {item?.price}
                            <sub>/{item?.duration}</sub>
                          </>
                        )}
                      </h2>
                      <h6 className="subtitle">What’s Included</h6>
                      <ul className="feature-txt">
                        {item?.included?.map(({ isIncluded, text }, j) => (
                          <li key={j}>
                            {isIncluded ? <CheckIcon /> : <UnCheckIcon />}
                            <span>{text}</span>
                          </li>
                        ))}
                      </ul>
                      {/* <button type="button" className="pricing-btn">
										{item?.btnText}
									</button> */}
                      <button
                        onClick={() =>
                          handleSubscribe(
                            item.price,
                            item.priceId,
                            item.productName
                          )
                        }
                        className="pricing-btn"
                      >
                        {item.name === "Enterprise"
                          ? "Contact Us"
                          : "Subscribe"}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Payment;
