import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Clear, Hamburger, PlayIcon } from "../../../components/Icon";
import Logo from "../../../components/Logo";
import useMobile from "../../../hooks/useMobile";
import useScroll from "../../../hooks/useScroll";
import VideoPlayer from "./VideoPlayer";
import { useUserContext } from "../../../Context/UserContext";

const Header = ({ isSecondary }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const scroll = useScroll();
  const mobile = useMobile();

  return (
    <>
      <header className={scroll > 20 || isSecondary ? "active" : ""}>
        <div className="container">
          <div className="header-wrapper">
            <Logo variant="white" />
            <div className={`menu-area ${open ? "active" : ""}`}>
              <ul className="menu">
                <li>
                  <a
                    href={
                      pathname === "/"
                        ? "/#home"
                        : pathname === "/tos" || pathname === "/privacy"
                        ? "/#home"
                        : "/"
                    }
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Product
                  </a>
                </li>
                <li>
                  <a
                    href={
                      pathname === "/"
                        ? "/#benefit"
                        : pathname === "/tos" || pathname === "/privacy"
                        ? "/#benefit"
                        : "/"
                    }
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Benefits
                  </a>
                </li>
                <li>
                  <a
                    href={
                      pathname === "/"
                        ? "/#pricing"
                        : pathname === "/tos" || pathname === "/privacy"
                        ? "/#pricing"
                        : "/"
                    }
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Pricing
                  </a>
                </li>
              </ul>
              {mobile && (
                <>
                  <HeaderIcons setModal={setModal} />
                  <div className="close-menu" onClick={() => setOpen(false)}>
                    <Clear />
                  </div>
                </>
              )}
            </div>
            {!mobile ? (
              <HeaderIcons setModal={setModal} />
            ) : (
              <button
                className="btn p-0 border-0"
                onClick={() => setOpen(!open)}
              >
                <Hamburger />
              </button>
            )}
          </div>
        </div>
      </header>
      <div className={`video-player-modal ${modal ? "active" : ""}`}>
        <div className="__backdrop" onClick={() => setModal(false)}></div>
        <VideoPlayer
          link="https://www.youtube.com/watch?v=zJSY8tbf_ys"
          playing={modal}
          controls
          className="player"
        />
      </div>
    </>
  );
};
const HeaderIcons = ({ setModal }) => {
  const { userInfo } = useUserContext();
  return (
    <>
      <ul className="header-buttons">
        {/* <li>
          <button className="play-button" onClick={() => setModal(true)}>
            See How it Works <PlayIcon />
          </button>
        </li> */}
        <li>
          <Link
            to={userInfo?.email ? "/dashboard" : "/login"}
            className="header-btn"
          >
            {userInfo?.email ? "Dashboard" : "Login"}{" "}
          </Link>
        </li>
      </ul>
    </>
  );
};

export default Header;
