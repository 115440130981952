import React from 'react';
import { useSpeechSynthesis } from 'react-speech-kit';

const Test = () => {
  const { speak } = useSpeechSynthesis();

  const textToSpeak = `
  
  Are you ready to elevate your career in tech? We are seeking passionate and skilled React developers to join our dynamic team and make an impact in the ever-evolving world of technology.

Benefits of Joining Us:

Cutting-Edge Projects: Work on innovative and challenging projects that will push your skills to the next level.

Collaborative Environment: Join a supportive and collaborative team where your contributions are valued and recognized.

Professional Growth: Access to extensive training and development opportunities, allowing you to continue learning and expanding your skill set.

State-of-the-Art Technology: Get the chance to work with the latest technologies and tools, enhancing your expertise and staying at the forefront of the industry.

Flexible Work Environment: Enjoy a flexible work schedule and remote work options, promoting a healthy work-life balance.

Competitive Compensation: Receive a competitive salary and benefits package, including health insurance and retirement plans.

If you're ready to take your React development career to the next level, join us and be part of something amazing! Apply now and seize this incredible opportunity to grow and thrive in the tech industry.
  `;

  const handleSpeak = () => {
    speak({ text: textToSpeak });
  };

  return (
    <div>
      <button onClick={handleSpeak}>Speak</button>
    </div>
  );
};

export default Test;
