import React, { createContext, useContext, useEffect, useState } from "react";

import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import app from "../Firebase.config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { OpenAiApi } from "../utils/OpenAiApi";
import OpenAI from "openai";
import { ToastContainer, toast } from "react-toastify";

const auth = getAuth(app);

// Create the UserContext
export const AuthContext = createContext();

// Create a UserContextProvider component
export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState([]);
  const [JobName, setjobName] = useState("");

  const [projectId, setprojectId] = useState("");
  const [isProjectIdLoaded, setSetProjectIdloaded] = useState(true);
  const [isjobnameLoaded, setisjobnameLoaded] = useState(true);

  const [isJobDescSaved, setIsJobDescSaved] = useState(false);
  const [location, setLocation] = useState("");

  const [errors, setErrors] = useState({});
  const [candidateResponse, setCandidateResponse] = useState([]);
  const [sourcingResponse, setSourcingResponse] = useState([]);
  const [onlineResourcesResponse, setonlineResourcesResponse] = useState([]);
  const [techResponse, setTechResponse] = useState([]);
  const [searchLoading, setSearchLoading] = useState({
    candidateLoading: false,
    searchLoading: false,
    techLoading: false,
  });

  const [searchCandidateCounter, setSearchCandidateCounter] = useState({});
  const [searchSourcingCounter, setSearchSourcingCounter] = useState({});
  const [onlineResourcesCounter, setOnlineResourcesCounter] = useState({});
  const [searchTechCounter, setSearchTechCounter] = useState({});

  const [emailLoading, setEmailLoading] = useState("");
  const [emailResponse, setEmailResponse] = useState("");
  const [isEmailSaved, setIsEmailSaved] = useState(false);

  const [emailType, seEmailType] = useState("Cold email");
  const [decodedValue, setDecodedValue] = useState("");
  const [persona, setPersona] = useState("Lou Adler");
  const [Tonality, setTonality] = useState("professional and polite");

  const [disabledJobSelection, setDisabledJobSelection] = useState(false);

  const [title, setTile] = useState("");
  const [name, setname] = useState("");
  const [skills, setskills] = useState("");
  const [experience, setexperience] = useState("1 year");

  const [openWordsLimitPopup, setOpenWordsLimitPopup] = useState(false);
  const [OpenPopup, setOpenPopup] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [response, setResponse] = useState([]);
  const [firstName, setName] = useState("");
  const modelNames = [
    "gpt-4-1106-preview",
    "gpt-3.5-turbo",
    "gpt-4-0125-preview",
    "gpt-3.5-turbo-1106",
  ];
  // Job description State
  const [rerenderCount, setRerenderCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectStyle, setselectStyle] = useState("Professional and polite");
  const [currentModel, setCurrentModel] = useState(modelNames[0]);
  const [jobDescLoading, setJobDescLoading] = useState(false);
  const [botResponse, setBotResponse] = useState(""); // Change to a single response
  const [jobDesCharacterCount, setJobDescCharacterCount] = useState(0);
  const [selectpersona, setselectpersona] = useState("Lou Adler");

  const intakeModelNames = [
    // "gpt-4-1106-preview",
    // "gpt-3.5-turbo",
    // "gpt-4-0125-preview",
    "gpt-3.5-turbo-1106",
  ];

  // Intake state

  const [intakeLoading, setIntakeLoading] = useState(false);
  const [intakeBotResponses, setIntakeBotResponses] = useState([]);
  const [intakeBotResponses0, setIntakeBotResponses0] = useState("");
  const [intakeBotResponses1, setIntakeBotResponses1] = useState("");
  const [isIntakeSaved, setIsIntakeSaved] = useState(false);

  const [currentModelVarNbQ, setCurrentModelVarNbQ] = useState(4);
  const [currentIntakeIndex, setCurrentInatekIndex] = useState(0);
  const [currentIntakeModel, setCurrentIntakeModel] =
    useState("gpt-3.5-turbo-1106");

  const [user, setUser] = useState({});

  const [intakeQst, setIntakeQst] = useState(3);
  const [intakeQstType, setIntakeQstType] = useState("-");

  const [meetingAttendee, setMeetingAttendee] = useState("-");
  const [intakeRole, setintakeRole] = useState("-");

  const [refetch, setRefetch] = useState(false);

  const VarNbQ = ["6", "5", "4", "3"];
  const [currentIndexVarNbQ, setCurrentIndexVarNbQ] = useState(0);

  // Interview state

  const interviewModelNames = [
    // "gpt-3.5-turbo-1106",
    // "gpt-4-1106-preview",
    // "gpt-3.5-turbo-0613",
    // "gpt-4-0613",
    // "gpt-3.5-turbo-1106",
    // "gpt-3.5-turbo-0613",
    "gpt-4-1106-preview",
    // "gpt-3.5-turbo-1106",
    // "gpt-3.5-turbo-0613",
  ];

  const [interviewCurrentIndexVarNbQ, setInterviewCurrentIndexVarNbQ] =
    useState(0);
  const [interviewCurrentModelVarNbQ, setInterviewCurrentModelVarNbQ] =
    useState(3);

  const [interviewBotResponses, setInterviewBotResponses] = useState([]);
  const interviewVarNbQ = ["6", "5", "4", "3"];
  const [interviewBotResponses0, setInterviewBotResponses0] = useState("");

  const [interviewBotResponses1, setInterviewBotResponses1] = useState("");

  const [interviewCurrentIndex, setInterviewCurrentIndex] = useState(0);
  const [interviewCurrentModel, setInterviewCurrentModel] = useState(
    interviewModelNames[0]
  );

  const [numberOfQst, setNumberOfQst] = useState(3);
  const [interviewLoading, setInterviewLoading] = useState(false);
  const [isInterviewSaved, setIsInterviewSaved] = useState(false);
  const [roleLevel, setRoleLevel] = useState("-");
  const [questionsComplexity, setQuestionsComplexity] = useState("-");
  const [areaFocus, setAreaFocus] = useState("-");
  const [questionsType, setQuestionsType] = useState("-");
  const [interviewQualifications, setInterviewQualifications] = useState("");

  const openai = new OpenAI({
    // apiKey: "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",
    apiKey: process.env.REACT_APP_OPEN_AI_KEY,
    dangerouslyAllowBrowser: true,
  });

  const checkWordLimit = () => {
    if (
      (totalWords < 1000 && userInfo?.productName === "Free") ||
      (totalWords < 10000 && userInfo?.productName === "bronze") ||
      (totalWords < 50000 && userInfo?.productName === "Silver") ||
      (totalWords < 85000 && userInfo?.productName === "Gold")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const sendRes = async (prompt) => {
    const systemContent = prompt.description;
    const userContent = prompt.userInput;

    // const assistant = prompt.assistant;
    setJobDescLoading(true);
    try {
      const response = await openai.chat.completions.create({
        model: currentModel,
        messages: [
          { role: "system", content: systemContent },
          { role: "user", content: userContent },
        ],
        temperature: 1,
        max_tokens: 1000,
        top_p: 1,
        // frequency_penalty: 0,
        // presence_penalty: 0,
      });

      setJobDescLoading(false);
      return response.choices[0].message.content;
    } catch (error) {
      console.error("Error fetching response:", error);
      return "Error fetching response";
    }
  };

  const sendIntakeRes = async (prompts) => {
    const responses = [];
    setIntakeLoading(true);
    for (const prompt of prompts) {
      const systemContent = prompt.description;
      const userContent = prompt.userInput; // Use the userInput from the prompt object

      try {
        const response = await openai.chat.completions.create({
          model: currentIntakeModel,
          messages: [
            { role: "system", content: systemContent },
            { role: "user", content: userContent },
          ],
          temperature: 1,
          max_tokens: 1000,
          top_p: 1,
          //   frequency_penalty: 0,
          //   presence_penalty: 0,
        });
        const newIndex = (currentIntakeIndex + 1) % intakeModelNames.length;

        // Update the current model and index
        setCurrentIntakeModel(intakeModelNames[newIndex]);
        setCurrentInatekIndex(newIndex);

        // VarNbQ------------
        const VarNbQConst = (currentIndexVarNbQ + 1) % VarNbQ.length;

        // Update the current model and index
        setCurrentModelVarNbQ(VarNbQ[VarNbQConst]);
        setCurrentIndexVarNbQ(VarNbQConst);

        responses.push(response.choices[0].message.content);
      } catch (error) {
        console.error("Error fetching response:", error);
        responses.push("Error fetching response");
      }
    }
    setIntakeLoading(false);
    return responses;
  };

  const sendInterviewRes = async (prompts) => {
    const responses = [];
    // setLoadingb(true);
    // const loadingToastId = toast.info('Loading...', { autoClose: false, draggable: false }); // Display loading spinner toast

    for (const prompt of prompts) {
      // const systemContent = prompt.description;
      const userContent = prompt.userInput;
      const Assistant = prompt.Assistant;

      console.log({ Assistant }, { userContent });
      try {
        const response = await openai.chat.completions.create({
          model: interviewCurrentModel,
          messages: [
            // { role: 'system', content: systemContent },
            { role: "system", content: Assistant },
            { role: "user", content: userContent },
          ],
          temperature: 1,
          max_tokens: 1000,
          top_p: 1,
        });
        console.log("response", response.choices[0].message.content);
        responses.push(response.choices[0].message.content);
      } catch (error) {
        console.error("Error fetching response:", error);
        responses.push("Error fetching response");
        // toast.dismiss(loadingToastId);
        toast.error("Error fetching response", { autoClose: 5000 }); // Display error toast
      }
    }

    // toast.dismiss(loadingToastId); // Dismiss the loading spinner toast
    // toast.success("Responses received successfully!", { autoClose: 3000 }); // Display success toast

    const newIndex = (interviewCurrentIndex + 1) % interviewModelNames.length;

    // Update the current model and index
    setInterviewCurrentModel(interviewModelNames[newIndex]);
    setInterviewCurrentIndex(newIndex);

    return responses;
  };

  const handleRemoveUserInfo = (newData) => {
    setUserInfo([]);
    setjobName("");
    setprojectId("");
    setInterviewBotResponses0("");
    setInterviewBotResponses1("");
    setIntakeBotResponses0("");
    setIntakeBotResponses1("");
    setEmailResponse("");
    // setJobData("");
    setSearchCandidateCounter(0);
    setSearchSourcingCounter(0);
    setSearchTechCounter(0);
    setOnlineResourcesCounter(0);
    setWords(0);
    setCandidateResponse("");
    setSourcingResponse("");
    setTechResponse("");
    setBotResponse("");
    setOnlineResourcesCounter("");
  };
  const allowedEmails = [
    "nader.nasar@gmail.com",
    "nader@R3achout.AI",
    "Riadhossinr4@gmail.com",
    "mohammadjahid0007@gmail.com",
    "reachoutpro.ai@gmail.com",
    "Yordanoskmekonen@gmail.com",
  ];

  const handleCountWords = (response) => {
    const totalWordCount = [response].reduce((total, response) => {
      return (
        total +
        response.reduce((count, obj) => {
          return (
            count +
            Object.values(obj).reduce((wordCount, value) => {
              return wordCount + (value ? value.split(/\s+/).length : 0);
            }, 0)
          );
        }, 0)
      );
    }, 0);

    setCharacterCount(totalWordCount);
    handleWords(totalWordCount);
  };

  // You can initialize your global data here

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/user/accountType?email=${user?.email}`,
          {
            method: "GET", // This is optional since GET is the default method
            headers: {
              // If your API requires headers, they go here
              // 'Authorization': 'Bearer YOUR_TOKEN_HERE',
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        setUserInfo(data);
        setjobName(data.JobName);
        setprojectId(data.projectId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (user?.email) {
      fetchData();
    }
  }, [user, refetch, user?.email]);

  //  search counter useEffect

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/user/accountType?email=${user?.email}`,
          {
            method: "GET", // This is optional since GET is the default method
            headers: {
              // If your API requires headers, they go here
              // 'Authorization': 'Bearer YOUR_TOKEN_HERE',
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        const projectId = data.projectId;

        //  candidate counter

        setSearchCandidateCounter((prevSearchCandidateCounter) => {
          const searchCounter = data?.candidateCount?.find(
            (s) => s?.uniqueID === projectId
          );

          // Create a new object based on the previous state to ensure immutability
          const updatedSearchCandidateCounter = {
            ...prevSearchCandidateCounter,
          };

          // Update the specific projectId with the new count or default to 0
          updatedSearchCandidateCounter[projectId] = searchCounter?.count || 0;

          // Return the updated object as the new state
          return updatedSearchCandidateCounter;
        });

        //  sourcing counter

        setSearchSourcingCounter((prevSearchSourcingCounter) => {
          const sourcingCounter = data?.sourcingCount?.find(
            (s) => s?.uniqueID === projectId
          );

          // Similarly, create a new object based on the previous state
          const updatedSearchSourcingCounter = { ...prevSearchSourcingCounter };

          // Update the specific projectId with the new count or default to 0
          updatedSearchSourcingCounter[projectId] = sourcingCounter?.count || 0;

          // Return the updated object as the new state
          return updatedSearchSourcingCounter;
        });

        //  online resources counter
        setOnlineResourcesCounter((prevOnlineResourcesCounter) => {
          const sourcingCounter = data?.onlineResourcesCount?.find(
            (s) => s?.uniqueID === projectId
          );

          // Similarly, create a new object based on the previous state
          const updatedOnlineResourcesCounter = {
            ...prevOnlineResourcesCounter,
          };

          // Update the specific projectId with the new count or default to 0
          updatedOnlineResourcesCounter[projectId] =
            sourcingCounter?.count || 0;

          // Return the updated object as the new state
          return updatedOnlineResourcesCounter;
        });

        //  seachTech

        setSearchTechCounter((prevSearchTechCounter) => {
          const techCounter = data?.techCount?.find(
            (s) => s?.uniqueID === projectId
          );

          // Similarly, create a new object based on the previous state
          const updatedSearchTechCounter = { ...prevSearchTechCounter };

          // Update the specific projectId with the new count or default to 0
          updatedSearchTechCounter[projectId] = techCounter?.count || 0;

          // Return the updated object as the new state
          return updatedSearchTechCounter;
        });

        // setUserInfo(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (user?.email) {
      fetchData();
    }
  }, [user, projectId, user?.email]);

  // useEffect(() => {
  //   // Retrieve projectId from the database and set it in the state
  //   const storedProjectId = userInfo.projectId;
  //   const storeJobName = userInfo.JobName;

  //   if (storedProjectId?.length > 1) {
  //     setjobName(storeJobName);

  //     if (isProjectIdLoaded) {
  //       setprojectId(storedProjectId);

  //       if (projectId?.length > 2) {
  //         setSetProjectIdloaded(false);
  //       }
  //     }

  //     //
  //     if (isjobnameLoaded) {
  //       setjobName(storeJobName);

  //       if (JobName?.length > 2) {
  //         setisjobnameLoaded(false);
  //       }
  //     }
  //   }
  // }, [userInfo, isProjectIdLoaded, projectId, refetch]);

  //set latest project id for the user to the database
  // useEffect(() => {
  //   const updateUser = async () => {
  //     const userData = {
  //       projectId,
  //       JobName,
  //     };
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_SERVER_END_POINT}/update/projectId/${user?.email}`,
  //         {
  //           method: "PUT",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(userData),
  //         }
  //       );

  //       if (response.ok) {
  //       } else {
  //         console.error("Failed to update user.");
  //       }
  //     } catch (error) {
  //       console.error("An error occurred while updating user:", error);
  //     }
  //   };

  //   // Call the function when the component mounts
  //   updateUser();
  // }, [projectId, JobName]); // E

  //('projectId',projectId)
  // Update localStorage whenever projectId changes
  useEffect(() => {
    localStorage.setItem("projectId", projectId);
  }, [projectId]);

  const [FullJobData, setJobData] = useState("FullJobData");
  const [file, setfile] = useState("");
  const [action, setAction] = useState(0);
  const userId = user?.email; // Replace with the actual user ID

  const [totalWords, setUserData] = useState(0);

  const [words, setWords] = useState();

  const [daypass, setDaypass] = useState(0);

  const handleDaypass = (newData) => {
    const sum = daypass + newData;
    setDaypass(sum);
  };

  const [skip, setskip] = useState(false);
  ////('skip',skip)
  const handleskip = (newData) => {
    setskip(newData);
  };

  // You can provide functions to update the data as needed
  const projectUpdateId = (newData, jobName) => {
    setprojectId(newData);
    const updateUser = async () => {
      const userData = {
        JobName: jobName,
        projectId: newData,
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/update/projectId/${user?.email}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
          }
        );

        if (response.ok) {
        } else {
          console.error("Failed to update user.");
        }
      } catch (error) {
        console.error("An error occurred while updating user:", error);
      }
    };
    if (user?.email) {
      updateUser();
    }
    // Call the function when the component mounts
  };

  const handleAction = (newData) => {
    const sum = action + newData;
    setAction(sum);
  };

  //("userInfo", userInfo);
  // take user info
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/user/accountType?email=${user?.email}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        ////({ data })

        setUserInfo(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (user?.email) {
      fetchData();
    }
  }, [user?.email, action, daypass, words]);

  const handleuserInfo = (newData) => {
    setUserInfo(newData);
  };

  const handleJobData = (newData) => {
    setJobData(newData);
  };

  const handlefile = (newData) => {
    setfile(newData);
  };

  // job recall for save
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);

  const handleUpdateRequest = () => {
    setIsUpdateRequest(true);
  };
  const handleUpdateRequestReset = () => {
    setIsUpdateRequest(false);
  };

  const [loading, setLoading] = useState(true);

  //registration pop
  const providerLogin = (provider) => {
    setUser(user);
    return signInWithPopup(auth, provider);
  };
  // sing in button
  const creatUser = async (email, password) => {
    try {
      // Assuming setUser and setLoading are defined elsewhere
      setUser(user);
      setLoading(true);

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const actionCodeSettings = {
        url: `${process.env.REACT_APP_FIREBASE_URL}/login`,
      };

      await sendEmailVerification(auth.currentUser, actionCodeSettings);

      // This alert should trigger after email verification is sent
      alert(
        "An email verification has been sent to your email address. Please verify it and log in again"
      );

      // Optionally, you can return the userCredential or any other data you need
      return userCredential;
    } catch (error) {
      // Handle any errors that might occur during user creation or email verification
      console.error("User creation or email verification failed", error);
      throw error; // Propagate the error up the chain
    }
  };

  //login - create new  user
  const creatNewUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  //login button
  const login = (email, password) => {
    setUser(user);
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password);
  };

  const reset = async (email) => {
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_FIREBASE_URL}/login`,

      // expirationTime: 3600,
    };
    try {
      await sendPasswordResetEmail(auth, email, actionCodeSettings);

      // Now, make the API call
      const apiEndpoint = `${process.env.REACT_APP_SERVER_END_POINT}/api/email/resetNoticefication`;
    } catch (err) {
      console.error({ err });
    }
  };

  //send verification
  const verification = (email) => {
    return sendEmailVerification(email);
  };

  //logout

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);

      setLoading(false);

      return () => {
        unsubscribe();
      };
    });
  }, []);

  // const [count, setCount] = useState(0);
  // //('count',count)
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setCount((prevCount) => prevCount + 1);
  //     }, 1000); // Increment every second (1000 milliseconds)

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, []);

  //console'words',words)
  //console'userData',totalWords)

  // Second useEffect to handle updating user data
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  // useEffect(() => {
  const updateUser = async (wordss) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_END_POINT}/update/user`,

        {
          email: user?.email,
          words: wordss,
        }
      );

      // Update the words in your component's state
      // setnews(response.data.words);

      setUpdateSuccessful(true);
    } catch (error) {
      console.error("Error updating user:", error);
      setUpdateSuccessful(false);
    }
  };

  // Call updateUser when the component mounts or when user or words change
  // if (user?.email && words) {
  // updateUser();
  // }
  // }, [words]);

  useEffect(() => {
    if (userId) {
      // / Make a GET request to the server
      fetch(`${process.env.REACT_APP_SERVER_END_POINT}/get/user/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.user) {
            setUserData(data.user.words);
            setUpdateSuccessful(false);
          } else {
            console.error("User not found:", data.message);
          }
        })
        .catch((error) => {
          console.error("Error retrieving user:", error);
        });
    }
  }, [user?.email, userId, action, updateSuccessful]);

  // Function to update words and save it to localStorage
  const handleWords = (newData) => {
    // const newWords = parseInt(words + newData);
    setWords(newData);
    updateUser(newData);
    // Save the updated 'words' to localStorage
  };

  const JobNameUpdate = (newData) => {
    setjobName(newData);
  };
  const singleObject = FullJobData[0];

  //  candidate Insight
  const handleCandidateInsight = async (projectId) => {
    setErrors({});
    // setRefetch((prevref) => !prevref);

    setSearchLoading((prevLoading) => {
      return { ...prevLoading, candidateLoading: true };
    });
    setDisabledJobSelection(true);

    const allowedUser = allowedEmails.includes(userInfo?.email);

    if (checkWordLimit()) {
      if (
        (userInfo?.productName === "Free" &&
          searchCandidateCounter[projectId] <= 0) ||
        (userInfo?.productName === "bronze" &&
          searchCandidateCounter[projectId] <= 1) ||
        (userInfo?.productName === "Silver" &&
          searchCandidateCounter[projectId] <= 1) ||
        (userInfo?.productName === "Gold" &&
          searchCandidateCounter[projectId] <= 2) ||
        allowedUser ||
        // paidUsers ||
        !searchCandidateCounter[projectId]
      ) {
        try {
          const jobData = {
            savedJobTitle: singleObject.name || "Default Title",
            savedJobDetails: singleObject.jd || "Default description", // Adjust this property as needed
          };

          const res = await axios.post(
            `${process.env.REACT_APP_SERVER_END_POINT}/search/candidate`,

            {
              jobData,
            }
          );
          const response = res.data;

          handleCountWords(response);

          if (response && !allowedUser) {
            setSearchCandidateCounter((prevCounter) => {
              const updatedCounter = { ...prevCounter };

              updatedCounter[projectId] = (updatedCounter[projectId] || 0) + 1;

              return updatedCounter;
            });

            await axios.put(
              `${process.env.REACT_APP_SERVER_END_POINT}/update-candidate/${userInfo._id}`,
              {
                uniqueID: singleObject.uniqueID,
                searchCandidateCounter,
              }
            );
          }

          setCandidateResponse(response);

          const ress = await axios.post(
            `${process.env.REACT_APP_SERVER_END_POINT}/search/saveResponses/${projectId}`,
            {
              candidateResponse: response,
            }
          );

          localStorage.setItem("searchtab", JSON.stringify(response));

          setSearchLoading((prevLoading) => {
            return { ...prevLoading, candidateLoading: false };
          });
          setDisabledJobSelection(false);
        } catch (error) {
          setSearchLoading((prevLoading) => {
            return { ...prevLoading, candidateLoading: false };
          });
          setDisabledJobSelection(false);

          let errorMessage = "An unexpected error occurred. Please try again.";
          if (error.response && error.response.status === 408) {
            errorMessage = "The request timed out. Please try again later.";
          } else if (error.message && error.message.includes("Network Error")) {
            errorMessage =
              "Unable to connect. Please check your internet connection and try again.";
          }

          setErrors((prevErrors) => {
            return { ...prevErrors, candidateSearchError: errorMessage };
          });
          console.error("Error fetching data:", error);
        }
      } else {
        setSearchLoading((prevLoading) => {
          return { ...prevLoading, candidateLoading: false };
        });
        setDisabledJobSelection(false);

        setOpenPopup(true);
      }
    } else {
      setSearchLoading((prevLoading) => {
        return { ...prevLoading, candidateLoading: false };
      });
      setDisabledJobSelection(false);

      setOpenWordsLimitPopup(true);
    }
  };

  // Tech
  const handleTechInsight = async (projectId) => {
    setErrors({});
    setSearchLoading((prevLoading) => {
      return { ...prevLoading, techLoading: true };
    });
    setDisabledJobSelection(true);

    const allowedUser = allowedEmails.includes(userInfo?.email);

    if (checkWordLimit()) {
      if (
        (userInfo?.productName === "Free" && !searchTechCounter[projectId]) ||
        (userInfo?.productName === "bronze" &&
          searchTechCounter[projectId] <= 1) ||
        (userInfo?.productName === "Silver" &&
          searchTechCounter[projectId] <= 1) ||
        (userInfo?.productName === "Gold" &&
          searchTechCounter[projectId] <= 2) ||
        allowedUser ||
        // paidUsers ||
        !searchTechCounter[projectId]
      ) {
        try {
          const jobData = {
            savedJobTitle: singleObject.name || "Default Title",
            savedJobDetails: singleObject.jd || "Default description", // Adjust as needed
          };

          // Perform the POST request with a timeout
          const res = await axios.post(
            `${process.env.REACT_APP_SERVER_END_POINT}/search/technology`,
            { jobData }
          );
          const response = res.data;

          handleCountWords(response);

          setSearchTechCounter((prevCounter) => {
            const updatedCounter = { ...prevCounter };

            updatedCounter[projectId] = (updatedCounter[projectId] || 0) + 1;

            return updatedCounter;
          });

          if (response && !allowedUser) {
            await axios.put(
              `${process.env.REACT_APP_SERVER_END_POINT}/update-tech/${userInfo._id}`,
              { uniqueID: singleObject.uniqueID }
            );
          }

          setResponse((prevResponse) => [...prevResponse, ...response]);

          setTechResponse(response);

          const ress = await axios.post(
            `${process.env.REACT_APP_SERVER_END_POINT}/search/saveResponses/${projectId}`,
            {
              techResponse: response,
            }
          );
          localStorage.setItem("searchtab", JSON.stringify(response));
        } catch (error) {
          console.error("Error fetching data:", error);
          let errorMessage = "An unexpected error occurred. Please try again.";

          if (
            error.code === "ECONNABORTED" &&
            error.message.includes("timeout")
          ) {
            errorMessage = "The request timed out. Please try again later.";
          } else if (error.response) {
            // Handle specific API response errors (like 404, 500, etc.)
            errorMessage = `Error: ${error.response.status} ${error.response.statusText}`;
          }

          setErrors((prevErrors) => {
            return { ...prevErrors, techSearchError: errorMessage };
          });
        } finally {
          // Always reset the loading state, regardless of request outcome
          setSearchLoading((prevLoading) => {
            return { ...prevLoading, techLoading: false };
          });
          setDisabledJobSelection(false);
        }
      } else {
        setSearchLoading((prevLoading) => {
          return { ...prevLoading, techLoading: false };
        });
        setDisabledJobSelection(false);

        setOpenPopup(true);
      }
    } else {
      setSearchLoading((prevLoading) => {
        return { ...prevLoading, techLoading: false };
      });
      setDisabledJobSelection(false);

      setOpenWordsLimitPopup(true);
    }
  };

  // Sourcing
  const handleSourcing = async (projectId) => {
    setErrors({});
    // setRefetch((prevref) => !prevref);

    setSearchLoading((prevLoading) => {
      return { ...prevLoading, sourceLoading: true };
    });
    setDisabledJobSelection(true);

    const allowedUser = allowedEmails.includes(userInfo?.email);

    if (checkWordLimit()) {
      if (
        (userInfo?.productName === "Free" &&
          !searchSourcingCounter[projectId]) ||
        (userInfo?.productName === "bronze" &&
          searchSourcingCounter[projectId] <= 1) ||
        (userInfo?.productName === "Silver" &&
          searchSourcingCounter[projectId] <= 1) ||
        (userInfo?.productName === "Gold" &&
          searchSourcingCounter[projectId] <= 2) ||
        allowedUser ||
        // paidUsers ||
        !searchSourcingCounter[projectId]
      ) {
        try {
          const jobData = {
            savedJobTitle: singleObject.name || "Default Title",
            savedJobDetails: singleObject.jd || "Default description", // Adjust this property as needed
          };

          // Make the POST request with a timeout
          const res = await axios.post(
            `${process.env.REACT_APP_SERVER_END_POINT}/search/sourcing`,
            { jobData }
          );
          const response = res.data;

          handleCountWords(response);

          if (response && !allowedUser) {
            setSearchSourcingCounter((prevCounter) => {
              const updatedCounter = { ...prevCounter };

              updatedCounter[projectId] = (updatedCounter[projectId] || 0) + 1;

              return updatedCounter;
            });

            await axios.put(
              `${process.env.REACT_APP_SERVER_END_POINT}/update-sourcing/${userInfo._id}`,
              { uniqueID: singleObject.uniqueID }
            );
          }

          setSourcingResponse(response);

          const ress = await axios.post(
            `${process.env.REACT_APP_SERVER_END_POINT}/search/saveResponses/${projectId}`,
            {
              sourcingResponse: response,
            }
          );

          localStorage.setItem("searchtab", JSON.stringify(response));
        } catch (error) {
          console.error("Error fetching data:", error);
          // Specific error handling for timeout
          let errorMessage = "An unexpected error occurred. Please try again.";
          if (
            error.code === "ECONNABORTED" &&
            error.message.includes("timeout")
          ) {
            errorMessage = "The request timed out. Please try again later.";
          } else if (error.response) {
            // Handle other API response errors (like 404, 500, etc.)
            errorMessage = `Error: ${error.response.status} ${error.response.statusText}`;
          }

          setErrors((prevErrors) => {
            return { ...prevErrors, sourcingSearchError: errorMessage };
          });
        } finally {
          // Always turn off loading, regardless of request success or failure
          setSearchLoading((prevLoading) => {
            return { ...prevLoading, sourceLoading: false };
          });
          setDisabledJobSelection(false);
        }
      } else {
        setSearchLoading((prevLoading) => {
          return { ...prevLoading, sourceLoading: false };
        });
        setDisabledJobSelection(false);

        setOpenPopup(true);
      }
    } else {
      setSearchLoading((prevLoading) => {
        return { ...prevLoading, sourceLoading: false };
      });
      setDisabledJobSelection(false);

      setOpenWordsLimitPopup(true);
    }
  };

  //  Online resources
  const handleOnlineResources = async (location, projectId) => {
    setErrors({});
    // setRefetch((prevref) => !prevref);
    setDisabledJobSelection(true);

    setSearchLoading((prevLoading) => {
      return { ...prevLoading, onlineResourcesLoading: true };
    });

    if (location) {
      let submit = { location };

      fetch(`${process.env.REACT_APP_SERVER_END_POINT}/updatejd/${projectId}`, {
        method: "PUT",

        headers: {
          "content-type": "application/json",
        },

        body: JSON.stringify(submit),
      });
    }

    const allowedUser = allowedEmails.includes(userInfo?.email);

    const haveAccess = userInfo?.sourcingCount?.find(
      (s) => s?.uniqueID === singleObject?.uniqueID
    );

    const paidUsers = userInfo?.productName === "Gold";

    if (checkWordLimit()) {
      if (
        (userInfo?.productName === "Free" &&
          !onlineResourcesCounter[projectId]) ||
        (userInfo?.productName === "bronze" &&
          onlineResourcesCounter[projectId] <= 1) ||
        (userInfo?.productName === "Silver" &&
          onlineResourcesCounter[projectId] <= 1) ||
        (userInfo?.productName === "Gold" &&
          onlineResourcesCounter[projectId] <= 2) ||
        allowedUser ||
        // paidUsers ||
        !onlineResourcesCounter[projectId]
      ) {
        try {
          const jobData = {
            savedJobTitle: singleObject.name || "Default Title",
            savedJobDetails: singleObject.jd || "Default description", // Adjust this property as needed
            location,
          };
          // Make the POST request with a timeout
          const res = await axios.post(
            `${process.env.REACT_APP_SERVER_END_POINT}/search/onlineResources`,
            { jobData }
          );
          const response = res.data;

          handleCountWords(response);

          if (response && !allowedUser) {
            setOnlineResourcesCounter((prevCounter) => {
              const updatedCounter = { ...prevCounter };

              updatedCounter[projectId] = (updatedCounter[projectId] || 0) + 1;

              return updatedCounter;
            });

            await axios.put(
              `${process.env.REACT_APP_SERVER_END_POINT}/update-onlineResources/${userInfo._id}`,
              { uniqueID: singleObject.uniqueID }
            );
          }
          setonlineResourcesResponse(response);

          const ress = await axios.post(
            // `http://localhost:5000/search/saveResponses/${projectId}`,
            `${process.env.REACT_APP_SERVER_END_POINT}/search/saveResponses/${projectId}`,
            {
              onlineResourcesResponse: response,
            }
          );
          localStorage.setItem("searchtab", JSON.stringify(response));
        } catch (error) {
          console.error("Error fetching data:", error);
          // Specific error handling for timeout
          let errorMessage = "An unexpected error occurred. Please try again.";
          if (
            error.code === "ECONNABORTED" &&
            error.message.includes("timeout")
          ) {
            errorMessage = "The request timed out. Please try again later.";
          } else if (error.response) {
            // Handle other API response errors (like 404, 500, etc.)
            errorMessage = `Error: ${error.response.status} ${error.response.statusText}`;
          }

          setErrors((prevErrors) => {
            return { ...prevErrors, onlineResourcesError: errorMessage };
          });
        } finally {
          // Always turn off loading, regardless of request success or failure
          setSearchLoading((prevLoading) => {
            return { ...prevLoading, onlineResourcesLoading: false };
          });

          setDisabledJobSelection(false);
        }
      } else {
        setSearchLoading((prevLoading) => {
          return { ...prevLoading, onlineResourcesLoading: false };
        });
        setDisabledJobSelection(false);

        setOpenPopup(true);
      }
    } else {
      setSearchLoading((prevLoading) => {
        return { ...prevLoading, sourceLoading: false };
      });
      setDisabledJobSelection(false);

      setOpenWordsLimitPopup(true);
    }
  };

  // Email crafting
  const handleEmailClick = async (projectId) => {
    //('check')
    setErrors({});

    if (singleObject == undefined) {
      alert("Please select job");
      return;
    }
    setEmailLoading(true);
    setDisabledJobSelection(true);

    if (checkWordLimit()) {
      try {
        const jobData = {
          savedJobTitle: singleObject.name || "Default Title",
          savedJobDetails: singleObject.jd || "Default description", // Adjust this property as needed
          emailType,
          persona,
          Tonality,
          title,
          name,
          firstName,
          skills,
          experience,
          company: userInfo.company,
        };

        const response = await OpenAiApi.reachoutTab(jobData);

        setEmailLoading(false);
        setDisabledJobSelection(false);

        //word count
        const responseWordCount = response.split(/\s+/).length;

        const templimit = userInfo?.limitWords;
        const temUsedWord = totalWords;

        // 2nd condition for the remaining word after respone
        const totalWordAfterResponse =
          parseInt(responseWordCount) + parseInt(temUsedWord);

        const difference = parseInt(templimit) - parseInt(temUsedWord);

        // Check if the total words exceed the limit
        if (parseInt(totalWordAfterResponse) > parseInt(templimit)) {
          const words = response.split(" ");

          // Take the first 10 words
          const remainsResponse = words.slice(0, difference);

          // Join the words back into a string
          const truncatedResponse = remainsResponse.join(" ");

          setOpenPopup(true);

          setEmailResponse(truncatedResponse);

          setCharacterCount(truncatedResponse.split(/\s+/).length);
          handleWords(truncatedResponse.split(/\s+/).length);
          const apiUrl = `${process.env.REACT_APP_SERVER_END_POINT}/update/email/inputBox/${projectId}`;

          // Your job data, including botResponses
          const jobData = {
            response: truncatedResponse,
          };

          // Fetch options
          const requestOptions = {
            method: "PUT", // Change this to PUT
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(jobData),
          };

          // Make the PUT request using fetch
          fetch(apiUrl, requestOptions).then(() => {
            // setIsEmailSaved((isEmailSaved) => !isEmailSaved);
          });
        } else {
          localStorage.setItem("emailreachout", JSON.stringify(response));

          const apiUrl = `${process.env.REACT_APP_SERVER_END_POINT}/update/email/inputBox/${projectId}`;

          // Your job data, including botResponses
          const jobData = {
            response,
            skills,
            Tonality,
            persona,
            name,
            emailType,
          };

          // Fetch options
          const requestOptions = {
            method: "PUT", // Change this to PUT
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(jobData),
          };

          // Make the PUT request using fetch
          fetch(apiUrl, requestOptions).then(() => {
            setIsEmailSaved((isEmailSaved) => !isEmailSaved);
          });

          setEmailResponse(response);
          if (response.length > 1) {
            setCharacterCount(response.split(/\s+/).length);
            handleWords(response.split(/\s+/).length);
          }
        }

        //word count
      } catch (error) {
        console.error("Error fetching data:", error);
        setEmailLoading(false);
        setDisabledJobSelection(false);
      }
    } else {
      setOpenPopup(true);
      setEmailLoading(false);
      setDisabledJobSelection(false);
    }
  };

  //  Job description rewriting

  const handleJobDescClick = async (projectId) => {
    setRerenderCount((prevCount) => prevCount + 1);
    // disabled job selection when loading
    setDisabledJobSelection(true);
    setJobDescLoading(true);
    setErrors({});
    const apiUrl = `${process.env.REACT_APP_SERVER_END_POINT}/update/jd/inputBox/${projectId}`;

    if (checkWordLimit()) {
      if (singleObject.jd == undefined) {
        // setjdEmptyPopup(true);
        try {
          const newIndex = (currentIndex + 1) % modelNames.length;

          // Update the current model and index
          setCurrentModel(modelNames[newIndex]);
          setCurrentIndex(newIndex);

          const jobData = {
            savedJobTitle: singleObject.name || "Default Title",
            savedJobDetails: singleObject.jd || "Default description", // Adjust this property as needed
          };
          //('selectStyle  eeeeeeeeeeeeeeeeeee', selectStyle)
          //('selectpersona  ppppppppppppppppppppp', selectpersona)
          const prompt = {
            description: `
            Assist with rewriting a job description.		
				`,
            userInput: `
            "The goal is to rewrite a job description, putting emphasis on what's in it for them.
            Begin with a quick introduction and immediately follow by describing the benefits.
            Use a ${
              selectStyle === "-" ? "Professional" : selectStyle
            } tonality when rewriting the job description. Present the content in a job description format.
            Use bolds, headings and exclamation marks when suitable and turn the job description into a marketing campaign.
            Take on the persona of ${
              selectpersona === "-" ? "Lou Adler" : selectpersona
            } to attract potential candidates. Do not mention your persona nor that you are a recruiter.

            Job description:
            ${jobData.savedJobDetails} ||  ${jobData.savedJobTitle}	
	
					`,
          };
          const response = await sendRes(prompt);

          const responseWordCount = response.split(/\s+/).length;

          const templimit = userInfo?.limitWords;
          const temUsedWord = totalWords;

          // 2nd condition for the remaining word after respone
          const totalWordAfterResponse =
            parseInt(responseWordCount) + parseInt(temUsedWord);

          const difference = parseInt(templimit) - parseInt(temUsedWord);

          // Check if the total words exceed the limit
          if (parseInt(totalWordAfterResponse) > parseInt(templimit)) {
            const words = response.split(" ");

            // Take the first 10 words
            const remainsResponse = words.slice(0, difference);

            // Join the words back into a string
            const truncatedResponse = remainsResponse.join(" ");

            setOpenPopup(true);

            setBotResponse(truncatedResponse);
            const jobData = {
              response: truncatedResponse,
              selectedPersona: selectpersona,
              selectedStyle: selectStyle,
            };
            // Fetch options
            const requestOptions = {
              method: "PUT", // Change this to PUT
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(jobData),
            };

            // Make the PUT request using fetch
            fetch(apiUrl, requestOptions).then(() => {
              // setIsJobDescSaved((isJobDescSaved) => !isJobDescSaved);
            });

            if (response.length > 1) {
              setCharacterCount(responseWordCount);
              handleWords(responseWordCount);
            }
          } else {
            setBotResponse(response);

            if (response.length > 1) {
              setCharacterCount(responseWordCount);

              handleWords(responseWordCount);
            }
            const jobData = {
              response: response,
              selectedPersona: selectpersona,
              selectedStyle: selectStyle,
            };
            // Fetch options
            const requestOptions = {
              method: "PUT", // Change this to PUT
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(jobData),
            };

            // Make the PUT request using fetch
            fetch(apiUrl, requestOptions).then(() => {
              setIsJobDescSaved((isJobDescSaved) => !isJobDescSaved);
            });
          }
          setJobDescLoading(false);
          setDisabledJobSelection(false);
          // 2nd condition for the remaining word after respone
        } catch (error) {
          setJobDescLoading(false);
          setDisabledJobSelection(false);

          setErrors({ jobDescErrors: "Error handling user input" });
          console.error("Error handling user input:", error);
        }
      } else {
        try {
          const newIndex = (currentIndex + 1) % modelNames.length;

          // Update the current model and index
          setCurrentModel(modelNames[newIndex]);
          setCurrentIndex(newIndex);

          const jobData = {
            savedJobTitle: singleObject.name || "Default Title",
            savedJobDetails: singleObject.jd || "Default description", // Adjust this property as needed
          };

          const prompt = {
            description: `
				Assist with rewriting a job description.
	Use bolds, headings and exclamation marks when suitable and turn the job description into a marketing campaign.
	
				`,
            userInput: `The goal is to rewrite a job description, putting emphasis on what's in it for them.
					Begin with a quick introduction and immediately follow by describing the benefits.
					Do not mention your persona nor that you are a recruiter.
					Job description:
					${jobData.savedJobDetails} || title-->  ${jobData.savedJobTitle}
	
	
					`,
            assistant: `Take on the persona of ${
              selectpersona === "-" ? "Lou Adler" : selectpersona
            } to attract potential candidates.
             Use a good tonality when rewriting the job description. 
             Present the content in a job description format.
	
	
					`,
          };

          const response = await sendRes(prompt);

          const responseWordCount = response.split(/\s+/).length;

          const templimit = userInfo?.limitWords;
          const temUsedWord = totalWords;

          // 2nd condition for the remaining word after respone
          const totalWordAfterResponse =
            parseInt(responseWordCount) + parseInt(temUsedWord);

          const difference = parseInt(templimit) - parseInt(temUsedWord);

          // Check if the total words exceed the limit
          if (parseInt(totalWordAfterResponse) > parseInt(templimit)) {
            const words = response.split(" ");

            // Take the first 10 words
            const remainsResponse = words.slice(0, difference);

            // Join the words back into a string
            const truncatedResponse = remainsResponse.join(" ");

            setOpenPopup(true);

            setBotResponse(truncatedResponse);

            if (response.length > 1) {
              setCharacterCount(responseWordCount);

              handleWords(responseWordCount);
            }
            // Your job data, including botResponses
            const jobData = {
              response: truncatedResponse,
              selectedPersona: selectpersona,
              selectedStyle: selectStyle,
            };

            // Fetch options
            const requestOptions = {
              method: "PUT", // Change this to PUT
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(jobData),
            };

            // Make the PUT request using fetch
            fetch(apiUrl, requestOptions);
          } else {
            setBotResponse(response);
            if (response.length > 1) {
              setCharacterCount(responseWordCount);

              handleWords(responseWordCount);
            }
            const jobData = {
              response: response,
              selectedPersona: selectpersona,
              selectedStyle: selectStyle,
            };

            // Fetch options
            const requestOptions = {
              method: "PUT", // Change this to PUT
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(jobData),
            };

            // Make the PUT request using fetch
            fetch(apiUrl, requestOptions).then(() => {
              setIsJobDescSaved((isJobDescSaved) => !isJobDescSaved);
            });
          }
          setJobDescLoading(false);
          setDisabledJobSelection(false);
        } catch (error) {
          setJobDescLoading(false);
          setDisabledJobSelection(false);

          setErrors({ jobDescErrors: "Error handling user input" });

          console.error("Error handling user input:", error);
        }
      }
    } else {
      setJobDescLoading(false);
      setDisabledJobSelection(false);

      setOpenPopup(true);
    }
  };

  // Intake
  const handleIntakeClick = async (projectId) => {
    setIntakeLoading(true);
    setDisabledJobSelection(true);

    setErrors({});

    if (checkWordLimit()) {
      // if (singleObject.jd == undefined) {
      //   setjdEmptyPopup(true);
      // }

      try {
        const jobData = {
          savedJobTitle: singleObject.name || "Default Title",
          savedJobDetails: singleObject.jd || "Default description", // Adjust this property as needed
          intakeQst,
          meetingAttendee,
          intakeRole,
          intakeQstType,
          userInfo,
        };
        const responses = await OpenAiApi.intakeTab(jobData);
        const apiUrl = `${process.env.REACT_APP_SERVER_END_POINT}/update/intake/inputBox/${projectId}`;

        // Your job data, including intakeBotResponses
        const jobDataa = {
          intakeBotResponses0: responses[0],
          intakeBotResponses1: responses[1],
          intakeQst,
          meetingAttendee,
          intakeRole,
          intakeQstType,
        };

        const requestOptions = {
          method: "PUT", // Change this to PUT
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jobDataa),
        };

        // Make the PUT request using fetch
        fetch(apiUrl, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            ////console'Data from server:', data);
            // Handle the response data as needed
            // setIsIntakeSaved((intakeSaved) => !intakeSaved);
          })
          .catch((error) => {
            console.error("Error:", error);
            // Handle errors
          });

        // setIntakeBotResponses(responses);

        setIntakeBotResponses0(responses[0]);
        setIntakeBotResponses1(responses[1]);
        const countWords = (str) => {
          const words = str.split(/\s+/);
          return words.length;
        };

        // Calculate total word count from intakeBotResponses array
        const totalWordCount = responses.reduce((count, paragraph) => {
          return count + countWords(paragraph);
        }, 0);
        // Update the state with the total word count
        setCharacterCount(totalWordCount);

        handleWords(totalWordCount);
        setIntakeLoading(false);
        setDisabledJobSelection(false);
      } catch (error) {
        setIntakeLoading(false);
        setDisabledJobSelection(false);

        setErrors({ errorsIntake: "There was an error , please try again" });

        console.error("Error handling user input:", error);
      }
    } else {
      setIntakeLoading(false);
      setDisabledJobSelection(false);

      setOpenPopup(true);
    }
  };

  //  Interview
  const handleInterviewClick = async (projectId) => {
    setInterviewLoading(true);
    setDisabledJobSelection(true);
    setErrors({});

    if (checkWordLimit()) {
      // if (singleObject.jd == undefined) {
      //   setjdEmptyPopup(true);
      // }

      try {
        // VarNbQ------------

        const jobData = {
          savedJobTitle: singleObject.name || "Default Title",
          savedJobDetails: singleObject.jd || "Default description", // Adjust this property as needed
        };

        console.log(
          interviewQualifications,
          questionsType,
          questionsComplexity,
          areaFocus,
          roleLevel
        );

        const prompts = [
          {
            Assistant: `
           You are an expert in recruitment. Your goal is to assist the user during an interview with a candidate assessment and to ensure we are talking to the best-fit candidate for the job.

## 
Type of questions
Behavioral Questions: These questions ask candidates to describe past behavior as an indicator of their future behavior in similar situations.
Situational Questions: Candidates are presented with a hypothetical situation related to the job and asked how they would handle it.
Technical Questions: Specific to the job’s technical requirements, these questions test the candidate’s technical knowledge and problem-solving abilities.
Case Study Questions: These involve giving the candidate a business problem, project brief, or strategic challenge and asking them to develop a response or solution.
Motivational Questions: Designed to understand what drives the candidate, their career aspirations, and how these align with the company’s goals.
Competency-Based Questions: These focus on specific competencies the job requirements, asking candidates to demonstrate how they've exhibited these traits or skills in past roles.		`,
            userInput:
              userInfo.productName !== "Gold" &&
              userInfo.productName !== "bronze"
                ? `Analyze the job description and list  ${numberOfQst} assessment questions.
             Help with qualifying a candidate for the following job. Use open-ended questions
              and provide insight to the interviewer on what to listen for.
               Do not provide an introduction, only list the questions and answers.  
               Job description: ${jobData.savedJobDetails} || ${jobData.savedJobTitle}
            `
                : !interviewQualifications
                ? `Analyze the job description and list ${numberOfQst} ${
                    questionsType === "-" ? "Behavioral" : questionsType
                  } questions that are ${
                    questionsComplexity === "-" ? "Basic" : questionsComplexity
                  } in nature,
              focusing on ${
                areaFocus === "-" ? "Work ethics" : areaFocus
              }. These questions should aim to assess key qualifications 
              for a ${
                roleLevel === "-" ? "Entry-level" : roleLevel
              } role. Use open-ended questions and provide insight
               to the interviewer on what to listen for.Use 
closed-ended questions if this can help determine their expertise. 
               Do not provide an introduction 
               to your response, only list the questions and answers.
                Craft your response to bold the question and the heading for what to listen for. 
.
              Job description: ${jobData.savedJobDetails} || ${
                    jobData.savedJobTitle
                  }
            `
                : `Analyze the job description and list ${numberOfQst} ${
                    questionsType === "-" ? "Behavioral" : questionsType
                  } questions that are ${
                    questionsComplexity === "-" ? "Basic" : questionsComplexity
                  } in nature,
              focusing on ${areaFocus === "-" ? "Work ethics" : areaFocus}. 
              These questions should aim to assess key qualifications such as ${interviewQualifications} 
              for a ${
                roleLevel === "-" ? "Entry-level" : roleLevel
              } role. Use open-ended questions and provide insight
               to the interviewer on what to listen for.
               Use 
closed-ended questions if this can help determine their expertise. 
               Do not provide an introduction to your response,
                only list the questions and answers.
                 Craft your response to bold the question and the heading for what to listen for. 
.
              Job description: ${jobData.savedJobDetails} || ${
                    jobData.savedJobTitle
                  }
            `,
          },

          //           {
          //             userInput: `
          // 						You are an expert in recruitment.
          //              Your goal is to assist the user during an interview with a candidate assessment
          //               and to ensure we are talking to the best-fit candidate for the job.

          // 					`,
          //             Assistant: !interviewQualifications
          //               ? `
          //             Analyze the job description and list ${numberOfQst} Technical questions that are ${questionsComplexity} in nature,
          //             focusing on ${areaFocus}. These questions should aim to assess key qualifications for a ${roleLevel} role.
          //             Use open-ended questions and provide insight to the interviewer on what to listen for.
          //             Do not provide an introduction to your response, only list the questions and answers.
          //             Craft your response to bold the question and the heading for what to listen for.
          // .
          //             Job description: ${jobData.savedJobDetails} || ${jobData.savedJobTitle}
          //             `
          //               : `
          //             Analyze the job description and list ${numberOfQst} Technical questions that are ${questionsComplexity} in nature,
          //             focusing on ${areaFocus}. These questions should aim to assess key qualifications such as ${interviewQualifications} for a ${roleLevel} role.
          //              Use open-ended questions and provide insight to the interviewer on what to listen for.Do not provide an introduction to your response,
          //              only list the questions and answers. Craft your response to bold the question and the heading for what to listen for.
          // .
          //             Job description: ${jobData.savedJobDetails} || ${jobData.savedJobTitle}
          //           `,
          //           },
        ];
        console.log({ prompts });

        // VarNbQ------------
        const VarNbQConst =
          (interviewCurrentIndexVarNbQ + 1) % interviewVarNbQ.length;

        // Update the current model and index
        setInterviewCurrentModelVarNbQ(interviewVarNbQ[VarNbQConst]);
        setInterviewCurrentIndexVarNbQ(VarNbQConst);

        const apiUrl = `${process.env.REACT_APP_SERVER_END_POINT}/update/interview/inputBox/${projectId}`;

        // Make the PUT request using fetch

        const responses = await sendInterviewRes(prompts);

        setInterviewBotResponses0(responses[0]);
        // setInterviewBotResponses1(responses[0]);

        setInterviewLoading(false);
        // Your job data, including interviewBotResponses
        const jobDataa = {
          interviewBotResponses0: responses[0],
          // interviewBotResponses1: responses[1],
          numberOfQst,
          questionsType,
          questionsComplexity,
          roleLevel,
          areaFocus,
          interviewQualifications,
        };

        const countWords = (str) => {
          const words = str.split(/\s+/);
          return words.length;
        };

        // Calculate total word count from interviewBotResponses array
        const totalWordCount = responses.reduce((count, paragraph) => {
          return count + countWords(paragraph);
        }, 0);

        // Update the state with the total word count
        setCharacterCount(totalWordCount);
        handleWords(totalWordCount);

        // Fetch options
        const requestOptions = {
          method: "PUT", // Change this to PUT
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jobDataa),
        };
        fetch(apiUrl, requestOptions)
          .then(() => {
            // setIsInterviewSaved((isInterviewSaved) => !isInterviewSaved);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        setInterviewLoading(false);
        setDisabledJobSelection(false);

        console.error("Error handling user input:", error);
      }
    } else {
      setOpenPopup(true);
      setInterviewLoading(false);
      setDisabledJobSelection(false);
    }
  };

  const authInfo = {
    JobName,
    JobNameUpdate,
    setSetProjectIdloaded,
    handleRemoveUserInfo,
    handleUpdateRequestReset,

    isUpdateRequest,
    handleUpdateRequest,
    handleskip,
    skip,
    handleDaypass,
    action,
    handleAction,
    userInfo,
    handleuserInfo,
    totalWords,
    words,
    handleWords,
    projectId,
    projectUpdateId,
    FullJobData,
    handleJobData,
    handlefile,
    file,
    user,
    loading,
    providerLogin,
    creatUser,
    login,
    logout,
    creatNewUser,
    reset,
    verification,
    refetch,
    setRefetch,
    setSearchLoading,
    searchLoading,
    errors,
    setErrors,
    candidateResponse,
    setCandidateResponse,
    sourcingResponse,
    setSourcingResponse,
    onlineResourcesResponse,
    setonlineResourcesResponse,
    techResponse,
    setTechResponse,

    OpenPopup,
    setOpenPopup,
    singleObject,
    handleCandidateInsight,
    characterCount,
    setCharacterCount,
    handleCountWords,
    handleSourcing,
    handleOnlineResources,
    handleTechInsight,
    response,
    setResponse,
    handleEmailClick,
    isEmailSaved,
    emailType,
    decodedValue,
    seEmailType,
    setPersona,
    setTonality,
    setTile,
    setname,
    persona,
    setskills,
    skills,
    setexperience,
    Tonality,
    title,
    name,
    setName,
    firstName,
    setDecodedValue,
    emailResponse,
    setEmailResponse,
    emailLoading,
    setEmailLoading,
    sendRes,
    rerenderCount,
    setRerenderCount,
    currentIndex,
    setCurrentIndex,
    selectStyle,
    setselectStyle,
    currentModel,
    setCurrentModel,
    jobDescLoading,
    setJobDescLoading,
    botResponse,
    setBotResponse,
    isJobDescSaved,
    handleJobDescClick,
    selectpersona,
    setselectpersona,
    modelNames,
    jobDesCharacterCount,
    setJobDescCharacterCount,
    // intake

    intakeBotResponses,
    setIntakeBotResponses,
    intakeBotResponses0,
    setIntakeBotResponses0,
    intakeBotResponses1,
    setIntakeBotResponses1,
    intakeLoading,
    setIntakeLoading,
    handleIntakeClick,
    currentModelVarNbQ,
    setCurrentModelVarNbQ,
    isIntakeSaved,
    intakeQst,
    meetingAttendee,
    intakeRole,
    setIntakeQst,
    setintakeRole,
    intakeQstType,
    setIntakeQstType,

    setMeetingAttendee,
    disabledJobSelection,
    setDisabledJobSelection,
    // interview
    interviewCurrentIndexVarNbQ,
    setInterviewCurrentIndexVarNbQ,
    interviewCurrentModelVarNbQ,
    setInterviewCurrentModelVarNbQ,
    interviewBotResponses,
    setInterviewBotResponses,
    interviewBotResponses0,
    setInterviewBotResponses0,
    // interviewBotResponses1,
    // setInterviewBotResponses1,
    handleInterviewClick,
    interviewLoading,
    setInterviewLoading,
    isInterviewSaved,
    setIsInterviewSaved,
    numberOfQst,
    setNumberOfQst,
    roleLevel,
    setRoleLevel,
    questionsComplexity,
    setQuestionsComplexity,
    areaFocus,
    setAreaFocus,
    questionsType,
    setQuestionsType,
    interviewQualifications,
    setInterviewQualifications,

    //
    openWordsLimitPopup,
    setOpenWordsLimitPopup,
    location,
    setLocation,
    setjobName,
    setprojectId,
  };

  //firebase-------------------------------
  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};

// Custom hook to easily access the UserContext
export const useUserContext = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
