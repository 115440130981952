import React, { useContext, useEffect, useState } from "react";
import { AngleDown, Cog } from "../../../../components/Icon";
import { AuthContext, useUserContext } from "../../../../Context/UserContext";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowUp19 } from "@fortawesome/free-solid-svg-icons";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

const JobsSingleHeading = () => {
  const {
    projectId,
    projectUpdateId,
    JobNameUpdate,
    isUpdateRequest,
    handleUpdateRequest,
    handleUpdateRequestReset,
    disabledJobSelection,
  } = useUserContext();
  const { FullJobData, handleJobData, action } = useUserContext();

  const [menuOpen, setMenuOpen] = useState(false);
  const [setting, setSetting] = useState(false);

  const [collapseNav, setCollapseNav] = useState(false);

  const [hiringManager, setHiringManager] = useState("Nail Patel");
  const [team, setTeam] = useState("Team-A");

  const [isTitle, setIsTitle] = useState(true);
  const [isHiringManager, setIsHiringManager] = useState(false);
  const [isDepartment, setIsDepartment] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [isClient, setIsClient] = useState(true);
  const [isStatus, setIsStatus] = useState(true);
  const [isPrioirity, setIsPriority] = useState(true);
  //nc

  const [jobData, setJobData] = useState([]);

  useEffect(() => {
    async function fetchJobData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_END_POINT}/get/job`,

          {
            params: {
              projectId: projectId, // Pass the projectId as a query parameter
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }

        setJobData(response.data); // Assuming you have a setJobData function to update job data
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    }

    fetchJobData();
    handleUpdateRequestReset();
  }, [projectId, isUpdateRequest]);

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    handleJobData(jobData);
  }, [jobData]);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/get/allJob?email=${user?.email}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setJobs(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [projectId, action]);

  // const [jobTitle, setJobTitle] = useState(() => {
  //   // Initialize the jobTitle from localStorage or a default value
  //   const storedJobTitle = localStorage.getItem("jobTitle");
  //   return storedJobTitle || "front end developer";
  // });

  // // Update localStorage whenever jobTitle changes
  // useEffect(() => {
  //   localStorage.setItem("jobTitle", jobTitle);
  // }, [jobTitle]);

  //==

  // const [jd, setjd] = useState(() => {
  //   // Initialize the jobTitle from localStorage or a default value
  //   const storedJobTitle = localStorage.getItem("jd");
  //   return storedJobTitle || "20 k salary, location canada";
  // });

  // Update localStorage whenever jobTitle changes
  // useEffect(() => {
  //   localStorage.setItem("jd", jd);
  // }, [jd]);

  ///////////////////////
  // localStorage.setItem("jobTitle", jobTitle);
  // localStorage.setItem("jd", jd);

  // const savedJobTitle = localStorage.getItem('jobTitle');
  //  //('savedJobTitle', savedJobTitle)

  //nc
  return (
    <section className="jobs-single-heading !py-2 !flex !items-center">
      <div className="item">
        <div className={`flex   items-center justify-between gap-3`}>
          <div className="subtitle !font-[400] !mb-0  ">Job Title</div>
          <div className="name d-flex align-items-center  !border-gray-200 !p-2 !py-1  !rounded-xl border !flex !items-center !justify-between !gap-5">
            <div
              className="no-gutter text-start    "
              // onClick={() => setMenuOpen(!menuOpen)}
              // onBlur={() => setMenuOpen(false)}
            >
              {jobData[0]?.name}
            </div>
            <span className="d-flex align-items-center">
              <button
                className="no-gutter mx-sm-2 !p-0 !m-0"
                onClick={() => setMenuOpen(!menuOpen)}
                onBlur={() => setMenuOpen(false)}
                type="button"
                disabled={disabledJobSelection}
              >
                <AngleDown />
              </button>
              {/* <button
							className="no-gutter"
							onClick={() => setSetting(!setting)}
							type="button"
						>
							<Cog />
						</button> */}
            </span>
          </div>
        </div>
        <div className={`job-heading-menu ${menuOpen ? "active" : ""}`}>
          <div style={{ height: "500px", overflowY: "auto" }}>
            <table className="table __table" style={{ "--section": "#F4F7FB" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      position: "sticky",
                      top: 0,
                      background: "#F4F7FB",
                      fontWeight: "600",
                    }}
                  >
                    Job Title
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      top: 0,
                      background: "#F4F7FB",
                    }}
                  >
                    Hiring Manager
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      top: 0,
                      background: "#F4F7FB",
                    }}
                  >
                    Team
                  </th>
                </tr>
              </thead>
              <tbody>
                {jobs?.map(({ name, jd, uniqueID, manager, team }, i) => (
                  <tr key={i}>
                    <td
                      onClick={() => {
                        // setjd(jd);
                        // setJobTitle(name);
                        projectUpdateId(uniqueID, name);
                        JobNameUpdate(name);
                      }}
                      className="hover:bg-gray-200"
                    >
                      {name}
                    </td>
                    <td onClick={() => setHiringManager(hiringManager)}>
                      {manager}
                    </td>
                    <td onClick={() => setTeam(team)}>{team}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={`job-setting ${setting ? "active" : ""}`}
          onMouseOver={() => setSetting(true)}
          onMouseLeave={() => setSetting(false)}
        >
          <div className="job-setting-inner">
            <label className="form-check form--check flex-grow-1">
              <input
                className="form-check-input"
                checked={isTitle}
                onChange={(e) => setIsTitle(e.target.checked)}
                type="checkbox"
              />
              {/* <span className="form-check-label">Job Title</span> */}
            </label>
            <label className="form-check form--check flex-grow-1">
              <input
                className="form-check-input"
                checked={isHiringManager}
                onChange={(e) => setIsHiringManager(e.target.checked)}
                type="checkbox"
              />
              {/* <span className="form-check-label">Hiring Manager</span> */}
            </label>
            <label className="form-check form--check flex-grow-1">
              <input
                className="form-check-input"
                checked={isDepartment}
                onChange={(e) => setIsDepartment(e.target.checked)}
                type="checkbox"
              />
              {/* <span className="form-check-label ">Department</span> */}
            </label>
            <label className="form-check form--check flex-grow-1">
              <input
                className="form-check-input"
                checked={isTeam}
                onChange={(e) => setIsTeam(e.target.checked)}
                type="checkbox"
              />
              <span className="form-check-label">Team</span>
            </label>
            <label className="form-check form--check flex-grow-1">
              <input
                className="form-check-input"
                checked={isClient}
                onChange={(e) => setIsClient(e.target.checked)}
                type="checkbox"
              />
              {/* <span className="form-check-label">Client</span> */}
            </label>
            <label className="form-check form--check flex-grow-1">
              <input
                className="form-check-input"
                checked={isStatus}
                onChange={(e) => setIsStatus(e.target.checked)}
                type="checkbox"
              />
              {/* <span className="form-check-label">Status</span> */}
            </label>
            <label className="form-check form--check flex-grow-1">
              <input
                className="form-check-input"
                checked={isPrioirity}
                onChange={(e) => setIsPriority(e.target.checked)}
                type="checkbox"
              />
              <span className="form-check-label">Priority</span>
            </label>
          </div>
        </div>
      </div>
      <div className="item">
        {collapseNav && (
          <div className="subtitle  !font-[400]">Hiring Manager</div>
        )}
        <div className="name  min-h-[20px]">{jobData[0]?.manager || ""}</div>
      </div>
      <div className="item">
        {collapseNav && <div className="subtitle  !font-[400]">Department</div>}{" "}
        <div className="name min-h-[20px]">{jobData[0]?.department}</div>
      </div>
      <div className="item ">
        {collapseNav && <div className="subtitle  !font-[400]">Team</div>}{" "}
        <div className="name min-h-[20px]">{jobData[0]?.team}</div>
      </div>
      <div className="item !flex !flex-col !justify-between">
        {collapseNav && (
          <p className="subtitle  !font-[400] basis-1/2">Client</p>
        )}{" "}
        <p className="name min-h-[20px] ">{jobData[0]?.client}</p>
      </div>
      <div className="item">
        {collapseNav && <div className="subtitle  !font-[400]">Status</div>}{" "}
        <div
          className={`name  min-h-[20px] ${
            jobData[0]?.selectStatus === "Close"
              ? "text--danger"
              : "text--success"
          }`}
        >
          {jobData[0]?.selectStatus}
        </div>
      </div>
      <div className="item">
        {collapseNav && <div className="subtitle  !font-[400]">Priority</div>}{" "}
        <div
          className={`name  min-h-[20px] ${
            jobData[0]?.selectPriority === "High"
              ? "text--danger"
              : jobData[0]?.selectPriority === "Medium"
              ? "text--yellow"
              : jobData[0]?.selectPriority === "Urgent"
              ? "text--danger"
              : "text--success"
          }`}
        >
          {jobData[0]?.selectPriority}
        </div>
      </div>

      <div className="item !min-w-0 !flex-row-reverse ">
        {collapseNav && (
          <div className="subtitle  !font-[400] min-h-[20px]"></div>
        )}{" "}
        <div
          className={
            collapseNav
              ? "name  min-h-[20px] rotate-180"
              : `name  min-h-[20px]  `
          }
          onClick={() => setCollapseNav((prevCollap) => !prevCollap)}
        >
          {!collapseNav ? (
            <AngleDown
            // onClick={() => setCollapseNav((prevCollap) => !prevCollap)}
            />
          ) : (
            <AngleDown
              className="rotate-180"
              // onClick={() => setCollapseNav((prevCollap) => !prevCollap)}
            />
          )}
        </div>
      </div>
    </section>
  );
};

const data1 = [
  {
    title: "Frontend Developer",
    hiringManager: "Nafiz Rana",
    team: "Team A",
  },
  {
    title: "UI / UX Engineer",
    hiringManager: "Nail Patel",
    team: "Team C",
  },
  {
    title: "Backend Developer",
    hiringManager: "AT Hasan",
    team: "Team D",
  },
  {
    title: "Full Stack Developer",
    hiringManager: "NS Rockey",
    team: "Team B",
  },
];

export default JobsSingleHeading;
