import React from "react";
import { useUserContext } from "../../../../../Context/UserContext";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TechInsight = ({
  textRef6,
  textRef7,
  createTechKeywordHTML,
  createTechStackHTML,
  handleOpenJd,
}) => {
  const { searchLoading, errors } = useUserContext();

  return (
    <div className="mb-4">
      <div
        className={`display-text rounded p-3 open-box`}
        style={{
          minHeight: "100px",
          maxHeight: "auto", // Updated to 'auto'
          overflowY: "auto", // Updated to 'auto'
          display: "block",
        }}
      >
        <div className="search-feature-container">
          <div
            style={{
              width: "40%",
            }}
            className=" jobtitle-box flex flex-col gap-4"
          >
            <p className="!font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D] ">
              Technology Stack
            </p>

            <div
              className="feature-box"
              ref={textRef6}
              contentEditable
              spellCheck="false"
              dangerouslySetInnerHTML={createTechStackHTML}
            ></div>
          </div>
          <div
            style={{
              width: "60%",
            }}
            className="jobtitle-box flex flex-col gap-4"
          >
            <p className="!font-[500] !text-base !leading-5 !capitalize !text-[#2D3E4D] ">
              Technology related keywords to Expand your search
            </p>
            <div
              className="feature-box"
              ref={textRef7}
              contentEditable
              spellCheck="false"
              dangerouslySetInnerHTML={createTechKeywordHTML}
            ></div>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end mt-4 pt-3">
          <div className="d-flex  flex-col items-center gap-20px save-info">
            {errors && errors.techSearchError && (
              <p className="text-red-500 text-sm font-semibold">
                {" "}
                {errors.techSearchError}
              </p>
            )}
            <button
              onClick={() => handleOpenJd("tech")}
              className="__btn m-0 px-xl-5 w-[295px]  !font-[500] !leading-5 !text-base !capitalize "
              disabled={searchLoading.techLoading}
            >
              {searchLoading && searchLoading.techLoading ? (
                <div
                  className="!flex justify-around  w-full"
                  style={{
                    display: "flex",
                    // justifyContent: "space-between",
                    gap: "2rem",
                    alignItems: "center",
                  }}
                >
                  {/* <p></p> */}
                  <p className="mb-0"> Loading…</p>
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
              ) : (
                "Get Technology Insights"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechInsight;
