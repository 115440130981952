import { useBlocker } from "react-router-dom";

const NavConfirmationDialog = ({ when, onSave, onCancel }) => {
  const { state, proceed, reset } = useBlocker(when);

  return (
    state === "blocked" && (
      <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50  ">
        <div className="flex flex-col justify-center items-center p-8 bg-white text-black rounded-lg shadow-lg">
          <h3 className="mt-0 mb-8 !text-black">Unsaved Changes Alert !</h3>
          <p className="mb-8 !text-black">
            You have unsaved changes! If you leave this page now, your changes
            will be lost.
            <br /> Would you like to save your changes before exiting?
          </p>
          <div className="flex justify-end gap-3">
            <button
              onClick={(e) => {
                onSave(e);

                proceed();
              }}
              className="__btn"
            >
              Save Changes
            </button>
            <button
              onClick={(e) => {
                onCancel(e);
                proceed();
              }}
              className="__btn-outline"
            >
              Discard Changes
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default NavConfirmationDialog;
