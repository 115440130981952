import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useUserContext } from "../../../Context/UserContext";

const JobDescriptionPopup = ({ open, setOpen, handleUserInput }) => {
  const { handleskip, skip, projectId } = useUserContext();

  const handleSkip = () => {
    handleskip(true);
    handleUserInput(projectId);
  };
  return (
    <>
      <Modal show={open} className="description-popup popup-modal" centered>
        <div className="modal-header px-sm-5 pt-5 text-center border-0 pb-0">
          <h5 className="modal-title modal-title-2">
            You can get better results if you add a Job Description (JD)
          </h5>
        </div>
        <div className="modal-body pt-4 px-sm-5 pb-5">
          <div className="d-flex gap-20px">
            <Link
              to={"/job-management"}
              className="__btn w-0 flex-grow-1 m-0"
              onClick={() => {
                setOpen(false);
              }}
            >
              Upload JD
              {/* <input type="file" className="d-none" /> */}
            </Link>

            <button
              type="submit"
              className="__btn-outline w-0 flex-grow-1"
              onClick={() => {
                setOpen(false);
                handleSkip();
              }}
            >
              Skip
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default JobDescriptionPopup;
