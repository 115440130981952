import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.scss";
import { RouterProvider } from "react-router-dom";
import router from "./routes";

export const MyContext = createContext("");

function App({ children }) {
  // const Wrapper = ({ children }) => {
  //   const location = useLocation();
  //   useEffect(() => {
  //     setTimeout(() => {
  //       document.documentElement.scrollTo(0, 0);
  //     }, 500);
  //   }, [location.pathname, location.search]);
  //   return children;
  // };

  function ScrollToTop() {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname, location.search]);

    return null; // This component does not render anything
  }

  const [collapesd, setCollapsed] = useState(false);

  return (
    <>
      <MyContext.Provider value={{ collapesd, setCollapsed }}>
        <RouterProvider router={router}>
          {/* <ScrollToTop /> */}
          {children}
        </RouterProvider>
      </MyContext.Provider>
    </>
  );
}

export default App;
