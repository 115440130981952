import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../components/Logo";
import { useScreenSize } from "../../../hooks/useMobile";

const Footer = () => {
  const screen = useScreenSize();
  return (
    <footer>
      <div className="container">
        <div className="footer-wrapper">
          <Logo favlogo={screen < 768} />
          <ul className="footer-menu">
            <li>
              <a href="/#home">Product</a>
            </li>
            <li>
              <a href="/#benefit">Benefits</a>
            </li>
            <li>
              <a href="/#pricing">Pricing</a>
            </li>
            <li>
              <Link to="/tos">Terms of Service</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
          </ul>
          <div className="copyright">
            © 2024 R3achout.AI
            {/* <span className="text-base-2">R3achout.AI </span>{" "} */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
