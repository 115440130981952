import React from "react";
import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <>
      <section className="cta-section">
        <div className="container">
          <div className="cta-wrapper">
            <h2 className="title">Get a free account today</h2>
            <div className="subtitle">
              No credit card required
              <div className="arrow">
                <Arrow />
              </div>
            </div>
            <div>
              <Link to="/login" className="cmn-btn">
                <span>Get Started</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="172"
    viewBox="0 0 172 49"
    fill="none"
  >
    <path
      d="M2.5 5C2.5 5 56.4783 50.8553 96.5 47C129.576 43.8138 150.806 31.7642 170.5 5"
      stroke="url(#paint0_linear_9710_2452)"
      strokeWidth="2"
      strokeDasharray="4 4"
    />
    <path
      d="M2 4.5L1.76536 3.52792L0.947936 3.72523L1.00207 4.56438L2 4.5ZM16.7346 1.97208C17.2715 1.84249 17.6017 1.30223 17.4721 0.765359C17.3425 0.228493 16.8022 -0.101671 16.2654 0.0279178L16.7346 1.97208ZM3.99793 19.9356L2.99793 4.43562L1.00207 4.56438L2.00207 20.0644L3.99793 19.9356ZM2.23464 5.47208L16.7346 1.97208L16.2654 0.0279178L1.76536 3.52792L2.23464 5.47208Z"
      fill="#F5BD62"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9710_2452"
        x1="10"
        y1="8.00001"
        x2="175.5"
        y2="8.00001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA412" />
        <stop offset="0.490413" stopColor="#FFA412" stopOpacity="0.509587" />
        <stop offset="1" stopColor="#FFA412" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
export default CallToAction;
