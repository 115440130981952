import React, { createContext, useContext, useState } from "react";
import NavConfirmationDialog from "../pages/dashboard/jobs-single/components/NavConfirmation";

const NavigationContext = createContext();

export const useNavigationControl = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [isDirty, setDirty] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [nextPath, setNextPath] = useState("");

  const handleNavigate = (path) => {
    if (isDirty) {
      setShowConfirmDialog(true);
      setNextPath(path);
    } else {
      // If not dirty, navigate immediately
      window.location.href = path; // or use a router method if internal navigation
    }
  };

  const confirmLeave = () => {
    setDirty(false);
    setShowConfirmDialog(false);
    window.location.href = nextPath; // or use a router method if internal navigation
  };

  const cancelLeave = () => {
    setShowConfirmDialog(false);
    setNextPath("");
  };

  return (
    <NavigationContext.Provider
      value={{ setDirty, handleNavigate, confirmLeave, cancelLeave, isDirty }}
    >
      {children}
      {showConfirmDialog && (
        <div>
          <p>You have unsaved changes, are you sure you want to leave?</p>
          <button onClick={confirmLeave}>Yes</button>
          <button onClick={cancelLeave}>No</button>
        </div>
      )}
    </NavigationContext.Provider>
  );
};
