import React, { useEffect, useState } from "react";
import { XlsxUpload } from "../../../../components/XlsxUpload";
import Input from "../../../auth/components/Input";
import { useUserContext } from "../../../../Context/UserContext";
import axios from "axios";
import { Link } from "react-router-dom";
import Popup from "../../../../components/Popup";
import { useNavigate, useLocation } from "react-router-dom";
import NavConfirmationDialog from "./NavConfirmation";

const JobsManagement = ({ handleClick }) => {
  const {
    projectId,
    projectUpdateId,
    handleAction,
    handleUpdateRequest,
    location,
    setLocation,
  } = useUserContext();

  const { file, handlefile } = useUserContext();

  const [editable, setEditable] = useState(true);

  const [name, setName] = useState("");
  const [manager, setManager] = useState("");
  const [department, setDepartment] = useState("");
  const [team, setTeam] = useState("");
  const [client, setClient] = useState("");
  const [jd, setJd] = useState("");

  const [files, setFiles] = useState([]);

  const [selectPriority, setselectPriority] = useState("");
  const [selectStatus, setselectStatus] = useState("open");
  const [selectstage, setselectstage] = useState("initial");

  const [isDirty, setDirty] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    projectUpdateId(projectId);
  }, [projectId]);

  const handleChange = (e) => {
    setJd(e.target.value);
    setDirty(true);
    if (editable) {
      handlefile(e.target.value);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        // "${process.env.REACT_APP_SERVER_END_POINT}/get/job",
        `${process.env.REACT_APP_SERVER_END_POINT}/get/job`,

        {
          params: {
            projectId: projectId, // Set the "projectId" query parameter
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      setJobData(response.data);
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  };

  const handleEdit = (event) => {
    event.preventDefault();

    const submit = {
      name,
      manager,
      department,
      team,
      client,
      location,
      selectPriority,
      selectStatus,
      selectstage,
      jd,
      files,
    };

    fetch(`${process.env.REACT_APP_SERVER_END_POINT}/updatejd/${projectId}`, {
      method: "PUT",

      headers: {
        "content-type": "application/json",
      },

      body: JSON.stringify(submit),
    })
      .then((res) => res.json())
      .then((data) => {
        handleUpdateRequest();
        handleAction(1);
        setDirty(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleSubmit = async (e) => {
  // 	e.preventDefault();

  // 	const jobData = {
  // 		name,
  // 		manager,
  // 		department,
  // 		team,
  // 		client,
  // 		selectPriority,
  // 		selectStatus,
  // 		selectstage,
  // 		jd,
  // 		files,
  // 	};
  // 	////console'jobData', jobData)

  // 	try {
  // 		const response = await fetch('${process.env.REACT_APP_SERVER_END_POINT}/post/createJob', {
  // 			method: 'POST',
  // 			headers: {
  // 				'Content-Type': 'application/json',
  // 			},
  // 			body: JSON.stringify(jobData),
  // 		});

  // 		if (response.ok) {
  // 			////console'Job created successfully');
  // 			// You can add further logic here, such as navigating to another page or displaying a success message.
  // 		} else {
  // 			console.error('Failed to create job');
  // 			// Handle the error here
  // 		}
  // 	} catch (error) {
  // 		console.error('Error:', error);
  // 		// Handle network or other errors here
  // 	}
  // };
  const [jobData, setJobData] = useState([]);

  useEffect(() => {
    fetchData(); // Fetch job data on component mount or when projectId changes
  }, [projectId]);

  useEffect(() => {
    try {
      // Check if jobData exists and has a name property
      if (jobData && jobData[0]) {
        const {
          name,
          manager,
          department,
          team,
          client,
          jd,
          files,
          location,
          selectStatus,
          selectPriority,
          selectstage,
        } = jobData[0];
        setName(name || "");
        setManager(manager || "");
        setDepartment(department || "");
        setTeam(team || "");
        setClient(client || "");
        setLocation(location || "");
        setselectStatus(selectStatus || "");
        setselectPriority(selectPriority || "");
        setselectstage(selectstage || "");
        setJd(jd || "");
        setFiles(files || []);
      }
    } catch (error) {
      // Handle errors here (e.g., log them or display an error message)
      console.error("Error in useEffect:", error);
    }
  }, [jobData]);

  const handlecancelButton = () => {
    // Reset state variables to their initial values
    setName("");
    setManager("");
    setDepartment("");
    setTeam("");
    setClient("");
    setLocation("");
    setJd("");
    setFiles([]);
    setselectPriority("");
    setselectStatus("open");
    setselectstage("initial");

    // Fetch job data after resetting the state variables
    fetchData();
  };

  const instanlyUpdateJd = (name, input) => {
    const submit = {
      [name]: input,
    };

    fetch(`${process.env.REACT_APP_SERVER_END_POINT}/updatejd/${projectId}`, {
      method: "PUT",

      headers: {
        "content-type": "application/json",
      },

      body: JSON.stringify(submit),
    })
      .then((res) => res.json())
      .then((data) => {
        handleUpdateRequest();
        handleAction(1);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const text = "Job updated successfully";
  return (
    <>
      <NavConfirmationDialog
        when={!!isDirty}
        onSave={handleEdit}
        onCancel={handlecancelButton}
      />

      <section className="jobs-management bg-body-2">
        <form onSubmit={handleEdit} className="dashboard-form">
          <div className="row g-4">
            <div className="col-md-5">
              <div className="mb-3">
                <h1 className="form-label form--label !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Job Title
                </h1>
                <Input
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setDirty(true);
                  }}
                />
              </div>
              <div className="mb-3">
                <h1 className="form-label form--label !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Hiring Manager{" "}
                </h1>
                <Input
                  value={manager}
                  onChange={(e) => {
                    setManager(e.target.value);
                    setDirty(true);
                  }}
                />
              </div>
              <div className="mb-3">
                <h1 className="form-label form--label !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Department
                </h1>
                <Input
                  value={department}
                  onChange={(e) => {
                    setDepartment(e.target.value);

                    setDirty(true);
                  }}
                />
              </div>
              <div className="mb-3">
                <h1 className="form-label form--label !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Team
                </h1>
                <Input
                  value={team}
                  onChange={(e) => {
                    setTeam(e.target.value);
                    setDirty(true);
                  }}
                />
              </div>
              <div className="mb-3 ">
                <h1 className="form-label form--label !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Client
                </h1>
                <Input
                  value={client}
                  onChange={(e) => {
                    setClient(e.target.value);
                    setDirty(true);
                  }}
                />
              </div>

              <div className="mb-3 ">
                <h1 className="form-label form--label !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Location
                </h1>

                <Input
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                    setDirty(true);
                  }}
                />
              </div>

              <div className="mb-3">
                <h1 className="form-label form--label !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Status
                </h1>
                <select
                  className="form-select form--control"
                  id="priority"
                  name="priority"
                  value={selectStatus}
                  onChange={(e) => {
                    setselectStatus(e.target.value);
                    setDirty(true);
                  }}
                >
                  <option value="Open">Open</option>
                  <option value="Close">Close</option>
                </select>
              </div>
              <div className="mb-3">
                <h1 className="form-label form--label !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Priority
                </h1>
                <div>
                  <select
                    className="form-select form--control"
                    id="priority"
                    name="priority"
                    value={selectPriority}
                    onChange={(e) => {
                      setselectPriority(e.target.value);

                      setDirty(true);
                    }}
                  >
                    <option value="Urgent">Urgent</option>
                    <option value="High">High</option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                  </select>
                </div>
              </div>

              <div className="mb-3">
                <div>
                  <h1 className="form-label form--label !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D] ">
                    Stage
                  </h1>
                  <select
                    className="form-select form--control"
                    id="stage"
                    name="stage"
                    value={selectstage}
                    onChange={(e) => {
                      setselectstage(e.target.value);

                      setDirty(true);
                    }}
                  >
                    <option
                      value="Initial"
                      // selected={selectstage === "Initial"}
                    >
                      Initial
                    </option>
                    <option value="Intake">Intake</option>
                    <option
                      value="Sourcing"
                      // selected={selectstage === "Sourcing"}
                    >
                      Sourcing
                    </option>
                    <option
                      value="Interview"
                      // selected={selectstage === "Interview"}
                    >
                      Interview
                    </option>
                    <option
                      value="Offer"
                      //  selected={selectstage === "Offer"}
                    >
                      Offer
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="mb-4">
                {/* <label className="form--label mb-20">
									Upload your Job Description


								</label>
								<XlsxUpload files={files} setFiles={setFiles} /> */}
              </div>

              <div>
                <label className="form--label mb-20 !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D]">
                  Job Descripton
                </label>
                <textarea
                  className="form-control form--control __txtarea-1"
                  placeholder="Copy/Paste your job description"
                  value={jd}
                  onChange={handleChange}
                  readOnly={!editable}
                  style={{ maxHeight: "" }} // Set the maximum height to 200px (adjust to your desired height)
                ></textarea>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-end mt-3">
              <div className="d-flex gap-20px">
                <button
                  type="button"
                  onClick={handlecancelButton}
                  className="__btn-outline m-0"
                >
                  Cancel
                </button>

                <button
                  onClick={handleEdit}
                  className="__btn"
                  data-bs-toggle="modal"
                  data-bs-target="#job-save-pop"
                >
                  Save
                </button>
                <Popup text={text}></Popup>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

const status = [
  {
    value: "Open",
    name: "Open",
  },
  {
    value: "Close",
    name: "Close",
  },
];
const stage = [
  {
    value: "Initial",
    name: "Initial",
  },
  {
    value: "Intake",
    name: "Intake",
  },
  {
    value: "Sourcing",
    name: "Sourcing",
  },
  {
    value: "Interview",
    name: "Interview",
  },
];

export default JobsManagement;
