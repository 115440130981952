import React from "react";
import { NavLink } from "react-router-dom";
import JobsSingleHeading from "./JobsSingleHeading";

const JobSingleLayout = ({ children }) => {
  return (
    <>
      <JobsSingleHeading />
      <div className="job-tab-group">
        <ul>
          {tabData?.map((item, i) => (
            <li key={i}>
              <NavLink to={`/${item?.url}`}>{item?.name}</NavLink>
            </li>
          ))}
        </ul>
      </div>
      {/* Jobs Management */}
      {children}
    </>
  );
};
const tabData = [
  {
    name: "Job Management",
    url: "job-management",
  },
  {
    name: "Job Posting",
    url: "jd-rewrite",
  },
  {
    name: "Candidate R3achout",
    url: "r3achout",
  },
  {
    name: "Search Assistant",
    url: "search",
  },
  {
    name: "Intake",
    url: "intake",
  },
  {
    name: "Interview",
    url: "interview",
  },
];
export default JobSingleLayout;
