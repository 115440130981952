import React, { useState } from "react";
import DashboardLayout from "../../../components/DashboardLayout";
import JobDescriptionPopup from "../components/JobDescriptionPopup";
import JobSingleLayout from "./components/JobSingleLayout";
import JobsManagement from "./components/JobsManagement";
import { useUserContext } from "../../../Context/UserContext";

const JobsManagementPage = () => {
	const [open, setOpen] = useState(true);
	const { FullJobData, handleJobData } = useUserContext();
	//('FullJobData',FullJobData)
	  // Define breadcrumbs here, after FullJobData is available
	  const breadcrumbs = [
		  {
			  name: "My Jobs",
		  },
		  {
			  name: FullJobData[0]?.name,
		  },
		  {
			  name: "Job Management",
		  },
	  ];
	return (
		<>
			<DashboardLayout breadcrumbs={breadcrumbs}>
				<JobSingleLayout>
					<JobsManagement />
				</JobSingleLayout>
			</DashboardLayout>
			{/* <JobDescriptionPopup open={open} setOpen={setOpen} /> */}
		</>
	);
};



export default JobsManagementPage;
