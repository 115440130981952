import { ArcElement, Chart as ChartJS } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement);

const Chart2 = ({ jobData }) => {
  ////console'jobData.closeJobsCount', jobData.openJobsCount)
  const data = {
    datasets: [
      {
        data: [
          jobData.closeJobsCount,
          jobData.openJobsCount,
          jobData.urgentJobsCount,
          jobData.highPriorities,

          //   jobData.openJobsCount,
          //   jobData.urgentJobsCount,
        ],
        backgroundColor: ["#EFF4FA", "#0C579B", "#FF0000", "#FFA412"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className="chart-1 chart2-padding-rm">
      <div className="pie-chart pie-chart-2">
        <Doughnut data={data} />
        <div className="center-label">
          <div>Open</div>
          <div className="count">{jobData.openJobsCount}</div>
        </div>
      </div>
      <ul className="chart-label-2">
        <li>
          <span className="icon" style={{ background: "#EFF4FA" }}></span>{" "}
          <span className="">{jobData.closeJobsCount} Closed Jobs</span>
        </li>
        <li>
          <span className="icon" style={{ background: "#0C579B" }}></span>{" "}
          <span className="">
            <span style={{ color: "#0C579B" }}>{jobData.openJobsCount}</span>{" "}
            Open Jobs
          </span>
        </li>
        <li>
          <span className="icon" style={{ background: "#FF0000" }}></span>{" "}
          <span className="">
            <span style={{ color: "#FF0000" }}>{jobData.urgentJobsCount}</span>{" "}
            Urgent Jobs
          </span>
        </li>
        <li>
          <span className="icon" style={{ background: "#FFA412" }}></span>{" "}
          <span className="">
            <span style={{ color: "#FFA412" }}>{jobData.highPriorities}</span>{" "}
            High Jobs
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Chart2;
