import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { CSVIcon, UploadIcon } from "./Icon";
import { useUserContext } from "../Context/UserContext";
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export function XlsxUpload({ children }) {
  const [files, setFiles] = useState([]);
  const [text, setText] = useState(""); // State to store extracted text
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { file, handlefile } = useUserContext();

  useEffect(() => {
    handlefile(text);
  }, [text]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: [".xlsx", ".csv", ".zip", ".txt", ".pdf"], // Add .pdf to accepted file types
    multiple: false,
    onDrop: async (acceptedFiles) => {
      setFiles((prev) => acceptedFiles.map((file) => Object.assign(file)));

      // If the uploaded file is a text file, read its content
      if (acceptedFiles[0].type === "text/plain") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileContent = event.target.result;
          setText(fileContent);
        };
        reader.readAsText(acceptedFiles[0]);
      }

      // If the uploaded file is a PDF, extract text content
      if (acceptedFiles[0].type === "application/pdf") {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const buffer = e.target.result;
          const loadingTask = pdfjs.getDocument(new Uint8Array(buffer));
          const pdf = await loadingTask.promise;

          let fullText = '';
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            const text = textContent.items.map((s) => s.str).join(' ');
            fullText += text;
          }

          setText(fullText);
          setNumPages(pdf.numPages);
          setPageNumber(1);
        };

        reader.readAsArrayBuffer(acceptedFiles[0]);
      }
    },
  });

  const thumbs = files.map((file) => {
    return (
      <div key={file.name} className="item">
        {file && (
          <div className="d-flex addr-dropdown p-3 bg-transparent align-items-center">
            <div className="icon">
              <CSVIcon />
            </div>
            <div className="cont ps-3">
              <h6>
                <span>{file.name}</span>
              </h6>
            </div>
          </div>
        )}
      </div>
    );
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  let theImgUrl;
  files.map((file) => (theImgUrl = URL.createObjectURL(file)));

  return (
    <>
      <div className="image-uploader-1">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          {files?.length === 0 ? (
            <div className="image-upload-container">
              <UploadIcon />
            </div>
          ) : (
            <div className="upload-file-wrapper">
              {thumbs}
              {text && <h1></h1>} {/* Display extracted text in an h1 tag */}
              {numPages && (
                <div>
                  <p>
                    {/* Page {pageNumber} of {numPages} */}
                  </p>
                  <Document file={null} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                    {/* <Page pageNumber={pageNumber} /> */}
                  </Document>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

// Usage
<XlsxUpload />;
