import React, { useState, useEffect } from "react";
import { Eye, EyeSlash } from "../../../components/Icon";

const Input = ({
  label,
  type,
  eye,
  value,
  // setValue,
  textarea,
  height,
  secondaryValue,
  required,
  options,
  path,
  placeholder,
  isDisabled,
  ...rest
}) => {
  const [inputType, setInputType] = useState(type);

  if (options?.length > 0)
    return (
      <>
        {label && (
          <div>
            <label className="form-label form--label  !font-[500] !leading-5 !text-base !capitalize !text-[#2D3E4D] ">
              {label} {required && <span style={{ color: "red" }}>*</span>}
            </label>
            <select
              className="form-select form--control  !leading-5 !text-base !capitalize"
              {...rest}
              value={value}
              // disabled={isDisabled}
            >
              {options?.map((item, i) => (
                <option value={item?.value} className="" key={i}>
                  {item?.name}{" "}
                </option>
              ))}
            </select>
          </div>
        )}
      </>
    );
  return (
    <div>
      {label && (
        <label className="form-label form--label !font-[500] !leading-5 !text-base !capitalize">
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <div className="position-relative">
        {textarea ? (
          <textarea
            className="form-control form--control pt-2"
            type={inputType ? inputType : "text"}
            {...rest}
            value={value}
            // onChange={(e) => setValue(e.target.value)}
            style={{ height: height ? height : "" }}
          ></textarea>
        ) : (
          <>
            <input
              className="form-control form--control"
              type={inputType ? inputType : "text"}
              {...rest}
              value={value}
              // onChange={(e) => setValue(e.target.value)}
              placeholder={placeholder || ""}
              style={{
                opacity: secondaryValue ? "0" : "",
                position: "relative",
                zIndex: "3",
                textTransform: (path === "auth") & "lowercase",
              }}
              disabled={isDisabled}
            />
            {secondaryValue && (
              <input
                className="form-control form--control"
                style={{
                  position: "absolute",
                  left: "0",
                  top: "0",
                  textTransform: (path === "auth") & "lowercase",
                }}
                value={secondaryValue == "() -" ? "Phone" : secondaryValue}
                disabled={isDisabled}
              />
            )}
          </>
        )}
        {eye &&
          (inputType == "password" ? (
            <span
              className="eye"
              onClick={() => {
                if (inputType === "password") {
                  setInputType("text");
                } else {
                  setInputType("password");
                }
              }}
            >
              <Eye />
            </span>
          ) : (
            <span
              className="eye"
              onClick={() => {
                if (inputType === "password") {
                  setInputType("text");
                } else {
                  setInputType("password");
                }
              }}
            >
              <EyeSlash />
            </span>
          ))}
      </div>
    </div>
  );
};

export default Input;
