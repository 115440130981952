import React from "react";
import { Link } from "react-router-dom";
import fav_logo from "../assets/img/fav-logo.png";
import whiteLogo from "../assets/img/logo-white.png";
import logo from "../assets/img/logo.png";
const Logo = ({ variant, favlogo, url }) => {
	return (
		<Link to={url ? url : "/"} className="logo">
			{favlogo ? (
				<img src={fav_logo} alt="" />
			) : (
				<img src={variant === "white" ? whiteLogo : logo} alt="" />
			)}
		</Link>
	);
};

export default Logo;
