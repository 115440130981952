import React, { useEffect } from "react";
import Banner from "./components/Banner";
import CallToAction from "./components/CallToAction";
import Feature from "./components/Feature";
import Footer from "./components/Footer";
import Header from "./components/Header";
import PricingPlan from "./components/PricingPlan";
import { useContext } from "react";
import { AuthContext } from "../../Context/UserContext";
import { Route, useLocation, useNavigate } from "react-router-dom";
import Faqs from "../dashboard/components/Faqs";
const LandingPage = () => {
  const { user, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  // user?.email ? (
  //   navigate("/dashboard/home")
  // ) : (
  //   <Route path="/" element={<LandingPage />} />
  // );

  return (
    <>
      <Header />
      <Banner />
      <Feature />
      <PricingPlan />
      <CallToAction />
      <Faqs />
      <Footer />
    </>
  );
};

export default LandingPage;
