import React from "react";
import DashboardLayout from "../../../components/DashboardLayout";
import JobSingleLayout from "./components/JobSingleLayout";
import Reachout from "./components/Reachout";
import { useUserContext } from "../../../Context/UserContext";

const ReachoutPage = () => {
	const { FullJobData, handleJobData } = useUserContext();
 
	// Define breadcrumbs here, after FullJobData is available
	const breadcrumbs = [
		{
			name: "My Jobs",
		},
		{
			name: FullJobData[0]?.name,
		},
		{
			name: "Candidate r3achout",
		},
	];
	return (
		<>
			<DashboardLayout breadcrumbs={breadcrumbs}>
				<JobSingleLayout>
					<Reachout />
				</JobSingleLayout>
			</DashboardLayout>
		</>
	);
};


export default ReachoutPage;
