import React, { useContext } from "react";
import { AuthContext } from "../../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const UpgradeBtn = ({ setOpen, classNames }) => {
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleManageSubscription = async () => {
    try {
      // Assuming you have a customerId stored in Firestore or similar
      ////({ customerId })

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_END_POINT}/create-portal`,

        { customerId: userInfo?.customer_id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.url) {
        window.location.href = response.data.url; // Redirect to the customer portal
      }
    } catch (error) {
      console.error("Error accessing customer portal:", error);
    }
  };

  return (
    <button
      onClick={() => {
        if (userInfo.productName === "Silver") {
          handleManageSubscription();
          if (setOpen) {
            setOpen(false);
          }
        } else {
          navigate("/payment");
          if (setOpen) {
            setOpen(false);
          }
        }
      }}
      className={classNames}
    >
      Upgrade
    </button>
  );
};

export default UpgradeBtn;
