import React, { useState } from "react";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";

const TestResizable = () => {
  const [size, setSize] = useState({ width: 200, height: 200 });

  const handleResize = (event, { element, size }) => {
    setSize({ width: size.width, height: size.height });
  };

  return (
    <Resizable width={size.width} height={size.height} onResize={handleResize}>
      <div
        style={{
          width: size.width,
          height: size.height,
          backgroundColor: "salmon",
        }}
      >
        Resizable Content
      </div>
    </Resizable>
  );
};

export default TestResizable;
