import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowRight,
  FacebookIcon,
  GoogleIcon,
  LinkedinIn,
  OtherIcon,
  TiktokIcon,
  TwitterIcon,
  WordOfMouth,
} from "../../components/Icon";
import { useContext } from "react";
import { AuthContext } from "../../Context/UserContext";

const OnBoarding2 = () => {
  const [index, setIndex] = useState();
  const { user } = useContext(AuthContext);
  const [hear, setSelectedRecruiter] = useState(null);

  //('selectedRecruiter',hear)
  const handleClick = (recruiter) => {
    setSelectedRecruiter(recruiter);
  };

  const navigate = useNavigate();

  const handleUpdate = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_END_POINT}/update/account/${user?.email}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ hear }),
      }
    );

    if (response.ok) {
      navigate("/dashboard?reg-completed");
      // navigate("/dashboard?reg-completed")
      //console'User updated successfully.');
    } else {
      console.error("Failed to update user.");
    }
  };

  return (
    <>
      <div className="onboarding-section-wrapper">
        <section className="onboarding-banner-2">
          <div className="container">
            <h3 className="title">How did you hear about us?</h3>
          </div>
        </section>
        <div className="container">
          <div style={{ maxWidth: "604px" }} className="mx-auto">
            <div className="onboarding-wrapper-2">
              {data?.map((item, i) => (
                <div
                  className={`item ${index == i ? "active" : ""}`}
                  key={i}
                  onMouseDown={() => setIndex(i)}
                  onClick={() => handleClick(item)}
                >
                  <div className="icon">{item?.icon}</div>
                  <h5>{item?.name}</h5>
                </div>
              ))}
            </div>
            <div className="pb-2">
              <div className="__btn-grp __btn-grp-2 flex-wrap d-flex justify-content-center column-gap-4 pb-5">
                <button
                  className="__btn-outline column-gap-4 btn-black me-auto"
                  type="button"
                  onClick={() => navigate("/onboarding-1")}
                >
                  Back
                </button>
                <button className="__btn-outline column-gap-4" type="button">
                  Skip
                </button>
                <button
                  className="__btn column-gap-4 __sm-btn-shadow"
                  type="button"
                  onClick={handleUpdate}
                >
                  <span>Get Started</span> <ArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const data = [
  {
    icon: <GoogleIcon />,
    name: "Google",
  },
  {
    icon: <FacebookIcon />,
    name: "Facebook",
  },
  // {
  // 	icon: <TwitterIcon />,
  // 	name: "Twitter",
  // },
  {
    icon: <LinkedinIn />,
    name: "LinkedIn",
  },
  {
    icon: <TiktokIcon />,
    name: "TikTok",
  },
  {
    icon: <WordOfMouth />,
    name: "Word of mouth",
  },
  {
    icon: <OtherIcon />,
    name: "Other",
  },
];

export default OnBoarding2;
