import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import google from "../../assets/img/google.svg";
import Logo from "../../components/Logo";
import Input from "./components/Input";
import { useState } from "react";

import { deleteUser, GoogleAuthProvider, unlink } from "firebase/auth";

import "firebase/auth";
import { AuthContext, useUserContext } from "../../Context/UserContext";
import axios from "axios";

//firebase
const Login = () => {
  const navigate = useNavigate();
  const { user, loading, refetch, setRefetch } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [password, setpassword] = useState("");
  const { login, logout } = useContext(AuthContext);
  const { reset } = useContext(AuthContext);

  const { providerLogin } = useContext(AuthContext);

  async function checkUserSignupMethod(email, firstName, signupMethod) {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_END_POINT}/user/signupMethod`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, firstName }),
      }
    );

    const data = await response.json(); // Parsing the JSON response

    if (!data.exists) {
      // Handle scenario where no user exists
    } else if (!data.signedUpWithGoogle) {
      if (signupMethod !== "email") {
        setError("Please Sigin using your email and password");
      }

      return { continue: signupMethod !== "email" ? false : true };

      // Handle scenario where user did not sign up with Google
    } else {
      setError(
        "You have Signed up using Google , please use the button on the top to signIn "
      );
      return;
      // Handle scenario where user signed up with Google
    }
  }

  const handleGoogleSignIn = () => {
    //  checking if user exist in db

    const googleProvider = new GoogleAuthProvider();

    providerLogin(googleProvider)
      .then(async (result) => {
        // Access the user's email from the result object
        const user = result.user;
        const email = user.email;
        // Access the user's name and profile photo
        const firstName = user.displayName; // User's first name
        const photo = user.photoURL; // User's profile photo URL

        // const signupCheck = await checkUserSignupMethod(
        //   email,
        //   firstName,
        //   "google"
        // );
        // console.log({ signupCheck });

        // if (!signupCheck.continue) {
        //   await unlink(user, "google.com").catch((error) => {
        //     console.error("Failed to unlink:", error);
        //   });
        //   logout();
        //   return;
        // }
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/get/user/${user.email}`
        );

        try {
          if (response.status !== 200) {
            await deleteUser(result.user);

            navigate("/register");
            return;
          }

          if (result) {
            const jobData = {
              words: 0,
              limitWords: 1000,
              email: email.toLowerCase(),
              firstName: firstName,
              company: "",
              location: "",
              // Include the user's photo URL in the jobData object
              photo: photo,
              subscribed: false,
              productName: "Free",
            };

            const userData = {
              email: email,
            };

            try {
              const response = await fetch(
                `${process.env.REACT_APP_SERVER_END_POINT}/api/welcome/sendEmail`,

                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ email, firstName }),
                }
              );

              if (response.ok) {
                ////('Email sent successfully');
                // Handle success as needed
              } else {
                console.error("Failed to send email");
                // Handle error as needed
              }
            } catch (error) {
              console.error("Error sending email", error);
              // Handle error as needed
            }

            const userResponse = await fetch(
              `${process.env.REACT_APP_SERVER_END_POINT}/post/user`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(jobData),
              }
            );

            if (userResponse.ok) {
              setRefetch((prevref) => !prevref);
              // logout();

              navigate("/home");
            } else {
              console.error("POST request failed");
            }
          } else {
            console.error("User creation failed");
          }
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => console.error(error));
  };

  const [error, setError] = useState("");

  const handleLogin = async (event) => {
    try {
      setError(null); // Clear any previous errors
      console.log(1);
      // const signupCheckResult = await checkUserSignupMethod(email, "", "email");
      // console.log({ signupCheckResult }, signupCheckResult.continue);
      // if (!signupCheckResult.continue) {
      //   // If we should not continue, stop the function here
      //   return;
      // }
      const result = await login(email, password);
      if (result?.user.emailVerified) {
        setRefetch((prevref) => !prevref);
        console.log(2);

        navigate("/home");
      } else {
        logout();

        setError(
          "Email not verified. Please check your email for verification."
        );
        return;
      }
    } catch (error) {
      // Handle Firebase authentication errors
      const errorCode = error.code;
      switch (errorCode) {
        case "auth/wrong-password":
          setError("Invalid password. Please try again.");
          break;
        case "auth/invalid-login-credentials":
          setError("Incorrect password. Please try again.");
          break;
        case "auth/invalid-email":
          setError("Enter a valid email.");
          break;
        // Add more cases as needed
        default:
          setError(errorCode);
          break;
      }
    }
  };

  const handleResetPassword = async () => {
    try {
      if (!email) {
        setError("Please enter a valid email.");
      } else {
        setError(); // Clear any previous errors
        await reset(email);
        setError("Password reset email sent successfully.");
      }
    } catch (error) {
      // Handle specific error cases and set appropriate error messages
      if (error.code === "auth/invalid-email") {
        setError("Enter a valid email address.");
      } else if (error.code === "auth/user-not-found") {
        setError("User with this email address not found.");
      } else {
        // Handle other errors as needed
        setError("Error sending password reset email. Please try again later.");
      }
    }
  };

  return (
    <>
      <section className="login-section">
        <div className="container">
          <div className="login-wrapper">
            <Logo />
            <div className="text-center">
              <h3 className="title">Login</h3>
              <div className="subtitle">
                Login to your account with your credentials
              </div>
            </div>

            <button
              onClick={handleGoogleSignIn}
              type="button"
              className="backup-login"
            >
              <img src={google} alt="" /> <span>Sign in with Google</span>
            </button>
            <div className="or">
              <span>Or Sign in with Email</span>
            </div>
            <div className="mb-4">
              <Input
                label="Email Address"
                required
                placeholder="mail@example.com"
                onChange={(e) => setEmail(e.target.value)}
                path="auth"
              />
            </div>
            <Input
              label="Password"
              required
              placeholder="**********"
              type="password"
              eye
              onChange={(e) => setpassword(e.target.value)}
            />
            <div className="d-flex flex-wrap justify-content-between mt-3">
              {/* <label class="form-check form--check flex-grow-1">
                <input class="form-check-input" type="checkbox" />
                <span class="form-check-label">Remember me</span>
              </label> */}
              <Link
                to="#"
                onClick={handleResetPassword}
                className="forget-pass-txt"
              >
                Forgot Password?
              </Link>
            </div>
            <br />
            {error && (
              <h4
                style={{ color: "red" }}
                className="my-3 mt-4 text-center alt-txt"
              >
                {error}{" "}
              </h4>
            )}

            <div className="mt-4 pt-lg-3">
              <button
                className="submit-btn"
                type="button"
                onClick={handleLogin}
                // onClick={() => navigate("/dashboard/home")}
              >
                Login
              </button>
            </div>
            <div className="mt-4 pt-lg-3 text-center alt-txt">
              Not registered ?{" "}
              <Link to="/register" className="text-blue-500">
                Create an account
              </Link>
            </div>
          </div>
          <div className="text-center mt-4 mt-xl-5 txt-sm">
            &copy;{new Date().getFullYear()} r3achout.ai all rights reserved
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
