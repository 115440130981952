import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// Configure pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfReader = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfText, setPdfText] = useState('');

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const buffer = e.target.result;
        // Load the PDF text using pdfjs-dist library
        const loadingTask = pdfjs.getDocument(new Uint8Array(buffer));
        const pdf = await loadingTask.promise;
        
        // Fetch text content from each page
        let fullText = '';
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const text = textContent.items.map((s) => s.str).join(' ');
          fullText += text;
        }

        // Set the PDF text to state
        setPdfText(fullText);
        setNumPages(pdf.numPages);
        setPageNumber(1);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div>
      <input type="file" onChange={onFileChange} accept=".pdf" />
      {numPages && (
        <p>
          Page {pageNumber} of {numPages}
        </p>
      )}
      <Document file={null} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
        <Page pageNumber={pageNumber} />
      </Document>
      <div>
        <p>ss{pdfText}</p>
      </div>
    </div>
  );
};

export default PdfReader;