import React from 'react';
import { useUserContext } from '../../../Context/UserContext';

const PersonalDetails = () => {
    const { userInfo } = useUserContext();

    const tableData = Object.entries(userInfo);

    return (
        <div>
            <h2 style={headerStyle}>User Details</h2>
            <table style={tableStyle}>
                <thead>
                    <tr style={rowStyle}>
                        <th style={cellStyle}>Field</th>
                        <th style={cellStyle}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map(([key, value]) => (
                        <tr key={key} style={rowStyle}>
                            <td style={cellStyle}>{key}</td>
                            <td style={cellStyle}>{value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

// Inline styles
const headerStyle = {
    fontSize: '1.5em',
    color: '#333',
    marginBottom: '10px',
};

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
};

const rowStyle = {
    borderBottom: '1px solid #ddd',
};

const cellStyle = {
    padding: '10px',
    textAlign: 'left',
};

export default PersonalDetails;
