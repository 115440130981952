import React, { useState } from "react";
import {
  CheckIcon,
  DayPassIcon,
  FreeIcon,
  MonthlyIcon,
  UnCheckIcon,
} from "../../../components/Icon";
import SectionHeader from "./SectionHeader";
import { Link } from "react-router-dom";
import { useUserContext } from "../../../Context/UserContext";

const PricingPlan = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const { userInfo } = useUserContext();

  return (
    <section className="pricing-section">
      <div className="pricing-section-top">
        <div id="pricing" className="target-id"></div>
        <div className="container">
          <SectionHeader
            attrTitle="Pricing"
            title="Affordable "
            colorTitle="Pricing Plans"
            subtitle="Accessible Pricing for Everyone. Our pricing plans are designed to meet your specific needs. Whether you’re seeking basic features or comprehensive services, we’ve got you covered."
          />
          {/* <div className="switcher">
            <button className="btn" onClick={() => setIsMonthly(false)}>
              Yearly
            </button>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                checked={isMonthly}
                onChange={(e) => setIsMonthly(e.target.checked)}
              />
            </div>
            <button className="btn" onClick={() => setIsMonthly(true)}>
              Monthly
            </button>
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className="pricing-wrapper">
          <div className="row g-4 justify-content-center   !flex-nowrap flex flex-col md:flex-row">
            {data?.map((item, i) => (
              <div className="col-md-6 col-lg-3" key={i}>
                <div className="plan-card">
                  <div className="plan-card-header">
                    {/* <div className="icon">{item?.icon}</div> */}
                    <div className="cont">
                      <h3 className="name pricing-card-title">{item?.name}</h3>
                      <h5 className="limit pricing-card-limit">
                        {item?.limit}
                      </h5>
                      {/* {item?.creditCard ? (
                        <span className="text text-base">
                          No Credit card required
                        </span>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                  <h2 className="plan-card-pricing">
                    {item?.discount ? (
                      <>
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: "red",
                          }}
                        >
                          $695
                        </span>{" "}
                        {item?.price}
                        <sub>/{item?.duration}</sub>
                      </>
                    ) : (
                      <>
                        {item?.price}
                        <sub>{item?.duration}</sub>
                      </>
                    )}
                  </h2>
                  <h6 className="subtitle">What’s Included</h6>
                  <ul className="feature-txt">
                    {item?.included?.map(({ isIncluded, text }, j) => (
                      <li key={j}>
                        {isIncluded ? <CheckIcon /> : <UnCheckIcon />}
                        <span className="">{text}</span>
                      </li>
                    ))}
                  </ul>
                  <Link
                    to={
                      item.name === "Enterprise"
                        ? "https://calendly.com/meet_nader/demo"
                        : userInfo.email
                        ? "/dashboard"
                        : `/register`
                    }
                    type="button"
                    className="pricing-btn"
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {item?.btnText}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const data = [
  {
    name: "Free",
    limit: "No Credit card required",
    creditCard: true,
    duration: "/month",
    price: "$00",
    btnText: "Sign up for free",
    icon: <FreeIcon />,
    included: [
      {
        isIncluded: true,
        text: "Trained AI for recruiters",
      },
      {
        isIncluded: true,
        text: "All feature enabled",
      },
      {
        isIncluded: true,
        text: "Built-in Editor tool",
      },
      {
        isIncluded: true,
        text: "3 Job projects",
      },
      {
        isIncluded: true,
        text: "1000 words/month",
      },
      {
        isIncluded: true,
        text: "No Support ",
      },
    ],
  },
  {
    name: "Day Pass",
    limit: "Test Driving experience",
    creditCard: false,
    duration: "/day",
    price: "$12",
    priceId: process.env.REACT_APP_DAYPASS_PLAN_ID,

    btnText: "Try it out for a day",
    icon: <DayPassIcon />,
    included: [
      {
        isIncluded: true,
        text: "Trained AI for recruiters",
      },
      {
        isIncluded: true,
        text: "All features enabled",
      },
      {
        isIncluded: true,
        text: "Built-in Editor tool",
      },
      {
        isIncluded: true,
        text: "Sourcing",
      },
      {
        isIncluded: true,
        text: "Enhanced Intake and Interview Questions",
      },
      {
        isIncluded: true,
        text: "10 Job projects",
      },
      {
        isIncluded: true,
        text: "10000 words",
      },
      {
        isIncluded: true,
        text: "Email Support",
      },
    ],
  },

  {
    name: "Silver",
    limit: "Your Comprehensive Solution",
    creditCard: false,
    duration: "/monthly",
    price: "$39",
    priceId: process.env.REACT_APP_SILVER_PLAN_ID,

    btnText: "Get Started",
    icon: <MonthlyIcon />,
    included: [
      {
        isIncluded: true,
        text: "Trained AI for recruiters",
      },
      {
        isIncluded: true,
        text: "All features enabled",
      },
      {
        isIncluded: true,
        text: "Built-in Editor tool",
      },
      {
        isIncluded: true,
        text: "Sourcing",
      },
      {
        isIncluded: true,
        text: "Basic Intake and Interview Questions",
      },
      {
        isIncluded: true,
        text: "25 Job projects",
      },
      {
        isIncluded: true,
        text: "50000 words/month",
      },
      {
        isIncluded: true,
        text: "Email Support",
      },
    ],
  },
  {
    name: "Gold",
    limit: "Get the best of our app !",
    duration: "/annually",
    creditCard: false,
    discount: false,
    price: "$595",
    priceId: process.env.REACT_APP_GOLD_PLAN_ID,

    btnText: "Activate our Top Tier",
    icon: <MonthlyIcon />,
    included: [
      {
        isIncluded: true,
        text: "Trained AI for recruiters",
      },
      {
        isIncluded: true,
        text: "All features enabled",
      },
      {
        isIncluded: true,
        text: "Built-in Editor tool",
      },
      {
        isIncluded: true,
        text: "Sourcing",
      },
      {
        isIncluded: true,
        text: "Enhanced Intake and Interview Questions",
      },
      {
        isIncluded: true,
        text: "75 Job projects",
      },
      {
        isIncluded: true,
        text: "85000 words/month",
      },
      // {
      //   isIncluded: true,
      //   text: "3 Search assistance / Job",
      // },
      {
        isIncluded: true,
        text: "Early access to new features",
      },
      {
        isIncluded: true,
        text: "Email Support",
      },
    ],
  },
  {
    name: "Enterprise",
    limit: "Tailored solutions for your needs",
    creditCard: false,
    // duration: "day",
    // price: "Custom",
    btnText: "Contact Us",
    // icon: <DayPassIcon />,
    included: [
      {
        isIncluded: true,
        text: "Trained AI for recruiters",
      },
      {
        isIncluded: true,
        text: "Trained and Enhanced AI on your hiring data",
      },
      // {
      //   isIncluded: true,
      //   text: "All features enabled",
      // },
      {
        isIncluded: true,
        text: "Built-in Editor tool",
      },
      {
        isIncluded: true,
        text: "Sourcing",
      },
      {
        isIncluded: true,
        text: "Enhanced Intake and Interview Questions",
      },
      {
        isIncluded: true,
        text: "Custom Job projects",
      },
      {
        isIncluded: true,
        text: "Custom words/month",
      },
      {
        isIncluded: true,
        text: "AI detection score",
      },
      {
        isIncluded: true,
        text: "Enhanced Candidate Matching",
      },
      {
        isIncluded: true,
        text: "Custom Branding/Voice",
      },
      {
        isIncluded: true,
        text: "Early access to new features",
      },

      {
        isIncluded: true,
        text: "Custom features development",
      },
      {
        isIncluded: true,
        text: "Business readiness",
      },
      {
        isIncluded: true,
        text: "Training",
      },
      {
        isIncluded: true,
        text: "7 / 24 Support",
      },
    ],
  },
];

export default PricingPlan;
