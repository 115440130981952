import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../Context/UserContext";
import { Link, useNavigate } from "react-router-dom";

const Success = () => {
  const [userData, setUserData] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_END_POINT}/user/accountType?email=${user?.email}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setUserData(data);

        // Save userInfo to local storage
        localStorage.setItem("userInfo", JSON.stringify(data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user?.email]);

  const [countdown, setCountdown] = useState(7);

  useEffect(() => {
    // Update countdown every second
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Cleanup interval to avoid memory leaks
    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  useEffect(() => {
    // Redirect to home page after 7 seconds
    const redirectTimeout = setTimeout(() => {
      navigate("/home");
    }, 7000);

    // Cleanup timeout to avoid memory leaks
    return () => {
      clearTimeout(redirectTimeout);
    };
  }, [navigate]);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#f0f0f0",
  };

  const successTextStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#00a854",
    margin: "20px 0",
  };

  const successImageStyle = {
    maxWidth: "100%",
    height: "auto",
  };

  const invoiceLinkStyle = {
    color: "gray",
  };

  const downloadButtonStyle = {
    padding: "10px",
    background: "#00a854",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "10px",
  };

  const handleDownload = () => {
    // Trigger the download
    window.open(userData.invoicePdfUrl, "_blank");
  };

  return (
    <div style={containerStyle}>
      <div style={successTextStyle}>
        Congratulations! Your payment was successful.
      </div>
      <img
        src="https://funtura.in/tvm/wp-content/themes/funtura/assets/images/success.svg"
        alt=""
        style={successImageStyle}
      />
      <div>Redirecting to the home page in {countdown} seconds...</div>
      <p style={invoiceLinkStyle}>Invoice will download shortly.</p>
      <button style={downloadButtonStyle} onClick={handleDownload}>
        Download Invoice
      </button>
      {userData.invoicePdfUrl && (
        <iframe src={userData.invoicePdfUrl} title="Invoice PDF"></iframe>
      )}
    </div>
  );
};

export default Success;
