import { Accordion } from "react-bootstrap";
import SectionHeader from "../../landing/components/SectionHeader";

const Faqs = () => {
  return (
    <div className="container my-5">
      <SectionHeader
        attrTitle="FAQs"
        title="Frequently Asked"
        colorTitle="Questions"
      />
      <Accordion defaultActiveKey="0" className="accordion-overrides">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="text-white">
            Who is this App designed for?
          </Accordion.Header>
          <Accordion.Body>
            R3achout.AI is built by recruiters, for recruiters or anyone in with
            hiring needs.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>What can the App do?</Accordion.Header>
          <Accordion.Body>
            Our app is comprised of a set of features aimed at copilot the
            recruitment process. Key functionalities include a sophisticated
            search assistant to uncover ideal candidates that fit your job
            descriptions perfectly. It goes beyond basic search to help you
            refine job listings, making them more attractive to potential
            candidates. The App also aids in drafting effective LinkedIn
            messages and cold emails, enhancing your communication with
            candidates, and increasing response rates. For a deeper
            understanding of your hiring manager's needs, it provides insightful
            questions for intake meetings. Moreover, it helps in the screening
            of candidates, ensuring a high-quality candidate pool. Continuous
            improvements and new features are regularly added based on user
            feedback and market trends, making the App an ever-evolving tool for
            recruiters.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Is the App Free to Use?</Accordion.Header>
          <Accordion.Body>
            Yes. As a free member, you can create job projects and generate
            content up to your monthly allowance. This allowance resets every
            month on your account renewal date.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Can I cancel anytime?</Accordion.Header>
          <Accordion.Body>
            Yes. Cancellation is straightforward and hassle-free. You can cancel
            your subscription at any time through our Stripe customer portal,
            ensuring you have complete control over your subscription choices.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Do you offer channels for feedback and support?
          </Accordion.Header>
          <Accordion.Body>
            Yes. We are committed to continuous improvement and value your
            feedback highly. A dedicated feedback channel is integrated within
            the app for ease of communication. Feel free to share your thoughts
            or for any assistance, and our team will respond promptly to address
            your needs.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Faqs;
