import React, { useState } from "react";

import userImg from "../../../assets/img/user-img-2.png";
import { CameraIcon } from "../../../components/Icon";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../Context/UserContext";
import axios from "axios";

const ImageUpload = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setloading] = useState(false);

  const { user, userInfo, handleAction } = useContext(AuthContext);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setloading(true);
      const formdata = new FormData();
      formdata.append("image", file);

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_END_POINT}/upload-profile?email=${userInfo?.email}`,
        // `http://localhost:3001/upload-profile?email=${userInfo?.email}`,
        formdata
      );
      setSelectedImage(response.data.image);
      handleAction(1);
      setloading(false);
    }
  };
  return (
    <>
      <div className="upload-img">
        {loading ? (
          <p>Uploading..</p>
        ) : (
          <img
            src={
              selectedImage
                ? selectedImage
                : userInfo?.imgupload
                ? userInfo.imgupload
                : user
                ? user.photoURL
                : null
            }
            alt=""
          />
        )}
        <label className="upload-icon">
          <input
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            className="d-none"
            onChange={handleImageChange}
          />
          <CameraIcon />
        </label>
      </div>
    </>
  );
};

export default ImageUpload;
