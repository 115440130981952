import axios from "axios";

const apiKey = process.env.REACT_APP_OPEN_AI_KEY;
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${apiKey}`,
};

const searchAi = async (jobData) => {
  const booleanResponse = localStorage.getItem("booleanResponse");

  const prompts = [
    {
      //Candidate Persona
      promptName: "Candidate persona",
      description: `
      Summarize the following job description and explain to a recruitment team the ideal candidate and ignore the location criteria and any education requirement.
        job description:  ${jobData.savedJobDetails} ||  ${jobData.savedJobTitle}`,
      token: 500,
      gpt: 4,
    },
    // {
    //   //
    //   description: `You are an expert in recruitment using LinkedIn as your search engine platform. Analyze the following job description and provide a Boolean search string to find a potential candidate with the most relevant skills. Omit the location criteria.
    // Job description:  ${jobData.savedJobDetails} else  ${jobData.savedJobTitle}`,
    //   token: 500,
    // },
    // {
    //   system: "Act as an expert in technology to assist the user.",
    //   description: `Based on the following job description, as a technology expert, identify and list the Technology Stack. Ensure to list only technologies and nothing else, the output needs to remain technology focused. Group the list in tiers. Do not provide an introduction, simply provide a list. If no technology is found in the job description, then output: The job description provided doesn't offer technologies to evaluate.
    // Job description:  ${jobData.savedJobDetails} ||  ${jobData.savedJobTitle}`,
    //   token: 1000,
    // },

    // {
    //   description: `Based on the following job description, as an expert in the recruitment field, write a list of possible Job Titles related to this position. Do not provide an introduction, simply list the Job Titles.
    // Job description:  ${jobData.savedJobDetails} ||  ${jobData.savedJobTitle}`,
    //   token: 500,
    // },

    // {
    //   description: `Based on the following job description, what are some industry-specific job boards we might want to consider to find relevant candidates. Do not provide an introduction, simply list them and their website addresses.
    // Job description:  ${jobData.savedJobDetails} ||  ${jobData.savedJobTitle}`,
    //   token: 500,
    // },

    // //
    // {
    //   description: `Based on the following job description, list relevant skills or keywords, not found in this  boolean search string, ${booleanResponse}, to find the best candidate and help narrow the search. Do not provide an introduction nor an explanation, simply list the relevant keywords.
    //     ${jobData.savedJobDetails} ||  ${jobData.savedJobTitle}
    //    `,
    //   token: 500,
    // },

    // {
    //   system:
    //     "As a technology expert, assist the user to uncover industry-known transferable technologies based on a job description.",
    //   description: `Based on the following job description, what are the transferable technologies or similar technologies known in the industry. Output the response in the following format: For example: TypeScript, Javascript  ===>  CoffeeScript. List the technologies from the job description on the left and transferable or similar technologies to the right. Do not repeat the transferable or similar technologies, instead group them in a logical manner like the example. Provide the reader with a heading such as; Technologies from the Job Description ===> Transferable Technologies. Do not provide an introduction, simply provide the data. Keep the output strictly focused on technology, nothing else. Add an empty line to separate the heading from the output. Present the information logically and in an easy to read manner. If no technology is found in the job description, then output: The job description provided doesn't offer technologies to evaluate.
    // Job description:  ${jobData.savedJobDetails} ||  ${jobData.savedJobTitle}`,
    //   token: 1000,
    // },
  ];

  try {
    const completions = await Promise.all(
      prompts.map(async (prompt, index) => {
        let completion;

        if (prompt.gpt === 4) {
          let data4 = {
            model: "gpt-4-1106-preview",
            messages: [
              {
                role: "user",
                content: prompt.description,
              },
            ],
            temperature: 1,
            max_tokens: prompt.token,
            top_p: 1,
          };

          if (prompt.system) {
            data4.messages.push({ role: "system", content: prompt.system });
          }

          await axios
            .post("https://api.openai.com/v1/chat/completions", data4, {
              headers,
            })
            .then((response) => {
              completion = response.data.choices[0].message.content.trim();
            });
        } else {
          let data3 = {
            model: "gpt-3.5-turbo-1106",
            prompt: prompt.description,
            max_tokens: prompt.token,
            temperature: 1,
            top_p: 1,
          };

          if (prompt.system) {
            data3.messages.push({ role: "system", content: prompt.system });
          }

          await axios
            .post("https://api.openai.com/v1/completions", data3, {
              headers,
            })
            .then((response) => {
              completion = response.data.choices[0].text.trim();
              if (index === 1) {
                localStorage.setItem(
                  "booleanResponse",
                  response.data.choices[0].text.trim()
                );
              }
            });
        }

        // if (index === 2 || index >= prompts.length - 2) {
        //   console.log("inside gpt 4", prompt.promptName);

        //   let data4 = {
        //     model: "gpt-4-1106-preview",
        //     messages: [
        //       {
        //         role: "user",
        //         content: prompt.description,
        //       },
        //     ],
        //     temperature: 1,
        //     max_tokens: prompt.token,
        //     top_p: 1,
        //   };

        //   if (prompt.system) {
        //     data4.messages.push({ role: "system", content: prompt.system });
        //   }

        //   console.log({ data4 });

        //   await axios
        //     .post("https://api.openai.com/v1/chat/completions", data4, {
        //       headers,
        //     })
        //     .then((response) => {
        //       completion = response.data.choices[0].message.content.trim();
        //     });
        // } else {
        //   console.log("inside gpt 3", prompt.promptName);
        //   let data3 = {
        //     model: "gpt-3.5-turbo-instruct",
        //     prompt: prompt.description,
        //     max_tokens: prompt.token,
        //     temperature: 1,
        //     top_p: 1,
        //   };

        //   if (prompt.system) {
        //     data3.messages.push({ role: "system", content: prompt.system });
        //   }

        //   await axios
        //     .post("https://api.openai.com/v1/completions", data3, {
        //       headers,
        //     })
        //     .then((response) => {
        //       completion = response.data.choices[0].text.trim();
        //       if (index === 1) {
        //         console.log(
        //           "inside boolean",
        //           response.data.choices[0].text.trim()
        //         );
        //         localStorage.setItem(
        //           "booleanResponse",
        //           response.data.choices[0].text.trim()
        //         );
        //       }
        //     });
        // }

        return {
          //   prompt: prompt.name,
          response: completion,
        };
      })
    );

    return completions;
  } catch (error) {
    console.error(error);
  }
};

const reachoutTab = async (jobData) => {
  try {
    const skills = jobData.skills;
    let systemContent = "";
    const LinkedInsystemContent = `Assist the user with writing a LinkedIn email to a candidate to attract them for an open position.
    Adopt the persona of ${jobData.persona}  and use a${jobData.Tonality}  tonality. Do not mention your persona nor that you are a recruiter. "		
    

    `;
    const ColdemailsystemContent = `Assist the user with writing an email to a candidate to attract them for an open position.
  
    
`;

    const conversation = `"You are an expert in recruitment, possess the skills of a talented writer and an outstanding communication style. Assist me with writing an email to a candidate to attract them for an open position.
    
    
`;
    const Network = `You are an expert in recruitment, possess the skills of a talented writer and an outstanding communication style.		

`;

    // The goal is to engage in a conversation with the candidate. The candidate's name is ${jobData.name}	. Adopt the persona of ${jobData.persona}  and use a ${jobData.Tonality} tonality. Do not mention your persona nor that you are a recruiter.
    // Acknowledge their skills as a ${jobData.savedJobTitle}.
    // Emphasize their evident involvement and contribution given the size of projects they have worked on, obvious dedication, commitment, and ${skills}. Recognize they would be a great asset to your organization and team.
    // Explain that judging by their background and experience, it would not surprise you if they are often approached for opportunities.
    // Ask the candidate a question: When considering a role, what's important to you, before making such an important decision?
    // Briefly describe your company culture and several benefits the company offers when joining the company, including a competitive salary.
    // Offer to have quick conversation.
    // Close by asking them to let you know when they are free to talk and express that you look forward to their response.""
    // Sign  ${jobData.firstName}

    // Add conditional logic to customize systemContent based on jobData.emailType
    if (jobData.emailType === "LinkedIn InMail") {
      systemContent = `${LinkedInsystemContent}`;
    } else if (jobData.emailType === "Cold email") {
      systemContent = `${ColdemailsystemContent}`;
    } else if (jobData.emailType === "Conversation Starter") {
      systemContent = `${conversation}`;
    } else if (jobData.emailType === "Network Expansion") {
      systemContent = `${Network}`;
    } else {
      // Handle other cases if needed
      systemContent = "Default system content";
    }
    let userContent = "";
    const linkedinuser = !skills
      ? `
    The goal is to engage in a conversation with the candidate. The candidate's name is ${jobData.name}. Adopt the persona of ${jobData.persona} and use a ${jobData.Tonality} tone. Do not mention your persona nor that you are a recruiter.
    Acknowledge their skills as a ${jobData.savedJobTitle}.Quickly acknowledge the candidate's expertise in a single sentence that combines admiration with relevance to your needs.
    Emphasize their evident involvement and contribution given the size of projects they have worked on, obvious dedication, and commitment. Recognize they would be a great asset to your organization and team. Mention what sets your company apart, focusing on unique opportunities or aspects of the company culture.
    Ask a pointed question that invites the candidate to share their priorities or interests, making it clear that their response will shape the conversation.
    Propose a specific time for a conversation, making it easy for them to say yes or suggest an alternative, and express that you look forward to their response.
    Sign ${jobData.firstName}
    
    
    `
      : `
      The goal is to engage in a conversation with the candidate. The candidate's name is ${jobData.name}. Adopt the persona of ${jobData.persona} and use a ${jobData.Tonality} tone. Do not mention your persona nor that you are a recruiter.
Acknowledge their skills as a ${jobData.savedJobTitle}. Quickly acknowledge the candidate's expertise in a single sentence that combines admiration with relevance to your needs.
Emphasize their evident involvement and contribution given the size of projects they have worked on, obvious dedication, commitment, and ${skills}. Recognize they would be a great asset to your organization and team. Mention what sets your company apart, focusing on unique opportunities or aspects of the company culture.
Ask a pointed question that invites the candidate to share their priorities or interests, making it clear that their response will shape the conversation.
Propose a specific time for a conversation, making it easy for them to say yes or suggest an alternative, and express that you look forward to their response.
Sign ${jobData.firstName}

`;

    const coldemailUser = !skills
      ? `The goal is to engage in a conversation with the candidate. The candidate's name is ${jobData.name}. Adopt the persona of ${jobData.persona} and use a ${jobData.Tonality} tone. Do not mention your persona nor that you are a recruiter.
    Acknowledge their skills as a ${jobData.savedJobTitle}.Quickly acknowledge the candidate's expertise in a single sentence that combines admiration with relevance to your needs.
    Emphasize their evident involvement and contribution given the size of projects they have worked on, obvious dedication, and commitment. Recognize they would be a great asset to your organization and team. Mention what sets your company apart, focusing on unique opportunities or aspects of the company culture.
    Ask a pointed question that invites the candidate to share their priorities or interests, making it clear that their response will shape the conversation.
    Propose a specific time for a conversation, making it easy for them to say yes or suggest an alternative, and express that you look forward to their response.
    Sign ${jobData.firstName}`
      : `
The goal is to engage in a conversation with the candidate. The candidate's name is ${jobData.name}. Adopt the persona of ${jobData.persona} and use a ${jobData.Tonality} tone. Do not mention your persona nor that you are a recruiter.
Acknowledge their skills as a ${jobData.savedJobTitle}. Quickly acknowledge the candidate's expertise in a single sentence that combines admiration with relevance to your needs.
Emphasize their evident involvement and contribution given the size of projects they have worked on, obvious dedication, commitment, and ${skills}. Recognize they would be a great asset to your organization and team. Mention what sets your company apart, focusing on unique opportunities or aspects of the company culture.
Ask a pointed question that invites the candidate to share their priorities or interests, making it clear that their response will shape the conversation.
Propose a specific time for a conversation, making it easy for them to say yes or suggest an alternative, and express that you look forward to their response.
Sign ${jobData.firstName}

`;

    const conversationUser = `
    "The goal is to have a conversation with the candidate. Write an enticing and compelling email to engage with the candidate. Their name is ${jobData.name}and this is for a potential${jobData.savedJobTitle}
    position. Adopt the persona of ${jobData.persona} and use a ${jobData.Tonality}  tonality. Do not mention your persona nor that you are a recruiter.  You are intrigued by the candidate’s ${skills} and you would be
    interested to learn more about their experiences. Start by explaining to the candidate how their experiences sparked your interests. Mention to the candidate that you are sincerely interested and curious
    to learn about what’s important to them when considering a new role. Acknowledge that making a career move can sometimes be stressful, and being strategic in shaping ones career is something you don't take lightly.
    There is a definite need to align with the right opportunity. This is where you can partner together. Ask the candidate to get on a call with you to discuss what they would ideally look for in their next career move
    and to see if we can meet their expectations.
    Sign   ${jobData.firstName}		
    
`;

    const networkuser = `The goal is to attract a potential candidate, their name is${jobData.name}, for an upcoming ${jobData.savedJobTitle} position.
     Adopt the persona of ${jobData.persona} when writing the email. The candidate’s skills and experience of interest are ${skills}, 
     and you would be interested to learn about their experiences. Write an enticing and compelling email to connect with the candidate. 
     The email should focus on expanding your network and understanding what the candidate would consider a golden opportunity for a career move.
      Use a ${jobData.Tonality}tone when writing the email. Start by explaining to the candidate how being proactive might be a good way to ease into a transition
       and be strategic in timing their next project or role. Explain to the candidate that there are no obligations with expanding their network but possibly
        increasing their earning potential and project exposure. Tell the candidate that you would not be surprised if they are often approached for opportunities,
         and that you are genuinely interested and curious to learn about what’s important to them before considering a new role. Do not mention that you are a recruiter nor your persona.
          Sign${jobData.firstName}		

`;

    // Add conditional logic to customize userContent based on jobData.emailType
    if (jobData.emailType === "LinkedIn InMail") {
      userContent = `${linkedinuser}`;
    } else if (jobData.emailType === "Cold email") {
      userContent = `${coldemailUser}`;
    } else if (jobData.emailType === "Conversation Starter") {
      userContent = `${conversationUser}`;
    } else if (jobData.emailType === "Network Expansion") {
      userContent = `${networkuser}`;
    }

    const data = {
      model: "gpt-4-1106-preview",
      messages: [
        { role: "system", content: systemContent },
        {
          role: "user",
          content: userContent,
        },
      ],

      top_p: 0.8,
      max_tokens: 1000,
    };

    let completion;

    await axios
      .post("https://api.openai.com/v1/chat/completions", data, {
        headers,
      })
      .then((response) => {
        completion = response.data.choices[0].message.content.trim();
      });

    return completion;
  } catch (error) {
    console.error("Error:", error);
  }
};

const intakeTab = async (jobData) => {
  try {
    const systemContent =
      "You are an expert in recruitment. Assist the user with questions to ask during a meeting. Your goal is to gather more information about the ideal candidate for the job.​";

    // The goal is to engage in a conversation with the candidate. The candidate's name is ${jobData.name}	. Adopt the persona of ${jobData.persona}  and use a ${jobData.Tonality} tonality. Do not mention your persona nor that you are a recruiter.
    // Acknowledge their skills as a ${jobData.savedJobTitle}.
    // Emphasize their evident involvement and contribution given the size of projects they have worked on, obvious dedication, commitment, and ${skills}. Recognize they would be a great asset to your organization and team.
    // Explain that judging by their background and experience, it would not surprise you if they are often approached for opportunities.
    // Ask the candidate a question: When considering a role, what's important to you, before making such an important decision?
    // Briefly describe your company culture and several benefits the company offers when joining the company, including a competitive salary.
    // Offer to have quick conversation.
    // Close by asking them to let you know when they are free to talk and express that you look forward to their response.""
    // Sign  ${jobData.firstName}

    // Add conditional logic to customize systemContent based on jobData.emailType

    let userContent = "";

    // console.log(
    //   { jobData },
    //   jobData.intakeQstType === "-" ? "Intake Questions" : jobData.intakeQstType
    // );

    // Hiring manager

    const hiringManager = `Analyze the following job description
    and list the ${
      jobData.intakeQst
    } best intake questions to ask during an upcoming
     meeting with the hiring manager regarding the ${
       jobData.intakeRole === "-" ? "Junior" : jobData.intakeRole
     } role 
     they are looking to hire. Tailor the questions to gain insights on 
     aspects such as Skill Fit, Experience, Cultural Fit, Team Dynamics,
      Potential for Growth. These questions should help the user assess 
      and are intended to deepen understanding of the ideal candidate's
       profile from their perspective. The questions should be crafted
        to align with the hiring manager’s understanding of the role,
         responsibilities, and who best fits the role. ${
           jobData.intakeQstType === "-"
             ? "Intake Questions"
             : jobData.intakeQstType
         } Use open-ended
          questions whenever possible. Do not provide an introduction 
          to your response, only list the questions and answers. Make sure to output the questions
            with a blank line in between each question.
    Job description: ${jobData.savedJobDetails} else ${jobData.savedJobTitle}"		
   

   `;

    //   Human Resources
    const humanResources = `Analyze the following job description and list the ${
      jobData.intakeQst
    } 
    best intake
    questions to ask during an upcoming meeting with the human resources 
    person regarding the ${
      jobData.intakeRole === "-" ? "Junior" : jobData.intakeRole
    } role they are looking to hire. 
    Tailor the questions to gain insights on aspects such as Organizational
     Fit, Compliance, Diversity and Inclusion, Employee Lifecycle, Benefit 
     and Compensation Needs, Onboarding Requirements. These questions 
     should help the user assess and are intended to deepen understanding 
     of the ideal candidate's profile from their perspective. 
     The questions should be crafted to align with the human resources 
     
     person’s understanding of the role, responsibilities, and who best 
     fits the role.  ${
       jobData.intakeQstType === "-"
         ? "Intake Questions"
         : jobData.intakeQstType
     } Use open-ended questions whenever possible. 
     Do not provide an introduction 
     to your response, only list the questions and answers. Make sure to 
     output the questions with a blank line in between each question.

     Job description: ${jobData.savedJobDetails} else ${
      jobData.savedJobTitle
    }"		`;

    // Decision maker
    const decisionMaker = `Analyze the following job description and list 
   the ${
     jobData.intakeQst
   } best intake questions to ask during an upcoming meeting 
   with the decision maker regarding the ${
     jobData.intakeRole === "-" ? "Junior" : jobData.intakeRole
   } role they are 
   looking to hire. Tailor the questions to gain insights on aspects such 
   as Strategic Fit, ROI on Hire, Leadership Qualities, Innovation and 
   Creativity, Business Acumen. These questions should help the user 
   assess and are intended to deepen understanding of the ideal candidate's
    profile from their perspective. The questions should be crafted to 
    align with the decision maker’s understanding of the role, ${
      jobData.intakeQstType === "-" ? "Intake Questions" : jobData.intakeQstType
    } 
    responsibilities, and who best fits the role. Use open-ended questions
     whenever possible.Do not provide an introduction 
     to your response, only list the questions and answers. Make sure to output the questions with a blank line in 
     between each question. 
     Job description: ${jobData.savedJobDetails} else ${
      jobData.savedJobTitle
    }"	`;

    //  Client
    const client = `Analyze the following job description and list the ${
      jobData.intakeQst
    }
 best intake questions to ask during an upcoming meeting with the client 
 regarding the ${
   jobData.intakeRole === "-" ? "Junior" : jobData.intakeRole
 } role they are looking to hire. Tailor the 
 questions to gain insights on aspects such as role requirements, project 
 objectives, culture fit, strategic alignment, business goals, innovation 
 and problem-solving, work ethics, past performance, budget alignment, ROI,
  communication skills, onboarding process. These questions should help the 
  user assess and are intended to deepen understanding of the ideal 
  candidate's profile from their perspective. The questions should be
   crafted to align with the client’s understanding of the role, ${
     jobData.intakeQstType === "-" ? "Intake Questions" : jobData.intakeQstType
   } 
   responsibilities, and who best fits the role. Use open-ended questions 
   whenever possible.Do not provide an introduction 
   to your response, only list the questions and answers. Make sure to output the questions with a blank line in 
   between each question. 
   Job description: ${jobData.savedJobDetails} else ${jobData.savedJobTitle}"	`;

    if (
      jobData.userInfo.productName !== "Gold" &&
      jobData.userInfo.productName !== "bronze"
    ) {
      userContent = `Analyze the job description and list the best ${jobData.intakeQst} intake questions for the role you are
       recruiting for. Help the user gain a better understanding of the ideal candidate for the following job
       . Use open-ended questions whenever possible. In addition, this exercise is to build a partnership with the 
       hiring manager and to demonstrate your understanding of the role. Do not give an introduction,
        simply list the questions. Make sure to output the questions with a 
        blank line in between each question. 
      
    Job description: ${jobData.savedJobDetails} else ${jobData.savedJobTitle}`;
    } else {
      // Add conditional logic to customize userContent based on jobData.emailType
      if (
        jobData.meetingAttendee === "Hiring Manager" ||
        jobData.meetingAttendee === "-"
      ) {
        userContent = `${hiringManager}`;
      } else if (
        jobData.meetingAttendee === "Human Resources person" ||
        jobData.meetingAttendee === "-"
      ) {
        userContent = `${humanResources}`;
      } else if (jobData.meetingAttendee === "Decision Maker") {
        userContent = `${decisionMaker}`;
      } else if (
        jobData.meetingAttendee ===
        "Client, representing an organization with hiring needs"
      ) {
        userContent = `${client}`;
      }
    }

    // console.log(
    //   jobData.meetingAttendee,
    //   jobData.intakeRole === "-" ? "Junior" : jobData.intakeRole,
    //   { userContent }
    // );

    const data = {
      model: "gpt-4-1106-preview",
      messages: [
        { role: "system", content: systemContent },
        {
          role: "user",
          content: userContent,
        },
      ],

      top_p: 0.8,
      max_tokens: 1000,
    };

    let completion = [];

    const response0 = await axios
      .post("https://api.openai.com/v1/chat/completions", data, {
        headers,
      })
      .then((response) => {
        completion.push(response.data.choices[0].message.content.trim());
      });

    //  profile refinement

    const profileSystem =
      "You are an expert in recruitment. Assist the user with questions to ask during a meeting. Your goal is to gather more information about the ideal candidate for the job";

    //  Get
    const prompt2 = `Based on the following job description, provide the user with  ${
      jobData.intakeQst
    }
      deep dive questions to ask the ${
        jobData.meetingAttendee === "-"
          ? "Hiring Manager"
          : jobData.meetingAttendee
      }  
     After having asked an initial set of questions, the focus should be to narrow down 
     the ideal and best-fit qualified candidate, without repeating any of the initial 
     questions.Do not provide an introduction 
     to your response, only list the questions and answers. Output the questions
      with a blank line in between each question.
     
       Initial questions: ${response0}
       Job description: ${jobData.savedJobDetails} else ${
      jobData.savedJobTitle
    } `;

    const dataa = {
      model: "gpt-4-1106-preview",
      messages: [
        { role: "system", content: profileSystem },
        {
          role: "user",
          content: prompt2,
        },
      ],

      top_p: 0.8,
      max_tokens: 1000,
    };

    // const response1 = await axios
    //   .post("https://api.openai.com/v1/chat/completions", dataa, {
    //     headers,
    //   })
    //   .then((response) => {
    //     completion.push(response.data.choices[0].message.content.trim());
    //   });
    // console.log({ response1 });

    // console.log({ completion });
    return completion;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const OpenAiApi = {
  searchAi,
  reachoutTab,
  intakeTab,
};
