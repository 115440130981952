import React from "react";
import DashboardLayout from "../../../components/DashboardLayout";
import JdRewrite from "./components/JdRewrite";
import JobSingleLayout from "./components/JobSingleLayout";
import { useUserContext } from "../../../Context/UserContext";

const JdRewritePage = () => {
	const { FullJobData, handleJobData } = useUserContext();
 
	// Define breadcrumbs here, after FullJobData is available
	const breadcrumbs = [
		{
			name: "My Jobs",
		},
		{
			name: FullJobData[0]?.name,
		},
		{
			name: "Job description rewrite",
		},
	];
	return (
		<>
			<DashboardLayout breadcrumbs={breadcrumbs}>
				<JobSingleLayout>
					<JdRewrite />
				</JobSingleLayout>
			</DashboardLayout>
		</>
	);
};



export default JdRewritePage;
