import React from "react";
import img1 from "../../../assets/img/screenshort/job.PNG";
import jd from "../../../assets/img/screenshort/jd.PNG";
import img2 from "../../../assets/img/feature/feature2.png";
import {
  AnalyzeIcon,
  ConvIcon,
  GenerateIcon,
  InhanceIcon,
  NetworkIcon,
  QuestionIcon,
} from "../../../components/Icon";
import SectionHeader from "./SectionHeader";
const Feature = () => {
  return (
    <section className="feature-section">
      <div id="benefit" className="target-id"></div>
      <div className="container">
        <SectionHeader
          attrTitle="Feature"
          title="Our Main"
          colorTitle="Features"
          subtitle="With R3achout.AI's core features, recruiters can simplify their email outreach, streamline their processes, and achieve unparalleled success in attracting, engaging, and qualifying candidates."
        />
        <div>
          {data?.map(({ img, icon, title, text }, i) => (
            <div className="feature-item" key={i}>
              <img className="feature-img" src={img} alt="" />
              <div className="feature-cont">
                <div className="header-area d-flex align-items-center">
                  <div className="icon">{icon}</div>
                  <h3 className="title m-0">{title}</h3>
                </div>
                <div className="text">{text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
const data = [
  {
    img: "/candidate.svg",
    // icon: <NetworkIcon />,
    title: "Discover Talent Faster",
    text: `Say goodbye to the Keyword Cloud and hello to precision hiring. Expand your reach and find the perfect fit. Sourcing has never been easier. Experience the future of hiring today.`,
  },
  {
    img: "/engage.svg",
    // icon: <ConvIcon />,
    title: "Boost Your Candidate Engagement  ",
    text: `Transform your recruitment communication into compelling, relevant, and impactful dialogues. Stand out with personalized messages that resonate with candidates, sparking their interest and engagement. Build meaningful relationships and secure your next hire with ease.  
    `,
  },

  {
    img: "/interview.svg",
    // icon: <QuestionIcon />,
    title: "Master the Art of Inquiry",
    text: `In the diverse world of recruitment, the secret to understanding every candidate is asking the right questions, even outside your field of expertise. With R3achout.AI, every interview becomes a strategic step towards the perfect hire, regardless of the nature of the role.`,
  },
];
export default Feature;
