// Chatgpt.js
import React, { useState } from "react";
import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPEN_AI_KEY,
  dangerouslyAllowBrowser: true,
});
// ... (other imports)

const sendRes = async (prompt) => {
  const systemContent = prompt.description;
  const userContent = prompt.userInput;

  try {
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo-16k",
      messages: [
        { role: "system", content: systemContent },
        { role: "user", content: userContent },
      ],
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });

    return response.choices[0].message.content;
  } catch (error) {
    console.error("Error fetching response:", error);
    return "Error fetching response";
  }
};

const ChatGPT = () => {
  const [userInput, setUserInput] = useState("");
  const [botResponse, setBotResponse] = useState(""); // Change to a single response

  const handleUserInput = async () => {
    try {
      const prompt = {
        description: `
          You are an expert in recruitment. Your goal is to assist me during an interview with a candidate details.
        `,
        userInput: userInput,
      };

      const response = await sendRes(prompt);
      setBotResponse(response);
    } catch (error) {
      console.error("Error handling user input:", error);
    }
  };

  return (
    <div>
      <div>
        <p>Response: {botResponse}</p>
      </div>

      <input
        type="text"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
      />
      <button onClick={handleUserInput}>Send</button>
    </div>
  );
};

export default ChatGPT;
