import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const UserList = () => {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          // "https://nadereach-server.vercel.app/get/userlist"
          `${process.env.REACT_APP_SERVER_END_POINT}/get/userlist`
        );
        const data = await response.json();
        setUserList(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []); // Run once when the component mounts

  return (
    <div>
      <h2>User List</h2>
      <table className="user-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Paid</th>
            <th>Subscription ID</th>
            <th>Package Name</th>
            <th>Email</th>
            <th>Recent Login Time</th>
            <th>Company</th>
            <th>Hear</th>
            <th>Title</th>
            <th>Words</th>
            <th>Limit Words</th>
            <th>Location</th>
            <th>Photo</th>
          </tr>
        </thead>
        <tbody>
          {userList.map((user) => (
            <tr key={user._id}>
              <td>{user._id}</td>
              <Link to={`/DetailsUser/${user?.email}`}>
                {" "}
                <td>{user.firstName}</td>
              </Link>

              <td>
                {" "}
                <Link to={`${user.invoicePdfUrl}`}>
                  {user.amount_paid}
                </Link>{" "}
              </td>
              <td>{user.customer_id}</td>
              <td>{user.productName}</td>
              <td>{user.email}</td>
              <td>
                {moment(user.loginTime || user.createdAccount).format(
                  "DD/MM/YYYY hh:mm:ss A"
                )}
              </td>
              <td>{user.company}</td>
              <td>{user.title}</td>
              <td>{user.hear && user.hear.name}</td>
              <td>{user.words}</td>
              <td>{user.limitWords}</td>
              <td>{user.location}</td>
              <td>
                <img
                  src={user.photo}
                  alt={user.firstName}
                  className="user-photo"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
