import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Close, Open } from "./Icon";
import { AuthContext } from "../Context/UserContext";
import axios from "axios";
import DOMPurify from "dompurify";
import { Resizable } from "react-resizable";
import TestResizable from "./resize";

const TextEditor = ({
  value,
  setValue,
  title: newEditorTitle,
  title: editorTitle,
  get,
  post,
  post: tab,
  open,
  setOpen,
}) => {
  // console.log("titletitletitletitletitletitle", editorTitle);
  const { userInfo, projectId, user } = useContext(AuthContext);
  const [isDirty, setDirty] = useState(false);

  const [data, setData] = useState(null);
  const [inputBox, setInput] = useState("");
  const [editorList, setEditorList] = useState([]);
  const len = editorTitle + (editorList.length + 1).toString();
  const [title, setTitle] = useState(len);
  const [editorId, setEditorId] = useState("");

  const [deleteId, setDeleteId] = useState("");
  const [savePressed, setsavepressed] = useState(false);
  const [width, setWidth] = React.useState(400);
  const [height, setHeight] = React.useState(200);

  const handleResize = (event, { size }) => {
    console.log("resizing");
    setWidth(size.width);
    setHeight(size.height);
  };
  // console.log("deleteId", deleteId, inputBox);

  // console.log("inputBoxinputBox", inputBox);
  const quillRef = useRef(null);

  const handleDrop = useCallback((event) => {
    event.preventDefault();

    const quillInstance = quillRef.current?.getEditor();
    if (!quillInstance) return;

    const cursorPosition =
      quillInstance.getSelection()?.index ?? quillInstance.getLength();
    const htmlContent = event.dataTransfer.getData("text/html");
    const plainTextContent = event.dataTransfer.getData("text/plain");

    // Use DOMPurify to sanitize the HTML content
    const cleanHTML = DOMPurify.sanitize(htmlContent);

    if (htmlContent) {
      // If there's HTML content, insert the sanitized HTML
      quillInstance.clipboard.dangerouslyPasteHTML(cursorPosition, cleanHTML);
    } else if (plainTextContent) {
      // If only plain text is available, insert it directly
      quillInstance.insertText(cursorPosition, plainTextContent);
    }
  }, []);

  useEffect(() => {
    const quillInstance = quillRef.current?.getEditor();
    if (quillInstance) {
      const editorRoot = quillInstance.root;
      editorRoot.addEventListener("drop", handleDrop);
      quillInstance.keyboard.addBinding(
        {
          key: "A",
          shortKey: true, // true for Ctrl, false for Cmd on Mac
        },
        (range, context) => {
          quillInstance.setSelection(0, quillInstance.getLength());
          return false; // Prevent the default handler
        }
      );
      // Cleanup: Remove the event listener on component unmount or re-render
      return () => {
        editorRoot.removeEventListener("drop", handleDrop);
      };
    }
  }, [handleDrop]);

  useEffect(() => {
    setIsSaving(true);

    if (!savePressed && editorList.length > 0) {
      // Get the last item from the editorList
      const lastEditor = editorList[editorList.length - 1];

      // Extract values from the last item
      const { title, inputBox, _id, editorId } = lastEditor;

      // console.log("from use effect", editorList);
      setTitle(title);
      setInput(inputBox || "");
      setEditorId(editorId);
      setDeleteId(_id);
    }
    setIsSaving(false);
  }, [editorList]);

  useEffect(() => {
    setInput(value);
  }, [value]);

  const generateRandomKey = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomKey = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomKey += characters.charAt(randomIndex);
    }

    return randomKey;
  };

  const generateEditorId = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(generateRandomKey(10));
      }, 1000); // Adjust the delay time as needed
    });
  };

  const [isSaving, setIsSaving] = useState(false);

  const handlesave = async () => {
    setIsSaving(true);
    // setsavepressed(true);
    // const editorId = await generateEditorId();
    const editorIdNew = await generateEditorId();

    const boldText = inputBox.replace(
      /\*\*(.*?)\*\*/g,
      (match, p1) => `<b>${p1}</b>`
    );

    const userData = {
      inputBox: boldText,
      title,
      editorId: editorId || editorIdNew,
      projectId,
      user: user?.email, // Replace with actual user email
      tab,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_END_POINT}/post/editor/save`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (response.ok) {
        fetchEditorList();
        // setDirty(false);
      }
      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      const result = await response.json();
      // console.log(result);
    } catch (error) {
      console.error("Error:", error);
    }
    setIsSaving(false);
  };

  const handlnew = async () => {
    setsavepressed(false);
    // setsavepressed(false)
    setInput("");
    setIsSaving(true);

    const editorId = await generateEditorId();

    const userData = {
      title: len || title,
      editorId,
      projectId,
      user: user?.email, // Replace with actual user email
      tab,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_END_POINT}/post/editor/save`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (response.ok) {
        fetchEditorList();

        // setHandlnewCalled(true)
      }
      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      const result = await response.json();
      // console.log(result);
    } catch (error) {
      console.error("Error:", error);
    }

    setIsSaving(false);
  };

  const handleEditorId = (editorValue) => {
    setEditorId(editorValue);
  };
  const handleObjectId = (editorValue) => {
    setDeleteId(editorValue);
  };

  // console.log("editorList", editorList);

  const fetchEditorList = useCallback(async () => {
    setIsSaving(true);
    try {
      setLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_END_POINT}/get/editorList?projectId=${projectId}&tab=${tab}`
      );

      if (response.ok) {
        const data = await response.json();
        setEditorList(data.editorList);

        if (data.editorList.length < 1 && !value) {
          setInput("");
          setTitle(newEditorTitle + 1);
        }
      } else {
        setError("Failed to fetch editor list.");
        setTitle("");
        setInput("");
        setEditorId("");
        setDeleteId("");
      }
    } catch (error) {
      setError("Internal server error.");
    } finally {
      setLoading(false);
    }
    setIsSaving(false);
  }, [newEditorTitle, projectId, tab]);

  useEffect(() => {
    if (projectId && tab) {
      fetchEditorList();
    }
  }, [projectId, tab, fetchEditorList]);
  //get all editor list

  // get method

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleQuillChange = (newValue) => {
    if (newValue) {
      setInput(newValue);
      // setDirty(true);
    }
  };

  const handleSeteditotitle = (editorValue) => {
    if (editorValue) {
      setTitle(editorValue);
    }
  };

  const handleSetInputBox = (editorValue) => {
    setInput(editorValue);
  };

  // handle delete
  const handleDelete = async () => {
    setsavepressed(false);
    try {
      const response = await fetch(
        // `https://nadereach-server.vercel.app/delete/editor/${tab}/${deleteId}`,
        `${process.env.REACT_APP_SERVER_END_POINT}/delete/editor/${tab}/${deleteId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Assuming you want to update the UI by removing the deleted item
        const updatedData = editorList.filter((item) => item._id !== deleteId);
        setEditorList(updatedData);

        // Set data to null on error

        setTitle(null);
        setInput(null);
        fetchEditorList();

        // // Find the next or previous item
        // const currentIndex = editorList.findIndex((item) => item._id === deleteId);
        // const nextItem = editorList[currentIndex + 1];
        // const previousItem = editorList[currentIndex - 1];

        // if (nextItem) {
        //   setTitle(nextItem.title);
        //   setInput(nextItem.inputBox);
        //   handleEditorId(nextItem.deleteId);
        // } else if (previousItem) {
        //   setTitle(previousItem.title);
        //   setInput(previousItem.inputBox);
        //   handleEditorId(previousItem.deleteId);
        // } else {
        //   // No next or previous item, reset to default values
        //   setTitle(null);
        //   setInput(null);
        //   handleEditorId(null);
        // }
      } else {
        console.error("Failed to delete item");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // handle delete

  // console.log({ inputBox });

  return (
    <>
      <div className="__item-container">
        <button
          className={`open-close no-gutter ${open ? "active" : ""}`}
          onClick={() => setOpen(!open)}
        >
          {!open ? <Open /> : <Close />}
        </button>
        <div className={`__item ${open ? "active" : ""}`}>
          <div className="dropdown">
            <div
              className="d-flex flex-wrap align-items-center gap-2 justify-content-between"
              style={{ marginBottom: "14px" }}
            >
              <div
                className="d-flex flex-grow-1"
                style={{ width: "100px", maxWidth: "200px" }}
              >
                {/* {console.log("title from input", title)} */}
                <input
                  type="text"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  className="form-control px-0 form--control border-0 text-dark !font-[500] !leading-5 !text-base !capitalize "
                />
                <button
                  className="btn dropdown-btn p-0 border-0 flex-grow-1"
                  type="button"
                  data-bs-toggle="dropdown"
                ></button>
                <div className="dropdown-menu __dropdown-menu-3">
                  <ul>
                    {editorList?.map((item, i) => (
                      <li key={i}>
                        <button
                          onClick={() => {
                            handleSeteditotitle(item.title);
                            handleSetInputBox(item.inputBox);
                            handleEditorId(item.editorId);
                            handleObjectId(item._id);
                          }}
                          className="no-gutter !font-[500] !leading-5 !capitalize !text-[#2D3E4D]"
                        >
                          {item.title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                className="d-flex align-items-center btn-sm-grp"
                style={{ gap: "10px" }}
              >
                <button
                  onClick={handleDelete}
                  className="__btn-outline !font-[500]   !capitalize "
                  type="button"
                >
                  Delete
                </button>
                <button
                  onClick={handlnew}
                  disabled={isSaving}
                  className="__btn-outline !font-[500]  !capitalize"
                  style={{
                    backgroundColor: isSaving ? "rgba(128, 128, 128, 0.5)" : "",
                  }}
                  type="button"
                >
                  New
                </button>

                <button
                  onClick={handlesave}
                  className="__btn !font-[500] !capitalize"
                  type="button"
                  disabled={isSaving}
                  style={{
                    backgroundColor: isSaving ? "rgba(128, 128, 128, 0.5)" : "",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          {/* <div className="max-h-[65vh] overflow-y-auto  h-[65vh] min-h-[65vh] 2xl:h-[65vh]   2xl:min-h-[65vh]  2xl:max-h-[65vh]  "> */}
          {/* <Resizable
            width={width}
            height={height}
            onResize={handleResize}
            className="" 
           
             draggableOpts={{ grid: [25, 25] }}
           >*/}
          <div className="resizable-content max-h-[55vh] overflow-y-auto  h-[55vh] min-h-[55vh]">
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={inputBox}
              className="custom-quill"
              onChange={handleQuillChange}
            />
          </div>
          {/* </div> */}
          {/* <TestResizable /> */}
        </div>
      </div>
    </>
  );
};

export default TextEditor;
