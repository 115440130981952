import React, { useContext, useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { AuthContext } from "../Context/UserContext";

const Checkout = ({ subscriptionType }) => {
  const { user, loading } = useContext(AuthContext);
  const email = user.email;

  const [error, setCardError] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetch(
      // "https://nadereach-server.vercel.app/create-payment-intent",
      `${process.env.REACT_APP_SERVER_END_POINT}/create-payment-intent`,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ price: 50 }), // Use a hardcoded value of $50
      })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    if (card == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card,
    });

    if (error) {
      setCardError(error.message);
    } else {
      setCardError("");
    }

    setSuccess("");
    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {},
        },
      });

    if (confirmError) {
      setCardError(confirmError.message);
      return;
    }

    if (paymentIntent.status === "succeeded") {
      setSuccess("Congrats! Your payment completed");
      setTransactionId(paymentIntent.id);
    }
  };

  return (
    <div
      style={{
        maxWidth: "400px",
        margin: "0 auto",
        background: "#ffffff",
        padding: "24px",
        borderRadius: "8px",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "16px" }}
      >
        <div>
          <label
            style={{ fontSize: "14px", fontWeight: "500", color: "#4b5563" }}
          >
            Card details
          </label>
          <div style={{ marginTop: "8px" }}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>
        </div>

        <div style={{ marginTop: "16px" }}>
          <div
            style={{
              padding: "16px",
              background: "#ffffff",
              borderRadius: "8px",
              boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#4b5563",
                marginBottom: "12px",
              }}
            >
              Order Summary
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #e2e8f0",
                paddingBottom: "8px",
              }}
            >
              <p style={{ fontSize: "14px", color: "#4b5563" }}>Package Name</p>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#4b5563",
                }}
              >
                {subscriptionType}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "8px",
              }}
            >
              <p style={{ fontSize: "14px", color: "#4b5563" }}>Total</p>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#4b5563",
                }}
              >
                $50
              </p>
            </div>
          </div>
        </div>

        <p style={{ color: "#e53e3e", marginTop: "16px" }}>{error}</p>
        {success && (
          <div style={{ marginTop: "16px" }}>
            <p style={{ color: "#38a169" }}>{success}</p>
            <p>
              Your transaction ID:{" "}
              <span style={{ fontWeight: "600" }}>{transactionId}</span>
            </p>
          </div>
        )}

        {success ? (
          <>s</>
        ) : (
          <>
            <button
              style={{
                marginTop: "16px",
                backgroundColor: "#1e40af",
                color: "#ffffff",
                padding: "12px",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "600",
              }}
              type="submit"
              disabled={!stripe || !clientSecret}
            >
              Pay
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default Checkout;
